<template>
  <div class="negative">
    <h2 class="normal" v-html="$t('confirmation.negative.header')"></h2>
    <p v-html="$t('confirmation.negative.description')"></p><p v-html="$t('confirmation.negative.description2')"></p>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationNegative'
}
</script>
