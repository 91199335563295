import { apiClass } from '@/base/class/api.class'
import { ToolsClass } from '@/base/class/tools.class'

export class PisTransactionStatusClass {
  constructor ({
    webSocketServerUrl,
    checkUrl,
    statusMaxWaitingTime,
    onRedirectUserCallback,
    onErrorCallback,
    onExceptionCallback
  }) {
    /**
     * @type {WebSocket|null}
     */
    this.connection = null
    /**
     * @type {string}
     */
    this.webSocketServerUrl = webSocketServerUrl
    /**
     * @type {string}
     */
    this.checkUrl = checkUrl
    /**
     * Maksymalny czas oczekiwania (w sekundach) na przekierowanie do banku.
     * @type {number}
     */
    this.statusMaxWaitingTime = statusMaxWaitingTime * 1000
    /**
     * Maksymalna liczba prób połączenia z serwerem WebSocket.
     * @type {number}
     */
    this.reinitMaxAttempts = 2
    /**
     * @type {number}
     */
    this.reinitAttemptsNumber = 0
    /**
     * Czas oczekiwania (w sekundach) przed następną próbą połączenia z serwerem WebSocket.
     * @type {number}
     */
    this.nextReinitAttemptDelay = 1000
    /**
     * @type {boolean}
     */
    this.tryToReinitOnClose = true
    /**
     * @type {number}
     */
    this.statusTimeoutID = -1
    /**
     * @type {Function}
     */
    this.onRedirectUserCallback = onRedirectUserCallback
    /**
     * @type {Function}
     */
    this.onErrorCallback = onErrorCallback
    /**
     * @type {Function}
     */
    this.onExceptionCallback = onExceptionCallback
  }

  init () {
    if (ToolsClass.isDebugEnabled()) {
      console.log('PisTransactionStatusClass init')
    }
    try {
      this.connection = new WebSocket(this.webSocketServerUrl)
      this.connection.addEventListener('open', (event) => {
        this.onConnectionOpened(event)
      })
      this.connection.addEventListener('message', (event) => {
        this.onDataReceived(event)
      })
      this.connection.addEventListener('error', (event) => {
        this.onErrorOccurred(event)
      })
      this.connection.addEventListener('close', (event) => {
        this.onConnectionClosed(event)
      })
    } catch (exception) {
      this.onExceptionCallback(exception)
      this.reinit()
    }
  }

  reinit () {
    this.reinitAttemptsNumber++
    if (this.reinitAttemptsNumber <= this.reinitMaxAttempts) {
      setTimeout(() => {
        this.init()
      }, this.nextReinitAttemptDelay)
      return
    }

    apiClass
      .getExternal(this.checkUrl)
      .then(response => {
        this.onDataReceived({
          isTrusted: true,
          data: response
        })
      })
      .catch(error => {
        const isTimeout = error.textStatus === 'timeout'
        this.handleError(isTimeout)
      })
  }

  /**
   * @param {Event} event
   */
  onConnectionOpened (event) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('PisTransactionStatusClass onConnectionOpened')
    }
    if (!event.isTrusted) {
      return
    }

    this.statusTimeoutID = setTimeout(() => {
      this.onStatusMaxWaitingTimeExceeded()
    }, this.statusMaxWaitingTime)
  }

  /**
   * @param {Event} event
   */
  onDataReceived (event) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('PisTransactionStatusClass onDataReceived', event)
    }
    if (!event.isTrusted) {
      return
    }

    clearTimeout(this.statusTimeoutID)

    let data = null
    try {
      data = JSON.parse(event.data)
      this.tryToReinitOnClose = false

      if (!this.isReceivedDataValid(data)) {
        this.handleError(false, data)
        return
      }

      if (this.connection !== null) {
        this.connection.close(1000, 'All data has been received.')
      }

      this.onRedirectUserCallback(data.form)
    } catch (exception) {
      this.onExceptionCallback(exception)
    }
  }

  /**
   * @param {Object} data
   * @returns {boolean}
   */
  isReceivedDataValid (data) {
    return !(
      !data ||
      typeof data.result === 'undefined' ||
      data.result !== 'OK' ||
      typeof data.form !== 'string' ||
      !data.form.trim().length
    )
  }

  /**
   * @param {Event} event
   */
  onErrorOccurred (event) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('PisTransactionStatusClass onErrorOccurred')
    }
    if (!event.isTrusted) {
      return
    }
    if (ToolsClass.isDebugEnabled()) {
      console.error('onErrorOccurred', event)
    }
    clearTimeout(this.statusTimeoutID)
    this.onExceptionCallback(new Error('WebSocket error occurred. Trying to reinit.'))
    if (!this.tryToReinitOnClose) {
      return
    }
    this.reinit()
  }

  /**
   * @param {Event} event
   */
  onConnectionClosed (event) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('PisTransactionStatusClass onConnectionClosed')
    }
    if (!event.isTrusted) {
      return
    }

    clearTimeout(this.statusTimeoutID)
    // 1000 to "Normal Closure".
    if (event.code === 1000) {
      return
    }
    this.onExceptionCallback(new Error('[' + event.code + '] WebSocket connection closed ("' + event.reason + '").'))
  }

  onStatusMaxWaitingTimeExceeded () {
    this.tryToReinitOnClose = false

    if (this.connection !== null &&
      (WebSocket.CONNECTING === this.connection.readyState || WebSocket.OPEN === this.connection.readyState)
    ) {
      this.connection.close()
    }

    this.handleError(true)
  }

  /**
   * @param {Boolean} isTimeout
   * @param {Object|null} data
   */
  handleError (isTimeout = false, data = null) {
    isTimeout = typeof isTimeout !== 'undefined' ? isTimeout : false

    this.onErrorCallback({
      timeout: isTimeout,
      data: data
    })
  }
}
