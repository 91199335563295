import { apiClass } from '@/base/class/api.class'
import { ENDPOINTS } from '@/base/const/endpoints.const'
import { Raven } from 'vue-raven'

const state = {
  loading: false,
  data: null,
  error: {
    invalidFields: null
  }
}

const mutations = {
  SET_CONFIRMATION_LOADING (state, value) {
    state.loading = value
  },
  SET_CONFIRMATION_DATA (state, data) {
    state.data = data
  },
  SET_CONFIRMATION_ERROR_INVALID_FIELDS (state, data) {
    state.error.invalidFields = data
  }
}

const actions = {
  getConfirmationTrx ({ commit, state, dispatch }, { hash, orderId, serviceId }) {
    commit('SET_CONFIRMATION_LOADING', true)
    const data = {
      hash: hash,
      orderId: orderId,
      serviceId: serviceId
    }
    return apiClass.post(ENDPOINTS.confirmation, data)
      .then(response => {
        const data = response.body.data
        commit('SET_CONFIRMATION_DATA', data)
        dispatch('setPaymentLanguage', data)
        return Promise.resolve(response)
      })
      .finally(() => {
        commit('SET_CONFIRMATION_LOADING', false)
      })
      .catch(error => {
        if (error.body.invalidFields) {
          commit('SET_CONFIRMATION_ERROR_INVALID_FIELDS', error.body.invalidFields)
        }
        Raven.captureMessage('confirmation.js getConfirmationTrx Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  },
  getConfirmationTrxForVisaMobile ({ commit, state, dispatch }, { hash, orderId, serviceId }) {
    const data = {
      hash: hash,
      orderId: orderId,
      serviceId: serviceId
    }
    return apiClass.post(ENDPOINTS.confirmation, data)
      .then(response => {
        const data = response.body.data
        commit('SET_CONFIRMATION_DATA', data)
        return Promise.resolve(response)
      })
      .catch(error => {
        if (error.body.invalidFields) {
          commit('SET_CONFIRMATION_ERROR_INVALID_FIELDS', error.body.invalidFields)
        }
        Raven.captureMessage('confirmation.js getConfirmationTrxForVisaMobile Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  }
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
