  <template>
  <footer>
    <div class="container">
      <div class="text" v-if="languageLoaded">
        <p class="text1" v-html="$t('footer.text1')"></p>
        <p class="text2">
          <span v-html="$t('footer.text2.part1')"></span> <span v-if="showMoreInfo" v-html="$t('footer.text2.part2', {privacyPolicyUrl: privacyPolicyUrl})"></span>
          &nbsp;
          <span>
            <button ref="moreButton"
               @keydown.32.prevent
               @keyup.32="handleContent"
               @click.prevent="showMoreInfo=true"
               v-if="!showMoreInfo"
               :title="$t('footer.linkShowMoreTitle')" tabindex="0">
              {{ $t('footer.linkShowMoreText') }}
              <img src="/web/img/icons/arrow-down-white.svg" alt=""/>
            </button>
            <button ref="lessButton" autofocus
               @keydown.32.prevent
               @keyup.32="handleContent"
               @click.prevent="showMoreInfo=false"
               v-if="showMoreInfo"
               :title="$t('footer.linkShowLessTitle')" tabindex="0">
                {{ $t('footer.linkShowLessText') }}
              <img src="/web/img/icons/arrow-up-white.svg" alt=""/>
            </button>
          </span>
        </p>
        <p class="copy">
          <span>© Autopay 1999 - {{ currentYear }}</span>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { DateClass } from '@/base/class/date.class'
import { mapGetters } from 'vuex'
import { PaymentClass } from '@/base/class/payment.class'
import EventBus from '@/base/event-bus'

export default {
  name: 'AppFooter',
  data () {
    return {
      showMoreInfo: false,
      languageLoaded: false
    }
  },
  computed: {
    ...mapGetters({
      lang: 'getCurrentLanguage'
    }),
    currentYear: () => DateClass.getCurrentYear(),
    privacyPolicyUrl: function () {
      return PaymentClass.getPrivacyPolicyLinkByLang(this.lang)
    }
  },
  methods: {
    handleContent () {
      this.showMoreInfo = !this.showMoreInfo
    }
  },
  watch: {
    showMoreInfo: function (value, oldValue) {
      if (value !== oldValue) {
        if (this.showMoreInfo) {
          setTimeout(() => {
            this.$refs.lessButton.focus()
          }, 100)
        }
        if (!this.showMoreInfo) {
          setTimeout(() => {
            this.$refs.moreButton.focus()
          }, 100)
        }
      }
    }
  },
  created () {
    EventBus.$on('language-loaded', () => {
      this.languageLoaded = true
    })
  }
}
</script>
