<template>
  <header class="header">
    <modals />
    <messages />
    <div class="container" v-if="languageLoaded">
      <a class="sr-only btn btn-plain" :aria-label="$t('header.availabilityAdjust.skipToContentLabel')" href="#page-title-for-sr">{{ $t('header.availabilityAdjust.skipToContent') }}</a>
      <div class="header-wrapper">
        <div class="logo" role="img" :aria-label="$t('header.availabilityAdjust.logoTitle')"></div>
        <div class="header-info">
          <session-timer v-if="isDataSet" />
        </div>
        <div class="header-menu">
          <languages v-if="!isLoading" />
          <availability-adjust />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import AvailabilityAdjust from '@/base/components/Header/AvailabilityAdjust'
import Messages from '@/base/components/Header/Messages'
import SessionTimer from '@/base/components/Header/SessionTimer'
import { mapState } from 'vuex'
import Languages from '@/base/components/Header/Languages'
import Modals from '@/base/components/Header/Modals'
import EventBus from '@/base/event-bus'

export default {
  name: 'AppHeader',
  components: {
    Modals,
    Languages,
    SessionTimer,
    Messages,
    AvailabilityAdjust
  },
  data () {
    return {
      languageLoaded: false
    }
  },
  computed: {
    ...mapState({
      isLoading: state => !!(state.payment.loading || state.confirmation.loading),
      isDataSet: state => !!(state.payment.paymentData)
    })
  },
  created () {
    EventBus.$on('language-loaded', () => {
      this.languageLoaded = true
    })
  }
}
</script>
