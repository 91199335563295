import { CLICK2PAY_IDENTITY_TYPE } from '@/base/const/click2pay.const'
import { CookieClass } from '@/base/class/cookie.class'
import { COOKIE_C2P_MC } from '@/base/const/cookies.const'
import { ToolsClass } from '@/base/class/tools.class'

export class MastercardClick2payClass {
  constructor () {
    this.recognized = null
    this.idTokens = null
    this.profiles = null
    this.consumerPresent = null
    this.identityValidation = null
    this.maskedCard = null
    this.srcCorrelationId = null
    this.checkoutResult = null
    this.sdk = null
  }

  init ({ initiatorId, dpaId, transactionId, transactionAmount, transactionCurrencyCode, locale, dpaPresentationName, dpaName }) {
    this.sdk = window.SRCSDK_MASTERCARD
    if (!this.sdk) {
      return Promise.reject(new Error('No Mastercard SDK instance!'))
    }
    const params = {
      srciTransactionId: transactionId,
      srcInitiatorId: initiatorId,
      srciDpaId: dpaId,
      dpaTransactionOptions: {
        transactionAmount: {
          transactionAmount: parseInt(transactionAmount, 10).toFixed(2),
          transactionCurrencyCode: transactionCurrencyCode
        },
        customInputData: {
          'com.mastercard.dcfExperience': 'WITHIN_CHECKOUT'
        },
        dpaLocale: locale,
        isGuestCheckout: false,
        confirmPayment: false,
        consumerNationalIdentifierRequested: false,
        dpaAcceptedBillingCountries: [],
        dpaAcceptedShippingCountries: [],
        consumerEmailAddressRequested: true,
        consumerNameRequested: true,
        consumerPhoneNumberRequested: true,
        dpaShippingPreference: 'NONE',
        dpaBillingPreference: 'NONE',
        paymentOptions: {
          dpaDynamicDataTtlMinutes: 15,
          dynamicDataType: 'CARD_APPLICATION_CRYPTOGRAM_SHORT_FORM'
        }
      },
      dpaData: {
        dpaPresentationName: dpaPresentationName,
        dpaName: dpaName
      }
    }
    return this.sdk
      .init(params)
      .then(() => {
        return Promise.resolve()
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }

  isRecognized () {
    try {
      let data = {
        recognitionTokens: []
      }
      let recognitionToken = CookieClass.getCookie(COOKIE_C2P_MC)
      if (recognitionToken && recognitionToken.length) {
        data.recognitionTokens = [recognitionToken]
      }
      if (ToolsClass.isDebugEnabled()) {
        console.log('isRecognized', data)
      }
      return this.sdk
        .isRecognized(data)
        .then(response => {
          this.recognized = response.recognized
          if (response.recognized) {
            this.idTokens = response.idTokens
          }
          return Promise.resolve()
        })
        .catch(error => {
          return Promise.reject(error)
        })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  getSrcProfile () {
    const params = {
      idTokens: []
    }
    if (this.idTokens) {
      params.idTokens = this.idTokens
    }
    return this.sdk
      .getSrcProfile(params)
      .then(response => {
        this.profiles = response.profiles
        this.srcCorrelationId = response.srcCorrelationId
        return Promise.resolve()
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }

  identityLookup (email) {
    try {
      const params = {
        consumerIdentity:
          {
            identityProvider: 'SRC',
            identityType: CLICK2PAY_IDENTITY_TYPE.email,
            identityValue: email
          }
      }
      return this.sdk
        .identityLookup(params)
        .then(response => {
          this.consumerPresent = response.consumerPresent
          return Promise.resolve()
        })
        .catch(error => {
          return Promise.reject(error)
        })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  initiateIdentityValidation (validationChannel) {
    try {
      const channel = validationChannel === CLICK2PAY_IDENTITY_TYPE.phone ? CLICK2PAY_IDENTITY_TYPE.phone : CLICK2PAY_IDENTITY_TYPE.email
      const params = {
        requestedValidationChannelId: channel
      }
      return this.sdk
        .initiateIdentityValidation(params)
        .then(response => {
          this.identityValidation = response
          return Promise.resolve()
        })
        .catch(error => {
          return Promise.reject(error)
        })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  completeIdentityValidation (validationData, complianceSettings) {
    try {
      const params = {
        validationData: validationData,
        complianceSettings: complianceSettings
      }
      if (ToolsClass.isDebugEnabled()) {
        console.log('completeIdentityValidation params', params)
      }
      return this.sdk
        .completeIdentityValidation(params)
        .then(response => {
          if (ToolsClass.isDebugEnabled()) {
            console.log('completeIdentityValidation response', response)
          }
          this.idTokens = [response.idToken]
          if (response.recognitionToken) {
            CookieClass.setCookie(COOKIE_C2P_MC, response.recognitionToken, 180)
          }
          return Promise.resolve()
        })
        .catch(error => {
          return Promise.reject(error)
        })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  enrollCard (encryptedCard, idToken = null) {
    try {
      const params = {
        encryptedCard: encryptedCard,
        idToken: idToken
      }
      return this.sdk
        .enrollCard(params)
        .then(response => {
          this.maskedCard = response.maskedCard
          this.srcCorrelationId = response.srcCorrelationId
          return Promise.resolve()
        })
        .catch(error => {
          return Promise.reject(error)
        })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  checkout (data) {
    try {
      return this.sdk
        .checkout(data)
        .then(response => {
          this.checkoutResult = response
          if (response.recognitionToken) {
            CookieClass.setCookie(COOKIE_C2P_MC, response.recognitionToken, 180)
          }
          return Promise.resolve()
        })
        .catch(error => {
          return Promise.reject(error)
        })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  unbindAppInstance () {
    try {
      let data = {
        recognitionTokens: []
      }
      let recognitionToken = CookieClass.getCookie(COOKIE_C2P_MC)
      if (recognitionToken && recognitionToken.length) {
        data.recognitionTokens = [recognitionToken]
      }
      if (ToolsClass.isDebugEnabled()) {
        console.log('unbindAppInstance', data)
      }
      return this.sdk
        .unbindAppInstance(data)
        .then(() => {
          CookieClass.removeCookie(COOKIE_C2P_MC)
          return Promise.resolve()
        })
        .catch(error => {
          return Promise.reject(error)
        })
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export const click2PayClass = new MastercardClick2payClass()
