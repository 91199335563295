<template>
  <div role="radio"
       name="payway-list-radio"
       tabindex="0"
       :id="payway.id"
       class="single-payway"
       :class="{'inactive': !payway.active}"
       :label="paywayName"
       :title="paywayName"
       :aria-checked="isPaywaySelected(payway.id)"
       @click="selectPayway(payway, $event)"
       @keyup.32="selectPayway(payway, $event)">
    <img v-if="!isTextInsteadOfImage() && isPaywayImage(payway)" aria-hidden="true" :src="getPaywayImage(payway)" :alt="paywayName">
    <p v-if="!isTextInsteadOfImage() && !isPaywayImage(payway)">{{paywayName}}</p>
    <p v-if="isTextInsteadOfImage()">{{setTextInsteadOfImage()}}</p>
    <span class="noimg" v-if="!isPaywayImage(payway) && isTextInsteadOfImage()">{{ paywayName | truncate(25) }}</span>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { PaymentClass } from '@/base/class/payment.class'
import { ToolsClass } from '@/base/class/tools.class'
import { FOCUSABLE_ELEMENTS } from '@/base/const/focusable.const'
import { EVENT_CODES } from '@/base/const/eventCodes.const'
import { MESSAGES } from '@/base/const/texts.const'
import { CARD_TYPES, singlePaywayWithoutImage } from '@/base/const/payway.const'

export default {
  name: 'PaywaySingle',
  props: {
    payway: {
      type: Object
    },
    paywaysLength: {
      type: Number
    }
  },
  computed: {
    ...mapState({
      lastPayway: state => state.payment.paymentData.lastUsedPayway ? state.payment.paymentData.lastUsedPayway : state.payment.paymentData.lastPayway,
      paywayID: state => state.payment.form.paywayID,
      paywaysLanguageData: function (state) {
        return state.payment.paywaysLanguageData !== null ? state.payment.paywaysLanguageData[this.payway.id] : null
      }
    }),
    ...mapGetters({
      lang: 'getCurrentLanguage',
      paywayList: 'getPaywayList'
    }),
    paywayName: function () {
      return this.paywaysLanguageData ? this.paywaysLanguageData.nameOnList : this.payway.nameOnList
    }
  },
  methods: {
    ...mapActions([
      'addModalInfo',
      'setPaymentActivePayway',
      'addMessageError'
    ]),
    isTextInsteadOfImage () {
      const paywayWithoutImageObject = singlePaywayWithoutImage()
      return paywayWithoutImageObject.type === this.payway.type && paywayWithoutImageObject.id === this.payway.id
    },
    setTextInsteadOfImage () {
      const paywayWithoutImageObject = singlePaywayWithoutImage()
      if (paywayWithoutImageObject.type === this.payway.type && paywayWithoutImageObject.id === this.payway.id) {
        return paywayWithoutImageObject.text
      }
    },
    goToNextFocusableElementOnPaywaySelectBySpace () {
      const focusable = [...document.querySelectorAll(FOCUSABLE_ELEMENTS)]
      return focusable[this.paywaysLength + 1]
    },
    selectPayway (payway, event) {
      if (!payway.active) {
        const message = {
          text: payway.inactiveMessage ? payway.inactiveMessage : MESSAGES().errors.paywayInactive,
          focusReturnElementId: this.payway.id
        }
        this.addModalInfo(message)
        return false
      }
      this.setPaymentActivePayway(payway)
        .then(() => {
          setTimeout(() => {
            if (event && event.code === EVENT_CODES.space && payway.id !== CARD_TYPES.creditCard.id) {
              const nextEl = this.goToNextFocusableElementOnPaywaySelectBySpace()
              if (nextEl) {
                ToolsClass.scrollToElementTop(nextEl, -50).then(() => {
                  nextEl.focus()
                })
              }
            }
          })
          if (window.yourcx) {
            window.yourcx('dimension', 'payment.method', payway.id, 'session')
            window.yourcx('dimension', 'payment.method_name', this.paywayName, 'session')
          }
        })
        .catch(() => {
          if (!event) {
            return
          }
          const message = {
            text: MESSAGES().errors.paywayInactive
          }
          this.addMessageError(message)
        })
    },
    getPaywayImage (payway) {
      return PaymentClass.getPaywayImage(payway)
    },
    isPaywayImage (payway) {
      return PaymentClass.isPaywayImage(payway)
    },
    isPaywaySelected (id) {
      return this.paywayID === id
    }
  },
  mounted () {
    if (this.lastPayway && this.lastPayway.id === this.payway.id) {
      this.selectPayway(this.payway)
    }
  }
}
</script>

<style lang="scss">
@import "./src/base/assets/scss/mixins.scss";
@import "./src/base/assets/scss/variables";
.single-payway {
  p {
    font-size: 0.9rem;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    font-family: $fontLatoBold;

    @include md {
      padding: 0 10px;
      font-size: 1rem;
    }
  }
}
</style>
