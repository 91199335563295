<template>
  <div class="outdated">
    <h2 class="normal" v-html="$t('confirmation.outdated.header')"></h2>
    <p v-html="$t('confirmation.outdated.description')"></p><p v-html="$t('confirmation.outdated.description2')"></p>
  </div>
</template>
<script>
export default {
  name: 'ConfirmationOutdated'
}
</script>
