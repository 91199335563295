import { OTP_TYPES, WALLET_TYPES } from '@/base/const/payway.const'

export const WALLET_IMAGES = [
  { img: 'google-pay.svg', id: WALLET_TYPES.googlePay.id },
  { img: 'apple-pay.svg', id: WALLET_TYPES.applePay.id },
  { img: 'visa-checkout.svg', id: WALLET_TYPES.visaCheckout.id }
]

export const OTP_IMAGES = [
  { img: 'pay-po.png', id: OTP_TYPES.paypo.id },
  { img: 'payka.png', id: OTP_TYPES.payka.id },
  { img: 'smartney.png', id: OTP_TYPES.smartney.id }
]

export const PBL_IMAGES = [
  { img: 'alior.svg', id: 95 },
  { img: 'alior.svg', id: 1608 },
  { img: 'alior.svg', id: 1802 },
  { img: 'bnp-paribas.svg', id: 31 },
  { img: 'bnp-paribas.svg', id: 508 },
  { img: 'bnp-paribas.svg', id: 1035 },
  { img: 'bnp-paribas.svg', id: 1804 },
  { img: 'bos.svg', id: 29 },
  { img: 'bos.svg', id: 86 },
  { img: 'bos.svg', id: 1813 },
  { img: 'bs-w-bialej-rawskiej.svg', id: 1520 },
  { img: 'bs-w-sztumie.svg', id: 1507 },
  { img: 'cityhandlowy.svg', id: 90 },
  { img: 'credit-agricole.svg', id: 59 },
  { img: 'credit-agricole.svg', id: 1609 },
  { img: 'credit-agricole.svg', id: 1809 },
  { img: 'getin-noble.svg', id: 513 },
  { img: 'getin-noble.svg', id: 1801 },
  { img: 'ing.svg', id: 68 },
  { img: 'ing.svg', id: 1602 },
  { img: 'ing.svg', id: 1702 },
  { img: 'ing.svg', id: 1808 },
  { img: 'inteligo.svg', id: 1064 },
  { img: 'inteligo.svg', id: 1810 },
  { img: 'ipko.svg', id: 1063 },
  { img: 'krakowski-bs.svg', id: 1519 },
  { img: 'mbank.svg', id: 3 },
  { img: 'mbank.svg', id: 17 },
  { img: 'mbank.svg', id: 1600 },
  { img: 'mbank.svg', id: 1800 },
  { img: 'malopolski-bs.svg', id: 1522 },
  { img: 'millennium.svg', id: 1807 },
  { img: 'nest.svg', id: 517 },
  { img: 'nest.svg', id: 1814 },
  { img: 'nicolaus.svg', id: 1515 },
  { img: 'noble.svg', id: 514 },
  { img: 'pekao.svg', id: 52 },
  { img: 'pekao.svg', id: 1704 },
  { img: 'pekao.svg', id: 1805 },
  { img: 'pko.svg', id: 1803 },
  { img: 'plus.svg', id: 131 },
  { img: 'pocztowy24.svg', id: 108 },
  { img: 'pocztowy24.svg', id: 1811 },
  { img: 'santander.svg', id: 27 },
  { img: 'santander.svg', id: 1603 },
  { img: 'santander.svg', id: 1806 },
  { img: 'sgb.svg', id: 1510 },
  { img: 'toyota.svg', id: 117 }
]
