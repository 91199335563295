<template>
  <div class="multiple-paid">
    <h2 class="normal" v-html="$t('confirmation.multiplePaid.header')"></h2>
    <p v-html="$t('confirmation.multiplePaid.description')"></p><p v-html="$t('confirmation.multiplePaid.description2')"></p>
  </div>
</template>
<script>
export default {
  name: 'ConfirmationMultiplePaid'
}
</script>
