import { Raven } from 'vue-raven'
import { apiClass } from '@/base/class/api.class'
import { ENDPOINTS } from '@/base/const/endpoints.const'
import { ERROR_CODES_NAMES } from '@/base/const/errorCodes.const'

const state = {
  loading: false,
  errorHash: null,
  errorData: null
}
const mutations = {
  SET_ERROR_LOADING (state, value) {
    state.loading = value
  },
  SET_ERROR_HASH (state, value) {
    state.errorHash = value
  },
  SET_ERROR_DATA (state, data) {
    state.errorData = data
  }
}
const actions = {
  getErrorTrxData ({ commit, state }, { errorHash }) {
    commit('SET_ERROR_LOADING', true)
    commit('SET_ERROR_HASH', errorHash)
    const data = {
      hash: errorHash
    }
    return apiClass.post(ENDPOINTS.error, data)
      .then(response => {
        commit('SET_ERROR_DATA', response.body.data)
        return Promise.resolve()
      })
      .finally(() => {
        commit('SET_ERROR_LOADING', false)
      })
      .catch(error => {
        Raven.captureMessage('error.js getErrorTrxData Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  }
}
const getters = {
  isErrorDataSet (state) {
    return !!state.errorData
  },
  getErrorDataCode (state) {
    if (state.errorData) {
      return state.errorData.code || state.errorData.errorCode
    }
    return ERROR_CODES_NAMES.GENERAL_ERROR
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
