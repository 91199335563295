<template>
  <div class="not-found">
    <h2 class="normal" v-if="isApplePay">{{ $t('paywall.info.applePayNotAvailable') }}</h2>
    <h2 class="normal" v-if="isGooglePay">{{ $t('paywall.info.googlePayNotAvailable') }}</h2>
    <h2 class="normal" v-if="isOtherPayment">{{ $t('paywall.info.paywayNotAvailable') }}</h2>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { PAYWALL_MODE } from '@/base/const/paywall.const'
import { ToolsClass } from '@/base/class/tools.class'

export default {
  name: 'InfoPaywayNotAvailable',
  computed: {
    ...mapState({
      chosenPaywayId: state => state.payment.paymentData.chosenPaywayId
    }),
    isApplePay: function () {
      return ToolsClass.isWalletApplePay(this.chosenPaywayId)
    },
    isGooglePay: function () {
      return ToolsClass.isWalletGooglePay(this.chosenPaywayId)
    },
    isOtherPayment: function () {
      return !ToolsClass.isWalletApplePay(this.chosenPaywayId) && !ToolsClass.isWalletGooglePay(this.chosenPaywayId)
    }
  },
  methods: {
    ...mapMutations([
      'SET_PAYMENT_PAYWALL_VIEW'
    ]),
    ...mapActions([
      'changePaywallMode'
    ]),
    setPaywallModeDefault () {
      this.SET_PAYMENT_PAYWALL_VIEW(true)
      this.changePaywallMode(PAYWALL_MODE.main)
    }
  }
}
</script>

<style lang="scss" scoped>
.change-payment-wrapper {
  p {
    padding-top: 20px;
    font-size: 1.2rem;
    line-height: 16px;
    color: var(--color-text-70);

    a {
      color: var(--color-contrast-b2b);
      text-decoration: none;
    }
  }
}
</style>
