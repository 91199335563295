import { eventsClass } from '@/base/class/events.class'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { DateClass } from '@/base/class/date.class'
import { MobileAppClass } from '@/base/class/mobileApp.class'
import { ToolsClass } from '@/base/class/tools.class'
import { MatomoClass } from '@/base/class/matomo.class'
import { LANGUAGES_ACTIVE_LIST } from '@/base/const/lang.const'
import { PAYWALL_THEME, PAYWALL_THEME_LIST, THEME_CONTEXT_SUPPORT_LIST } from '@/base/const/paywall.const'

export default {
  metaInfo () {
    return {
      htmlAttrs: {
        lang: this.lang.toLowerCase()
      }
    }
  },
  data () {
    return {
      contextName: this.$VUE_CONTEXT_NAME
    }
  },
  computed: {
    ...mapState({
      isPaywallView: state => state.payment.paywallView,
      isPaywallThemeDark: state => state.payment.paywallTheme === PAYWALL_THEME.dark
    }),
    ...mapGetters({
      lang: 'getCurrentLanguage'
    })
  },
  methods: {
    ...mapMutations([
      'SET_PAYMENT_CONTEXT_NAME',
      'SET_TIMER_APP_START',
      'SET_PAYWALL_THEME'
    ]),
    ...mapActions([
      'setLanguage'
    ]),
    checkMatomoLoaded () {
      console.time('Matomo Loaded')
      if (!MatomoClass.isMatomoLoaded()) {
        setTimeout(this.checkMatomoLoaded, 100)
        return
      }
      console.timeEnd('Matomo Loaded')
    },
    setPaywallTheme (theme = PAYWALL_THEME.light) {
      if (PAYWALL_THEME_LIST.includes(theme)) {
        this.SET_PAYWALL_THEME(theme)
      }
    },
    setInitialLanguage (initLang = null) {
      if (initLang && LANGUAGES_ACTIVE_LIST.includes(initLang)) {
        this.setLanguage(initLang.toUpperCase())
      }
    },
    setPageTheme (layout) {
      if (!THEME_CONTEXT_SUPPORT_LIST.includes(this.contextName)) {
        this.setPaywallTheme(PAYWALL_THEME.light)
        return
      }
      if (layout === PAYWALL_THEME.dark) {
        this.setPaywallTheme(PAYWALL_THEME.dark)
        return
      }
      if (layout === PAYWALL_THEME.light) {
        this.setPaywallTheme(PAYWALL_THEME.light)
        return
      }
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.setPaywallTheme(PAYWALL_THEME.dark)
        return
      }
      this.setPaywallTheme(PAYWALL_THEME.light)
    }
  },
  created () {
    const queryParams = ToolsClass.getQueryParams()
    this.setPageTheme(queryParams.layout)
    this.setInitialLanguage(queryParams.lang)
    this.SET_PAYMENT_CONTEXT_NAME(this.$VUE_CONTEXT_NAME)
    // if (ToolsClass.isDebugEnabled()) {
    //   this.checkMatomoLoaded()
    // }
    eventsClass.addEventsHandlers()
    if (queryParams.VersionSDK) {
      MobileAppClass.setVersionSDKParam(queryParams.VersionSDK)
    }
    if (ToolsClass.isMobileSDKMockEnabled()) {
      MobileAppClass.initMobileSDKMock()
    }
    MobileAppClass.supportExternalLinks()
    ToolsClass.disableBrowserRefresh()
  },
  mounted () {
    this.SET_TIMER_APP_START(DateClass.getCurrentTimestamp())
  },
  beforeDestroy () {
    eventsClass.removeEventsHandlers()
  },
  updated: function () {
    this.$nextTick(function () {
      MobileAppClass.supportExternalLinks()
    })
  }
}
