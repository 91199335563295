<template>
  <span>
    <span>
      <span class="shortText" v-html="newRegulationLabel"></span>
      <div class="hiddenText" v-if="isHiddenSectionSet && showMore" v-html="newRegulationLabelHidden"></div>
    </span>
    <span v-if="isHiddenSectionSet">
      <a v-if="!showMore" @click.prevent="showMore = true">{{ $t('paywall.form.linkMore') }}</a>
      <a v-else @click.prevent="showMore = false">{{ $t('paywall.form.linkLess') }}</a>
    </span>
  </span>
</template>

<script>
import { ToolsClass } from '@/base/class/tools.class'

const regexHTMLList = /(<li>|<ul>|<\/li>|<\/ul>)/gi
const regexNewLine1 = /(\r\n)/gi
const regexNewLine2 = /(\n)/gi
const regexHTMLLiOpenTag = /(<li>)/gi
const regexHTMLLiCloseTag = /(<\/li>)/gi
const regexHTMLHiddenSection = /<span class="___more ___hidden"(.*)/gi

export default {
  name: 'RegulationText.vue',
  data () {
    return {
      showMore: false
    }
  },
  props: {
    regulation: {
      type: Object,
      required: true
    }
  },
  computed: {
    // sprawdzamy czy w treści regulaminu jest lista HTMLowa
    isHtmlListSet: function () {
      return regexHTMLList.test(this.regulation.inputLabel)
    },
    // sprawdzamy czy w treści regulaminu jest ukryta sekcja
    isHiddenSectionSet: function () {
      return regexHTMLHiddenSection.test(this.regulation.inputLabel)
    },
    // zwracamy treść krótkiej części regulaminu
    newRegulationLabel: function () {
      let shortRegulationText = this.regulation.inputLabel
      // czyścimy z ul/li jeśli trzeba
      if (this.isHtmlListSet) {
        shortRegulationText = shortRegulationText
          .replaceAll(regexNewLine1, '')
          .replaceAll(regexNewLine2, '')
          .replaceAll(regexHTMLList, ' ')
      }
      // czyścimy z części ukrytej jeśli trzeba
      if (this.isHiddenSectionSet) {
        shortRegulationText = shortRegulationText.replace(regexHTMLHiddenSection, '')
      }
      // zapewniamy kompatybilność linków dla iOS i Androida
      shortRegulationText = this.setLinksCompatibilityForMobiles(shortRegulationText)
      return shortRegulationText
    },
    // zwracamy ukrytą część regulaminu
    newRegulationLabelHidden: function () {
      const doc = new DOMParser().parseFromString(this.regulation.inputLabel, 'text/html')
      let hiddenSectionText = doc.getElementsByClassName('___more ___hidden')[0].innerHTML
      hiddenSectionText = hiddenSectionText.replaceAll(regexHTMLLiOpenTag, '<div>')
      hiddenSectionText = hiddenSectionText.replaceAll(regexHTMLLiCloseTag, '</div>')
      // zapewniamy kompatybilność linków dla iOS i Androida
      hiddenSectionText = this.setLinksCompatibilityForMobiles(hiddenSectionText)
      return hiddenSectionText
    }
  },
  methods: {
    setLinksCompatibilityForMobiles (shortRegulationText) {
      // jeśli wyświetlamy na urządzeniu mobilnym z użyciem SDK czyścimy z target="_blank"
      const doc = new DOMParser().parseFromString(shortRegulationText, 'text/html')
      let links = doc.getElementsByTagName('a')
      Array.from(links).forEach(link => {
        if (ToolsClass.isVersionSDKSet() && !ToolsClass.isAndroid()) {
          link.removeAttribute('target')
        } else {
          link.setAttribute('target', '_blank')
        }
      })
      return doc.querySelector('body').innerHTML
    }
  }
}
</script>

<style lang="scss">
.shortText {
  word-break: break-word;
}
.hiddenText {
  display: inline-block;

  div {
    padding: 5px 0;
    word-break: break-word;
  }
}
</style>
