import Vue from 'vue'
import { HTTP_STATUS } from '../const/http.const'
import { Raven } from 'vue-raven'
import { ToolsClass } from '@/base/class/tools.class'

const OPTIONS = {
  credentials: true
}

function isResponseValidString (response) {
  return typeof response.body === 'string'
}
function isResponseValidObject (response) {
  return typeof response.body === 'object'
}
function supportServerErrors (error) {
  const internalErrorsList = [
    HTTP_STATUS.INTERNAL_SERVER_ERROR,
    HTTP_STATUS.SERVICE_UNAVAILABLE
  ]

  if (internalErrorsList.includes(error.status)) {
    Raven.captureMessage('api.class.js supportServerErrors Method response error', {
      extra: error
    })
  }
}

export class ApiClass {
  getExternal (url, options = {}) {
    Object.assign(options, OPTIONS)
    return Vue.http.get(url, options).then(
      response => {
        if (isResponseValidString(response) || isResponseValidObject(response)) {
          return Promise.resolve(response)
        }
        return Promise.reject(response)
      },
      error => {
        supportServerErrors(error)
        return Promise.reject(error)
      })
  }

  get (endpoint, options = {}) {
    Object.assign(options, OPTIONS)
    const url = ApiClass.getBackendUrl(endpoint)
    return Vue.http.get(url, options).then(
      response => {
        if (isResponseValidString(response) || isResponseValidObject(response)) {
          return Promise.resolve(response)
        }
        return Promise.reject(response)
      },
      error => {
        supportServerErrors(error)
        return Promise.reject(error)
      })
  }
  getAds (endpoint, options = {}) {
    Object.assign(options, {
      credentials: false
    })
    const url = ApiClass.getAdServerUrl(endpoint)
    return Vue.http.get(url, options).then(
      response => {
        if (isResponseValidString(response) || isResponseValidObject(response)) {
          return Promise.resolve(response)
        }
        return Promise.reject(response)
      },
      error => {
        supportServerErrors(error)
        return Promise.reject(error)
      })
  }

  post (endpoint, data = {}, options = {}) {
    Object.assign(options, OPTIONS)
    const url = ApiClass.getBackendUrl(endpoint)
    return Vue.http.post(url, data, options).then(
      response => {
        if (isResponseValidString(response) || isResponseValidObject(response)) {
          return Promise.resolve(response)
        }
        return Promise.reject(response)
      },
      error => {
        supportServerErrors(error)
        return Promise.reject(error)
      })
  }

  postCards (endpoint, data = {}, options = {}) {
    Object.assign(options, OPTIONS, { credentials: false })
    const url = ApiClass.getCardsBackendUrl(endpoint)
    return Vue.http.post(url, data, options).then(
      response => {
        if (isResponseValidString(response) || isResponseValidObject(response)) {
          return Promise.resolve(response)
        }
        return Promise.reject(response)
      },
      error => {
        supportServerErrors(error)
        return Promise.reject(error)
      })
  }

  static getBackendUrl (endpoint) {
    // return ToolsClass.isApiMockEnabled() ? endpoint : process.env.VUE_APP_API + endpoint
    return endpoint
  }

  static getCardsBackendUrl (endpoint) {
    return ToolsClass.isApiMockEnabled() ? endpoint : process.env.VUE_APP_CARDS_API + endpoint
  }

  static getAdServerUrl (endpoint) {
    // return ToolsClass.isApiMockEnabled() ? endpoint : process.env.VUE_APP_ADS_API + endpoint
    return process.env.VUE_APP_ADS_API + endpoint
  }
}

export const apiClass = new ApiClass()
