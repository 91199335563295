<template>
  <div class="order-details mobile" :class="{'opened': detailsBottomVisible}">
    <div class="content">
      <div>
        <button :aria-label="toggleBtnAriaLabel" class="more" @click="toggleDetailsBottomVisibility"></button>
        <div class="content-top" v-if="transaction">
          <div class="amount-wrapper">
            <p>
              {{ $t('orderDetails.amountLabel') }}
              <span>{{ getTransactionPayAmount() | amount }} {{ getTransactionPayCurrency() }}</span>
            </p>
          </div>
          <div class="details-data" v-if="!isProductCartSet">
            <p class="receiverLabel">
              {{ $t('orderDetails.receiverLabel') }}:
              <span v-safe-html="transaction.receiverNameForFront"></span>
            </p>
          </div>
        </div>
        <div class="content-bottom" v-if="detailsBottomVisible">
          <order-details-cart v-if="isProductCartSet" />
          <div class="details-data" v-if="isProductCartSet">
            <p>
              {{ $t('orderDetails.receiverLabel') }}
              <span v-safe-html="transaction.receiverNameForFront"></span>
            </p>
          </div>
          <div class="details-data" v-if="dccDetails">
            <p>
              {{ $t('orderDetails.amountOryginalCurrencyLabel', {currency: getTransactionOryginalCurrency()}) }}
              <span>{{ getTransactionOryginalAmount() | amount }} {{ getTransactionOryginalCurrency() }}</span>
            </p>
          </div>
          <div class="details-data" v-if="dccDetails">
            <p>
              {{ $t('orderDetails.currencyRateLabel') }}
              <span>1 {{ getTransactionOryginalCurrency() }} = {{ getTransactionDccRate() }} {{ getTransactionPayCurrency() }}</span>
            </p>
          </div>
          <div class="details-data" v-if="visibility && visibility.showFrontFees">
            <p>
              {{ $t('orderDetails.feeLabel') }}
              <span v-if="!dccDetails">{{ getTransactionCustomerFee() | amount }} {{ transaction.payAmountCurrency }}</span>
              <span v-if="dccDetails">{{ getTransactionCustomerFee() }}%</span>
            </p>
          </div>
          <div class="details-data">
            <p>
              {{ $t('orderDetails.orderLabel') }}
              <span>{{ transaction.orderId }}</span>
            </p>
          </div>
          <div class="details-data" v-if="isServiceTypeMwf && transaction.customerNumber">
            <p>
              {{ $t('orderDetails.customerNumberLabel') }}
              <span>{{ transaction.customerNumber }}</span>
            </p>
          </div>
          <div class="details-data" v-if="isServiceTypeMwf && transaction.invoiceNumber">
            <p>
              {{ $t('orderDetails.invoiceNumberLabel') }}
              <span>{{ transaction.invoiceNumber }}</span>
            </p>
          </div>
          <div class="details-data" v-if="isServiceTypeMwf && transaction.accountNumber">
            <p>
              {{ $t('orderDetails.accountNumberLabel') }}
              <span>{{ transaction.accountNumber }}</span>
            </p>
          </div>
          <div class="details-data" v-if="payway">
            <p>
              {{ $t('orderDetails.paywayLabel') }}
              <span>{{ $t('orderDetails.paywayName') }}</span>
            </p>
          </div>
          <div class="details-data" v-if="payway">
            <p class="payway-img">
              <img :src="getPaywayImage(payway)" :alt="payway.nameOnList">
              <span>{{ payway.nameOnList }}</span>
            </p>
          </div>
          <div class="details-link" v-if="showButton && visibility && visibility.showReturnToShopButton">
            <button type="button"
                    class="btn btn-plain link-back-to-shop"
                    v-if="allowReject"
                    @click="sendPaymentReject">
              {{ $t('orderDetails.linkBack') }}
            </button>
            <button type="button"
                    class="btn btn-plain link-back-to-shop"
                    title="Wróć do sklepu"
                    v-if="!allowReject"
                    @click="goBackToShop(returnUrl)">
              {{ $t('orderDetails.linkBack') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orderDetailsMixin from '@/base/mixins/orderDetails.mixin'
import OrderDetailsCart from '@/base/components/OrderDetails/partial/cart'

let gridBreakpointMd = null

export default {
  name: 'OrderDetailsTop',
  components: { OrderDetailsCart },
  mixins: [orderDetailsMixin],
  data () {
    return {
      detailsBottomVisible: false,
      toggleBtnAriaLabel: this.$t('orderDetails.toggleBtnUnfoldAriaLabel')
    }
  },
  methods: {
    setBgWrapperHeight () {
      const bgWrapper = document.querySelector('.bg-wrapper')
      const orderDetails = document.querySelector('.order-details')

      if (!bgWrapper || !orderDetails) return

      if (bgWrapper.classList.contains('opened')) {
        const dataHeight = orderDetails.clientHeight + 'px'
        bgWrapper.style.setProperty('--data-height', dataHeight)
      } else {
        bgWrapper.style.removeProperty('--data-height')
      }
    },
    setPositionTop () {
      const bgWrapper = document.querySelector('.bg-wrapper')

      if (bgWrapper) {
        bgWrapper.classList.toggle('opened')
        this.$nextTick(this.setBgWrapperHeight)
      }
    },
    checkTop () {
      if (window.innerWidth >= gridBreakpointMd) {
        const bgWrapper = document.querySelector('.bg-wrapper')
        if (bgWrapper) bgWrapper.style.removeProperty('--data-height')
      } else {
        this.setBgWrapperHeight()
      }
    },
    toggleDetailsBottomVisibility () {
      this.detailsBottomVisible = !this.detailsBottomVisible
      this.toggleBtnAriaLabel = this.detailsBottomVisible
        ? this.$t('orderDetails.toggleBtnFoldAriaLabel')
        : this.$t('orderDetails.toggleBtnUnfoldAriaLabel')

      this.setPositionTop()
    }
  },
  mounted () {
    if (this.expandFrontTransactionDetails) {
      this.toggleDetailsBottomVisibility()
    }
    gridBreakpointMd = getComputedStyle(document.documentElement).getPropertyValue('--grid-breakpoint-md')
    window.addEventListener('resize', this.checkTop)
  },
  onBeforeUnmount () {
    window.removeEventListener('resize', this.checkTop)
  }
}
</script>

<style lang="scss" scoped>
@import "./src/base/assets/scss/components/OrderDetails.scss";
</style>
