<template>
  <logo-slides v-if="images[activeImage]" :image="images[activeImage]" />
</template>

<script>
import LogoSlides from '@/base/components/LogoSlider/LogoSlides'

const CHANGE_DELAY = 3000
const PATH = '/web/img/logo-slider/'

export default {
  name: 'LogoSlider',
  components: { LogoSlides },
  props: {
    logoImages: {
      type: Array
    }
  },
  data () {
    return {
      interval: null,
      images: [],
      activeImage: 0
    }
  },
  methods: {
    createImagesArray () {
      this.logoImages.forEach((image, index) => {
        this.images.push({
          id: index,
          url: PATH + image
        })
      })
    },
    changeSlide () {
      this.activeImage += 1
      if (this.activeImage >= this.images.length) {
        this.activeImage = 0
      }
    }
  },
  created () {
    this.createImagesArray()
    this.interval = setInterval(() => {
      this.changeSlide()
    }, CHANGE_DELAY)
  },
  destroyed () {
    this.interval = null
  }
}
</script>
