import { mapActions, mapState } from 'vuex'
import { PAYMENT_SERVICE_TYPE } from '@/base/const/payment.const'
import { Raven } from 'vue-raven'
import { MobileAppClass } from '@/base/class/mobileApp.class'
import { ToolsClass } from '@/base/class/tools.class'
import { PaymentClass } from '@/base/class/payment.class'

export default {
  props: {
    transaction: {
      type: Object | null,
      required: false
    },
    dccDetails: {
      type: Object | null,
      required: false
    },
    visibility: {
      type: Object | null,
      required: false
    },
    showButton: {
      type: Boolean,
      required: false
    },
    payway: {
      type: Object | null,
      required: false
    }
  },
  computed: {
    ...mapState({
      returnUrl: state => state.payment.paymentData.returnUrl,
      isPaywallView: state => state.payment.paywallView,
      isServiceTypeMwf: state => {
        let serviceType = ''
        if (state.payment.paymentData && state.payment.paymentData.serviceType) {
          serviceType = state.payment.paymentData.serviceType
        }
        if (state.confirmation.data && state.confirmation.data.serviceType) {
          serviceType = state.confirmation.data.serviceType
        }
        return serviceType === PAYMENT_SERVICE_TYPE.mwf
      },
      isProductCartSet: state => !!state.payment.paymentData?.visibilityOptions?.showFrontBasket,
      expandFrontTransactionDetails: state => !!state.payment.paymentData?.visibilityOptions?.expandFrontTransactionDetails
    }),
    allowReject: function () {
      return this.isPaywallView
    }
  },
  methods: {
    ...mapActions([
      'sendPaymentRejectTrx'
    ]),
    /**
     * @param {string} returnUrl
     */
    goBackToShop (returnUrl) {
      if (ToolsClass.isMobileAppConnection()) {
        MobileAppClass.callMobileTransactionFinish()
        return
      }
      ToolsClass.goToExternalUrl(returnUrl)
    },
    sendPaymentReject () {
      this.sendPaymentRejectTrx()
        .then(returnUrl => {
          ToolsClass.goToExternalUrl(returnUrl)
        })
        .catch(error => {
          MobileAppClass.callMobileTransactionFinish()
          Raven.captureMessage('sendPaymentReject Method response error', {
            extra: error
          })
        })
    },
    getPaywayImage (payway) {
      return PaymentClass.getPaywayImage(payway)
    },
    getTransactionPayAmount () {
      if (this.dccDetails) {
        return this.dccDetails.dcc.amount
      }
      return this.transaction?.payAmount
    },
    getTransactionPayCurrency () {
      if (this.dccDetails) {
        return this.dccDetails.dcc.currency
      }
      return this.transaction?.payAmountCurrency
    },
    getTransactionOryginalAmount () {
      if (this.dccDetails) {
        return this.dccDetails.transaction.amount
      }
    },
    getTransactionOryginalCurrency () {
      if (this.dccDetails) {
        return this.dccDetails.transaction.currency
      }
    },
    getTransactionDccRate () {
      if (this.dccDetails) {
        return this.dccDetails.dcc.rate
      }
    },
    getTransactionCustomerFee () {
      if (this.dccDetails) {
        return this.dccDetails.dcc.marginPercentage
      }
      return this.transaction?.customerFee
    }
  }
}
