export const CONFIRMATION_STATE = {
  pending: 'PENDING',
  positive: 'POSITIVE',
  incorrectAmount: 'INCORRECT_AMOUNT',
  negative: 'NEGATIVE',
  multiplePaid: 'MULTIPLE_PAID',
  outdated: 'OUTDATED',
  outdatedPaidNotRefunded: 'OUTDATED_PAID_NOT_REFUNDED',
  outdatedPaidRefunded: 'OUTDATED_PAID_REFUNDED',
  transactionNotFound: 'TRANSACTION_NOT_FOUND'
}

export const CONFIRMATION_REDIRECT_TIME = 15
export const CONFIRMATION_REDIRECT_TIME_LONG = 150
