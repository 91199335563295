import { ToolsClass } from '@/base/class/tools.class'
import { Raven } from 'vue-raven'

const VISA_ENV = {
  production: 'assets',
  sandbox: 'sandbox-assets'
}

export class VisaClass {
  constructor () {
    this.isActive = false
  }

  /**
   * @param {string} apiKey
   * @param {string} acceptorId
   * @param {string} currency
   * @param {string} total
   * @param {function} successCallback
   * @param {function} cancelCallback
   * @param {function} errorCallback
   */
  init (apiKey, acceptorId, currency, total, successCallback, cancelCallback, errorCallback) {
    if (this.isActive) {
      return
    }

    const visaEnv = ToolsClass.isProduction() ? VISA_ENV.production : VISA_ENV.sandbox
    const visaScriptSrc = `https://${visaEnv}.secure.checkout.visa.com/checkout-widget/resources/js/integration/v1/sdk.js`
    const scriptTag = document.createElement('script')

    scriptTag.setAttribute('src', visaScriptSrc)

    scriptTag.onload = function () {
      try {
        window.V.init({
          apikey: apiKey,
          externalClientId: acceptorId,
          paymentRequest: {
            currencyCode: currency,
            total: total
          },
          settings: {
            review: {
              buttonAction: 'Pay'
            },
            shipping: {
              collectShipping: false
            }
          }
        })
        window.V.on('payment.success', function (payment) {
          successCallback(payment)
        })
        window.V.on('payment.cancel', function (payment) {
          cancelCallback(payment)
        })
        window.V.on('payment.error', function (payment, error) {
          errorCallback(payment, error)
        })
      } catch (error) {
        Raven.captureMessage('visa.class.js init Method response error', {
          extra: error
        })
      }
    }
    document.body.appendChild(scriptTag)
  }

  static getButtonSrc () {
    const prefix = !ToolsClass.isProduction() ? 'sandbox.' : ''
    return `https://${prefix}secure.checkout.visa.com/wallet-services-web/xo/button.png`
  }
}

export const visaClass = new VisaClass()
