import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { CookieClass } from '@/base/class/cookie.class'
import { COOKIE_LANG } from '@/base/const/cookies.const'
import EventBus from '@/base/event-bus'
import { Raven } from 'vue-raven'
import { LANGUAGES, LANGUAGES_ACTIVE_LIST } from '@/base/const/lang.const'

Vue.use(VueI18n)
const supportedLanguages = LANGUAGES_ACTIVE_LIST.map(lang => lang.toLowerCase())
const fallbackLanguage = LANGUAGES.en.toLowerCase()
const navigatorLanguage = navigator.language.split('-')[0].toLowerCase()
let appLanguage = CookieClass.getCookie(COOKIE_LANG) ? CookieClass.getCookie(COOKIE_LANG).toLowerCase() : navigatorLanguage
if (!supportedLanguages.includes(appLanguage)) {
  appLanguage = fallbackLanguage
  CookieClass.setCookie(COOKIE_LANG, appLanguage.toUpperCase())
}
export let currentLang = appLanguage

const i18n = new VueI18n({
  locale: currentLang,
  messages: {}
})

export default i18n

export function loadLanguage (lang) {
  return new Promise((resolve, reject) => {
    import(`./${lang}.js`)
      .then(messages => {
        i18n.setLocaleMessage(lang, messages.default)
        i18n.locale = lang
        EventBus.$emit('language-loaded')
        resolve()
      })
      .catch(error => {
        const errorMsg = 'loadLanguage error'
        Raven.captureMessage(errorMsg, {
          extra: { error }
        })
        reject(new Error(errorMsg))
      })
  })
}

loadLanguage(currentLang).catch((error) => {
  Raven.captureMessage('Unable to load init language', {
    extra: { error }
  })
})
