import i18n from '@/base/i18n/index'

export const MOBILE_EVENT_NAMES = {
  transactionStartCallback: 'transactionStartCallback',
  transactionFinishCallback: 'transactionFinishCallback',
  linkClickCallback: 'linkClickCallback'
}

export const MOBILE_STATUS = () => {
  return {
    paywaylistLoaded: {
      name: 'PAYWAYLIST_LOADED',
      newName: 'CONTENT_LOADED',
      description: i18n.t('configuration.mobileEvents.statusDescription.paywaylistLoaded')
    },
    paid: {
      name: 'PAID',
      newName: 'PAID',
      description: i18n.t('configuration.mobileEvents.statusDescription.paid')
    },
    declined: {
      name: 'DECLINED',
      newName: 'DECLINED',
      description: i18n.t('configuration.mobileEvents.statusDescription.declined')
    },
    bankDisabled: {
      name: 'BANK_DISABLED',
      newName: 'BANK_DISABLED',
      description: i18n.t('configuration.mobileEvents.statusDescription.bankDisabled')
    },
    blockMultipleTransactions: {
      name: 'BLOCK_MULTIPLE_TRANSACTIONS',
      newName: 'BLOCK_MULTIPLE_TRANSACTIONS',
      description: i18n.t('configuration.mobileEvents.statusDescription.blockMultipleTransactions')
    },
    blockPaidTransactions: {
      name: 'BLOCK_PAID_TRANSACTIONS',
      newName: 'BLOCK_PAID_TRANSACTIONS',
      description: i18n.t('configuration.mobileEvents.statusDescription.blockPaidTransactions')
    },
    generalError: {
      name: 'GENERAL_ERROR',
      newName: 'GENERAL_ERROR',
      description: i18n.t('configuration.mobileEvents.statusDescription.generalError')
    },
    insufficientStartAmount: {
      name: 'INSUFFICIENT_START_AMOUNT',
      newName: 'INSUFFICIENT_START_AMOUNT',
      description: i18n.t('configuration.mobileEvents.statusDescription.insufficientStartAmount')
    },
    outdatedError: {
      name: 'OUTDATED_ERROR',
      newName: 'OUTDATED_ERROR',
      description: i18n.t('configuration.mobileEvents.statusDescription.outdatedError')
    },
    internalServerError: {
      name: 'INTERNAL_SERVER_ERROR',
      newName: 'INTERNAL_SERVER_ERROR',
      description: i18n.t('configuration.mobileEvents.statusDescription.internalServerError')
    },
    canceledTransactions: {
      name: 'TRANSACTION_CANCELED',
      newName: 'TRANSACTION_CANCELED',
      description: i18n.t('configuration.mobileEvents.statusDescription.canceledTransactions')
    },
    nonAccountedLimitExceeded: {
      name: 'NON_ACCOUNTED_LIMIT_EXCEEDED',
      newName: 'NON_ACCOUNTED_LIMIT_EXCEEDED',
      description: i18n.t('configuration.mobileEvents.statusDescription.nonAccountedLimitExceeded')
    },
    webPageClick: {
      name: 'WEBPAGE_CLICK',
      newName: 'WEBPAGE_CLICK'
    },
    paywayLoaded: {
      name: 'PAYWAY_LOADED',
      newName: 'CONTENT_LOADED',
      description: i18n.t('configuration.mobileEvents.statusDescription.paywayLoaded')
    },
    connectionError: {
      name: 'CONNECTION_ERROR',
      newName: 'CONNECTION_ERROR',
      description: i18n.t('configuration.mobileEvents.statusDescription.generalError')
    }
  }
}
