import i18n from '@/base/i18n/index'

export const MESSAGES = (options) => {
  return {
    accessForbidden: i18n.t('messages.accessForbidden'),
    internalServerError: i18n.t('messages.internalServerError'),
    validators: {
      required: i18n.t('messages.validators.required'),
      emailRequired: i18n.t('messages.validators.emailRequired'),
      emailNotValid: i18n.t('messages.validators.emailNotValid'),
      paywayRequired: i18n.t('messages.validators.paywayRequired'),
      regulationRequired: i18n.t('messages.validators.regulationRequired')
    },
    success: {
      copy: i18n.t('messages.success.copy'),
      codeSent: i18n.t('messages.success.codeSent')
    },
    errors: {
      getPdf: i18n.t('messages.errors.getPdf'),
      copy: i18n.t('messages.errors.copy'),
      thankYouUpdate: i18n.t('messages.errors.thankYouUpdate'),
      groupInactive: i18n.t('messages.errors.groupInactive', options),
      paywayInactive: i18n.t('messages.errors.paywayInactive'),
      googlePayInactive: i18n.t('messages.errors.googlePayInactive'),
      applePayInactive: i18n.t('messages.errors.applePayInactive'),
      codeNotSent: i18n.t('messages.errors.codeNotSent'),
      codeCounterExceeded: i18n.t('messages.errors.codeCounterExceeded')
    }
  }
}
