export class CookieClass {
  static getCookie (name) {
    const value = '; ' + document.cookie
    const parts = value.split('; ' + name + '=')
    if (parts.length === 2) return parts.pop().split(';').shift()
  }

  static setCookie (name, value, expireDays = 365, domain = window.location.hostname) {
    const expireTime = new Date()
    expireTime.setTime(expireTime.getTime() + (expireDays * 24 * 60 * 60 * 1000))
    value = value || true
    value = typeof value !== 'string' ? JSON.stringify(value) : value
    document.cookie = `${name}=${value};expires=${expireTime.toGMTString()};domain=${domain};path=/;`
  }

  static removeCookie (name, domain = window.location.hostname) {
    document.cookie = `${name}=null;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${domain};path=/;`
  }
}
