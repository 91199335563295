import { DateClass } from '@/base/class/date.class'

export default function (date) {
  if (!date) return '-'

  if (!(date instanceof Date)) {
    date = new Date(date)
  }
  return DateClass.getFormattedDate(date)
}
