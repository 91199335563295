import { PAYWAY_IMAGES, PAYWAY_IMAGES_SRC, PAYWAY_PSD2_TYPES } from '@/base/const/payway.const'
import { LANG_DEFAULT, LANGUAGES } from '@/base/const/lang.const'
import { REGULATION_TYPE } from '@/base/const/regulation.const'
import { PAYMENT_SERVICE_TYPE_WITH_HIDDEN_REGULATION } from '@/base/const/payment.const'
import { PRIVACY_POLICY_LINK } from '@/base/const/links.const'

export class PaymentClass {
  /**
   * Metoda sprawdzająca, czy dana płatność jest typu PIS
   * @param {number} [paywayId]
   * @param {array} [paywayList]
   * @returns {boolean}
   */
  static isPaywayTypePis (paywayId, paywayList = []) {
    if (!paywayId || typeof paywayId !== 'number') {
      return false
    }
    const payway = paywayList.find(payway => payway.id === paywayId)
    if (!payway) {
      return false
    }
    return payway.typeForPsd2 === PAYWAY_PSD2_TYPES.pis
  }

  /**
   * Metoda zwracająca regulamin z listy regulaminów
   * @param {number|null} [paywayId]
   * @param {array} [regulations]
   * @param {string} [lang]
   * @param {array} [languagesList]
   * @param {string} [type]
   * @returns {object|null}
   */
  static getRegulationForPayway (paywayId, regulations = [], lang = LANG_DEFAULT, languagesList = [], type = REGULATION_TYPE.main) {
    const noRegulation = null
    lang = lang.toUpperCase()
    if (!languagesList.includes(lang)) {
      lang = LANG_DEFAULT
    }

    // 1. szukamy po paywayId, lang, !checkAndHidden
    let regulation = regulations.find(item => item.gatewayId === paywayId && item.language === lang && !item.checkedAndHidden && item.type === type)
    if (regulation) {
      return regulation
    }

    // 2. szukamy po paywayId, PL, !checkAndHidden
    regulation = regulations.find(item => item.gatewayId === paywayId && item.language === LANG_DEFAULT && !item.checkedAndHidden && item.type === type)
    if (regulation) {
      return regulation
    }

    // 3. szukamy po paywayId, lang, checkAndHidden
    regulation = regulations.find(item => item.gatewayId === paywayId && item.language === lang && item.checkedAndHidden && item.type === type)
    if (regulation) {
      return regulation
    }

    // 4. szukamy po paywayId, PL, checkAndHidden
    regulation = regulations.find(item => item.gatewayId === paywayId && item.language === LANG_DEFAULT && item.checkedAndHidden && item.type === type)
    if (regulation) {
      return regulation
    }

    // 5. szukamy po lang
    regulation = regulations.find(item => item.language === lang && item.type === type)
    if (regulation) {
      return regulation
    }

    // 6. szukamy po PL
    regulation = regulations.find(item => item.language === LANG_DEFAULT && item.type === type)
    if (regulation) {
      return regulation
    }
    return noRegulation
  }

  /**
   * Metoda sprawdzająca, czy wyświetlamy regulamin
   * @param {string} serviceType
   * @returns {boolean}
   */
  static isRegulationHidden (serviceType) {
    return PAYMENT_SERVICE_TYPE_WITH_HIDDEN_REGULATION.includes(serviceType)
  }

  /**
   * Metoda sprawdzająca, czy mamy logo dla kanału płatności
   * @param {object} payway
   * @returns {boolean}
   */
  static isPaywayImage (payway) {
    if (!payway || typeof payway !== 'object') {
      return false
    }

    let result = !!payway.iconUrl && !!payway.iconUrl.length
    PAYWAY_IMAGES.forEach(item => {
      if (item.id.includes(payway.id)) {
        result = true
      }
    })
    return result
  }

  /**
   * Metoda zwracająca ścieżkę do obrazka z logo kanału płatności
   * @param {object} payway
   * @returns {string}
   */
  static getPaywayImage (payway) {
    if (!payway || typeof payway !== 'object') {
      return ''
    }
    let imgUrl = payway.iconUrl
    PAYWAY_IMAGES.forEach(item => {
      if (item.id.includes(payway.id)) {
        imgUrl = `${PAYWAY_IMAGES_SRC}${item.img}`
      }
    })
    return imgUrl
  }

  /**
   * Metoda zwracająca link do polityki prywatności dla podanego języka
   * @param {string} [lang]
   * @returns {*}
   */
  static getPrivacyPolicyLinkByLang (lang) {
    if (lang && PRIVACY_POLICY_LINK[lang]) {
      return PRIVACY_POLICY_LINK[lang]
    }
    return PRIVACY_POLICY_LINK[LANGUAGES.en]
  }
}
