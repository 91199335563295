import { apiClass } from '@/base/class/api.class'
import { ENDPOINTS } from '@/base/const/endpoints.const'
import { ToolsClass } from '@/base/class/tools.class'
import { ADS_BANNER_SIZE, ADS_SOURCE_ID } from '@/base/const/ads.const'

const state = {
  data: null,
  size: ADS_BANNER_SIZE.desktop,
  confirmed: null
}
const mutations = {
  SET_ADS_DATA (state, data) {
    state.data = data
  },
  SET_ADS_SIZE (state, value) {
    state.size = value
  },
  SET_ADS_CONFIRMED (state, value) {
    state.confirmed = value
  }
}
const actions = {
  getAdsBanners ({ commit }, { serviceId, paywayId }) {
    const endpoint = `${ENDPOINTS.ads.getBannersList}?serviceId=${serviceId}&sourceId=${ADS_SOURCE_ID}&paywayId=${paywayId}`
    return apiClass.getAds(endpoint)
      .then(response => {
        commit('SET_ADS_DATA', response.body)
        if (ToolsClass.isMobileScreenSize()) {
          commit('SET_ADS_SIZE', ADS_BANNER_SIZE.mobile)
        }
        return Promise.resolve()
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  sendAdsBannerViewConfirmation ({ commit }, { serviceId, creationId }) {
    commit('SET_ADS_CONFIRMED', creationId)
    const endpoint = `${ENDPOINTS.ads.sendBannerViewConfirmation}?serviceId=${serviceId}&creationId=${creationId}`
    return apiClass.getAds(endpoint)
  }
}
const getters = {
  isAdsBannerDataSet (state) {
    return !!state.data && Array.isArray(state.data) && state.data.length
  },
  getBannerToDisplay (state) {
    if (!state.data || !state.data.length) {
      return false
    }
    return state.data.find((banner) => banner.targetDevice === state.size)
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
