<template>
  <div class="message-list">
    <div class="message-item"
         :class="{'error': isTypeError(message), 'success': isTypeSuccess(message), 'warning': isTypeWarning(message), 'info': isTypeInfo(message)}"
         v-for="(message, index) in getMessagesToDisplay()"
         :key="index">
      <a class="close" href="" @click.prevent="hideMessage(message.id)" v-if="message.options.hideButton"></a>
      <span class="text">{{ message.text }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { MESSAGE_TYPE } from '@/base/const/messages.const'
import { ToolsClass } from '@/base/class/tools.class'

export default {
  name: 'Messages',
  data () {
    return {
      cleanupHandler: null,
      handlers: []
    }
  },
  watch: {
    messages: function (val) {
      if (val.length) {
        ToolsClass.scrollToElementTop(document.querySelector('header'))
        this.autoHideMessages()
      }
    }
  },
  computed: {
    ...mapGetters({
      messages: 'getNotices'
    })
  },
  methods: {
    ...mapActions([
      'removeMessage',
      'setMessageAsDisplayed',
      'removeDisplayedMessages'
    ]),
    isTypeError (message) {
      return message.type === MESSAGE_TYPE.error
    },
    isTypeSuccess (message) {
      return message.type === MESSAGE_TYPE.success
    },
    isTypeWarning (message) {
      return message.type === MESSAGE_TYPE.warning
    },
    isTypeInfo (message) {
      return message.type === MESSAGE_TYPE.info
    },
    autoHideMessages () {
      const messageList = this.messages.filter(message => message.options.autoHide && !message.options.displayed)
      messageList.forEach(message => {
        let handler = this.handlers.find(item => item.id === message.id)
        if (handler) {
          return
        }
        handler = {
          id: message.id,
          handler: setTimeout(() => {
            this.setMessageAsDisplayed(message.id)
            this.removeHandler(message.id)
          }, message.options.time)
        }
        this.handlers.push(handler)
      })
    },
    hideMessage (id) {
      const handlerItem = this.handlers.find(item => item.id === id)
      if (handlerItem) {
        clearTimeout(handlerItem.handler)
        this.removeHandler(id)
      }
      this.removeMessage(id)
    },
    removeHandler (id) {
      const handlerItem = this.handlers.find(item => item.id === id)
      if (!handlerItem) {
        return
      }
      const index = this.handlers.indexOf(handlerItem)
      this.handlers.splice(index, 1)
    },
    getMessagesToDisplay () {
      return this.messages.filter(message => !message.options.displayed)
    }
  },
  mounted () {
    this.cleanupHandler = setInterval(this.removeDisplayedMessages, 10000)
  },
  destroyed () {
    clearInterval(this.cleanupHandler)
  }
}
</script>
