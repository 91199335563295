import { MESSAGE_DISPLAY, MESSAGE_MODEL, MESSAGE_TYPE } from '@/base/const/messages.const'
import { DateClass } from '@/base/class/date.class'
import { ToolsClass } from '@/base/class/tools.class'

const state = {
  data: [],
  focusReturnElementId: null
}

const mutations = {
  ADD_MESSAGE (state, message) {
    state.data.push(message)
  },
  REMOVE_MESSAGE (state, index) {
    state.data.splice(index, 1)
  },
  SET_MESSAGE_AS_DISPLAYED (state, index) {
    state.data[index].options.displayed = true
  },
  SET_MESSAGE_FOCUS_RETURN_REF_ID (state, focusReturnElementId) {
    state.focusReturnElementId = focusReturnElementId
  }
}

const actions = {
  addModalInfo ({ commit }, { text, focusReturnElementId, options, component }) {
    if ((!text || !text.length) && !component) {
      return Promise.reject(new Error('No message!'))
    }
    const msg = ToolsClass.deepExtend(MESSAGE_MODEL)
    msg.id = DateClass.getCurrentTimestamp()
    msg.type = MESSAGE_TYPE.info
    msg.display = MESSAGE_DISPLAY.modal
    msg.text = text
    msg.component = component
    if (options) {
      Object.assign(msg.options, options)
    }
    commit('SET_MESSAGE_FOCUS_RETURN_REF_ID', focusReturnElementId)
    commit('ADD_MESSAGE', msg)
    return Promise.resolve()
  },
  addMessageError ({ commit }, { text, options }) {
    if (!text || !text.length) {
      return Promise.reject(new Error('No message!'))
    }

    const msg = ToolsClass.deepExtend(MESSAGE_MODEL)
    msg.id = DateClass.getCurrentTimestamp()
    msg.type = MESSAGE_TYPE.error
    msg.display = MESSAGE_DISPLAY.notice
    msg.text = text
    if (options) {
      Object.assign(msg.options, options)
    }
    commit('ADD_MESSAGE', msg)
    return Promise.resolve()
  },
  addMessageSuccess ({ commit }, { text, options }) {
    if (!text || !text.length) {
      return Promise.reject(new Error('No message!'))
    }

    const msg = ToolsClass.deepExtend(MESSAGE_MODEL)
    msg.id = DateClass.getCurrentTimestamp()
    msg.type = MESSAGE_TYPE.success
    msg.display = MESSAGE_DISPLAY.notice
    msg.text = text
    if (options) {
      Object.assign(msg.options, options)
    }
    commit('ADD_MESSAGE', msg)
    return Promise.resolve()
  },
  addMessageWarning ({ commit }, { text, options }) {
    if (!text || !text.length) {
      return Promise.reject(new Error('No message!'))
    }

    const msg = ToolsClass.deepExtend(MESSAGE_MODEL)
    msg.id = DateClass.getCurrentTimestamp()
    msg.type = MESSAGE_TYPE.warning
    msg.display = MESSAGE_DISPLAY.notice
    msg.text = text
    if (options) {
      Object.assign(msg.options, options)
    }
    commit('ADD_MESSAGE', msg)
    return Promise.resolve()
  },
  addMessageInfo ({ commit }, { text, options }) {
    if (!text || !text.length) {
      return Promise.reject(new Error('No message!'))
    }

    const msg = ToolsClass.deepExtend(MESSAGE_MODEL)
    msg.id = DateClass.getCurrentTimestamp()
    msg.type = MESSAGE_TYPE.info
    msg.display = MESSAGE_DISPLAY.notice
    msg.text = text
    if (options) {
      Object.assign(msg.options, options)
    }
    commit('ADD_MESSAGE', msg)
    return Promise.resolve()
  },
  removeMessage ({ state, commit }, id) {
    if (!id || typeof id !== 'number') {
      return Promise.reject(new Error('No message id!'))
    }
    const msg = state.data.find(message => message.id === id)
    const index = state.data.indexOf(msg)

    if (index === -1) {
      return Promise.reject(new Error('No message index found!'))
    }

    commit('REMOVE_MESSAGE', index)
    return Promise.resolve()
  },
  setMessageAsDisplayed ({ commit }, id) {
    if (!id || typeof id !== 'number') {
      return Promise.reject(new Error('No message id!'))
    }
    const msg = state.data.find(message => message.id === id)
    const index = state.data.indexOf(msg)

    if (index === -1) {
      return Promise.reject(new Error('No message index found!'))
    }
    commit('SET_MESSAGE_AS_DISPLAYED', index)
    return Promise.resolve()
  },
  removeDisplayedMessages ({ state, commit }) {
    const messages = state.data.filter(message => message.options.displayed)
    messages.forEach(message => {
      const index = state.data.indexOf(message)
      commit('REMOVE_MESSAGE', index)
    })
    return Promise.resolve()
  }
}

const getters = {
  getNotices (state) {
    return state.data.filter(message => message.display === MESSAGE_DISPLAY.notice) || []
  },
  getModals (state) {
    return state.data.filter(message => message.display === MESSAGE_DISPLAY.modal) || []
  },
  getModal (state) {
    return state.data.find(message => message.display === MESSAGE_DISPLAY.modal) || null
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
