export const LANGUAGES = {
  pl: 'PL',
  en: 'EN',
  de: 'DE',
  cs: 'CS',
  uk: 'UK',
  sk: 'SK',
  it: 'IT',
  es: 'ES',
  fr: 'FR',
  hu: 'HU',
  hr: 'HR',
  el: 'EL',
  ro: 'RO',
  sl: 'SL',
  tr: 'TR',
  bg: 'BG'
}

export const LANGUAGES_ACTIVE_LIST = [
  LANGUAGES.pl,
  LANGUAGES.en,
  LANGUAGES.uk,
  LANGUAGES.de,
  LANGUAGES.cs,
  LANGUAGES.sk,
  LANGUAGES.it,
  LANGUAGES.es,
  LANGUAGES.fr,
  LANGUAGES.hu,
  LANGUAGES.hr,
  LANGUAGES.sl,
  LANGUAGES.ro,
  LANGUAGES.tr,
  LANGUAGES.bg,
  LANGUAGES.el
]

export const LANGUAGES_LABEL_LIST = {
  [LANGUAGES.pl]: 'polski',
  [LANGUAGES.en]: 'english',
  [LANGUAGES.uk]: 'українська',
  [LANGUAGES.de]: 'deutsch',
  [LANGUAGES.cs]: 'čeština',
  [LANGUAGES.sk]: 'slovenčina',
  [LANGUAGES.it]: 'italiano',
  [LANGUAGES.es]: 'español',
  [LANGUAGES.fr]: 'français',
  [LANGUAGES.hu]: 'magyar',
  [LANGUAGES.hr]: 'hrvatski',
  [LANGUAGES.el]: 'Ελληνικά',
  [LANGUAGES.ro]: 'română',
  [LANGUAGES.sl]: 'slovenščina',
  [LANGUAGES.tr]: 'türkçe',
  [LANGUAGES.bg]: 'български'
}

export const LANGUAGES_CODE_LIST = {
  [LANGUAGES.pl]: 'pl_PL',
  [LANGUAGES.en]: 'en_US',
  [LANGUAGES.uk]: 'uk_UA',
  [LANGUAGES.de]: 'de_DE',
  [LANGUAGES.cs]: 'cs_CZ',
  [LANGUAGES.sk]: 'sk_SK',
  [LANGUAGES.it]: 'it_IT',
  [LANGUAGES.es]: 'es_ES',
  [LANGUAGES.fr]: 'fr_FR',
  [LANGUAGES.hu]: 'hu_HU',
  [LANGUAGES.hr]: 'hr_HR',
  [LANGUAGES.el]: 'el_GR',
  [LANGUAGES.ro]: 'ro_RO',
  [LANGUAGES.sl]: 'sl_SL',
  [LANGUAGES.tr]: 'tr_TR',
  [LANGUAGES.bg]: 'bg_BG'
}

export const LANG_DEFAULT = LANGUAGES.pl
