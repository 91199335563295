export const PAYMENT_CLIENT_SOURCE = {
  frData: 'frData',
  pblData: 'pblData',
  pgData: 'pgData',
  pbcData: 'pbcData',
  pbrData: 'pbrData',
  pbiData: 'pbiData',
  walletData: 'walletData',
  payways: 'payways',
  otherData: 'otherData',
  xmlData: 'xmlData',
  psd2Data: 'psd2Data',
  visaMobileData: 'visaMobileData'
}

export const PAYMENT_SERVICE_TYPE = {
  ecommerce: 'ECOMMERCE',
  verificationBank: 'VERIFICATION_BANK',
  verificationNotBank: 'VERIFICATION_NOT_BANK',
  mwf: 'MWF',
  shoper: 'SHOPER',
  expressTransfers: 'EXPRESS_TRANSFERS',
  public: 'PUBLIC'
}

export const PAYMENT_INIT_TYPE = {
  initWithRefund: 'INIT_WITH_REFUND',
  initWithPayment: 'INIT_WITH_PAYMENT'
}

export const PAYMENT_SERVICE_TYPE_WITH_HIDDEN_REGULATION = [
  PAYMENT_SERVICE_TYPE.verificationBank,
  PAYMENT_SERVICE_TYPE.verificationNotBank,
  PAYMENT_SERVICE_TYPE.ecommerce,
  PAYMENT_SERVICE_TYPE.mwf,
  PAYMENT_SERVICE_TYPE.public
]
