<template>
  <div class="settings-wrapper">
    <button :aria-label="$t('header.availabilityAdjust.changeContrast')" @click="changeContrast" class="contrast" :class="{'active': !settings.defaultContrast}"></button>
  </div>
</template>
<script>
import { ToolsClass } from '@/base/class/tools.class'

const STORAGE_ITEM = 'availabilitySettings'
const THEMES = {
  default: 'theme-default',
  contrast: 'theme-contrast'
}

export default {
  name: 'AvailabilityAdjust',
  data () {
    return {
      fontSizeIncreased: false,
      changeFontClicked: false,
      settings: {
        fontSize: null,
        defaultContrast: true
      }
    }
  },
  created () {
    if (localStorage.getItem(STORAGE_ITEM)) {
      this.settings = JSON.parse(localStorage.getItem(STORAGE_ITEM))
      this.setAllSettings()
      if (this.settings.defaultContrast) {
        document.body.classList.add(THEMES.default)
      } else {
        document.body.classList.remove(THEMES.default)
        document.body.classList.add(THEMES.contrast)
      }
      return
    }
    document.body.classList.add(THEMES.default)
    this.settings.fontSize = parseInt(getComputedStyle(document.documentElement)
      .getPropertyValue('font-size'))
  },
  methods: {
    setFontSize () {
      document.querySelector(':root')
        .style.setProperty('font-size', this.settings.fontSize + 'px')
      this.save()
    },
    increaseFontSize () {
      if (this.fontSizeIncreased) {
        return
      }
      this.settings.fontSize = this.settings.fontSize * 1.2
      this.fontSizeIncreased = true
      this.changeFontClicked = true
      this.setFontSize()
    },
    decreaseFontSize () {
      this.settings.fontSize = 10
      this.fontSizeIncreased = false
      this.changeFontClicked = true
      this.setFontSize()
    },
    changeContrast () {
      this.settings.defaultContrast = !this.settings.defaultContrast
      if (this.settings.defaultContrast) {
        document.body.classList.remove(THEMES.contrast)
        document.body.classList.add(THEMES.default)
      } else {
        document.body.classList.remove(THEMES.default)
        document.body.classList.add(THEMES.contrast)
      }
      this.save()
    },
    save () {
      const data = ToolsClass.deepExtend(this.settings)
      localStorage.setItem(STORAGE_ITEM, JSON.stringify(data))
    },
    setAllSettings () {
      this.setFontSize()
    }
  }
}
</script>
