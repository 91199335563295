import { mapMutations, mapState } from 'vuex'

export default {
  watch: {
    paywallMode: function (value, oldValue) {
      if (value !== oldValue) {
        this.reloadPaywall()
      }
    },
    lang: function (value, oldValue) {
      if (value !== oldValue) {
        this.reloadPaywall()
      }
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.payment.loading,
      paywallMode: state => state.payment.paywallMode,
      paywallView: state => state.payment.paywallView,
      lang: state => state.lang.current
    })
  },
  methods: {
    ...mapMutations([
      'SET_PAYMENT_PAYWALL_VIEW',
      'SET_PAYMENT_LOADING'
    ]),
    reloadPaywall () {
      if (this.isLoading) {
        return
      }

      const paywallViewState = this.paywallView
      this.SET_PAYMENT_LOADING(true)
      this.SET_PAYMENT_PAYWALL_VIEW(false)
      setTimeout(() => {
        this.SET_PAYMENT_LOADING(false)
        this.SET_PAYMENT_PAYWALL_VIEW(paywallViewState)
      }, 500)
    }
  }
}
