export const PAYWALL_MODE = {
  main: 'DEFAULT',
  chosenPayway: 'CHOSEN_PAYWAY',
  regulationsApproval: 'REGULATIONS_APPROVAL'
}

export const PAYWALL_PAYWAY_METHOD = {
  single: 'SINGLE',
  auto: 'AUTO'
}

export const PAYWALL_THEME = {
  light: 'light',
  dark: 'dark'
}

export const PAYWALL_THEME_LIST = [
  PAYWALL_THEME.light,
  PAYWALL_THEME.dark
]

export const THEME_CONTEXT_SUPPORT_LIST = [
  'orangedoppayment',
  'njumobile'
]
