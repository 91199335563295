<template>
  <div class="container">
    <h1 class="page-title_hidden" id="page-title-for-sr">{{ $t('wait.pageTitle') }}</h1>
    <div class="columns">
      <div class="column-full">
        <div class="content loader-wrapper">
          <component v-bind:is="loaderComponent" />
          <div v-if="isPaymentDataSet">
            <payment-psd2-data v-if="isPaymentPsd2DataSet" :payment-data="paymentData" />
            <payment-data v-else :payment-data="paymentData"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentData from '@/base/components/Payment/Data/PaymentData'
import PaymentPsd2Data from '@/base/components/Payment/Data/PaymentPsd2Data'
import loadingMixin from '@/base/mixins/loading.mixin'

export default {
  name: 'PaymentLoading',
  mixins: [loadingMixin],
  components: {
    PaymentData,
    PaymentPsd2Data
  }
}
</script>
