<template>
  <div class="error-template">
    <h2 class="normal" v-if="title">{{ title }}</h2>
    <div class="error-message-wrapper" v-if="isTemplateGeneralError()">
      <p v-for="(message, index) in messages" v-html="message" :key="index"></p>
    </div>
    <error-back-button />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ErrorBackButton from '@/base/components/Error/BackButton'
import { ERROR_CODES, ERROR_CODES_NAMES, ERROR_TEMPLATES } from '@/base/const/errorCodes.const'
import { MobileAppClass } from '@/base/class/mobileApp.class'
import { MOBILE_STATUS } from '@/base/const/mobileEvents.const'
import { ToolsClass } from '@/base/class/tools.class'
import { Raven } from 'vue-raven'
import EventBus from '@/base/event-bus'

export default {
  name: 'ErrorTemplate',
  components: { ErrorBackButton },
  data () {
    return {
      title: null,
      messages: null,
      template: null
    }
  },
  computed: {
    ...mapGetters({
      errorCode: 'getErrorDataCode'
    })
  },
  methods: {
    isTemplateGeneralError () {
      return this.template === ERROR_TEMPLATES.generalError
    },
    generateError () {
      this.title = ERROR_CODES()[this.errorCode].title
      this.messages = ERROR_CODES()[this.errorCode].message
      this.template = ERROR_CODES()[this.errorCode].template

      switch (ERROR_CODES()[this.errorCode]) {
        case ERROR_CODES_NAMES.MULTIPLY_TRANSACTION:
          MobileAppClass.postMessageError(MOBILE_STATUS().blockMultipleTransactions)
          break

        case ERROR_CODES_NAMES.BANK_TEMPORARY_MAINTENANCE:
        case ERROR_CODES_NAMES.BANK_DISABLED:
          MobileAppClass.postMessageError(MOBILE_STATUS().bankDisabled)
          break

        case ERROR_CODES_NAMES.NON_ACCOUNTED_LIMIT_EXCEEDED:
          MobileAppClass.postMessageError(MOBILE_STATUS().nonAccountedLimitExceeded)
          break

        case ERROR_CODES_NAMES.MULTIPLY_PAID_TRANSACTION:
          MobileAppClass.postMessageError(MOBILE_STATUS().blockPaidTransactions)
          break

        case ERROR_CODES_NAMES.TRANSACTION_CANCELED:
          MobileAppClass.postMessageError(MOBILE_STATUS().canceledTransactions)
          break

        case ERROR_CODES_NAMES.INSUFFICIENT_START_AMOUNT:
        case ERROR_CODES_NAMES.START_AMOUNT_OUT_OF_RANGE:
          MobileAppClass.postMessageError(MOBILE_STATUS().insufficientStartAmount)
          break

        default:
          MobileAppClass.postMessageError(MOBILE_STATUS().generalError)
      }
    }
  },
  mounted () {
    EventBus.$on('language-loaded', this.generateError)
    try {
      this.generateError()
    } catch (error) {
      this.title = this.$t('error.generalError.title')
      this.messages = [this.$t('error.generalError.message')]
      if (ToolsClass.isDebugEnabled()) {
        console.log('ErrorTemplate generateError', error, this.errorCode)
      }
      Raven.captureMessage('ErrorTemplate generateError error', {
        extra: {
          error: error,
          errorCode: this.errorCode
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 20px;
}
.error-message-wrapper {
  margin-bottom: 20px;
  p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
