<template>
  <div class="languages" v-if="canShowLanguagesSelector()">
    <div class="lang-selector"><label class="sr-only" for="select_lang">{{ $t('header.availabilityAdjust.changeLanguage') }}</label>
      <select name="langs" id="select_lang" v-model="currentLang">
        <option v-for="(lang, index) in languagesList"
                :value="lang"
                :selected="isLanguageActive(lang)"
                :key="index">{{ getLangLabel(lang) }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import { LANGUAGES_LABEL_LIST } from '@/base/const/lang.const'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { CookieClass } from '@/base/class/cookie.class'
import { COOKIE_LANG } from '@/base/const/cookies.const'

export default {
  name: 'Languages',
  computed: {
    ...mapGetters({
      languagesList: 'getAvailableLanguagesList'
    }),
    currentLang: {
      get () {
        return this.$store.state.lang.current
      },
      set (value) {
        this.setLanguage(value)
      }
    }
  },
  methods: {
    ...mapActions([
      'setLanguage'
    ]),
    ...mapMutations([
      'SET_CURRENT_LANGUAGE'
    ]),
    isLanguageActive (lang) {
      return lang === this.currentLang
    },
    getLangLabel (lang) {
      return LANGUAGES_LABEL_LIST[lang]
    },
    canShowLanguagesSelector () {
      return this.languagesList.length > 1
    }
  },
  created () {
    this.SET_CURRENT_LANGUAGE((CookieClass.getCookie(COOKIE_LANG) && CookieClass.getCookie(COOKIE_LANG).toUpperCase()) || navigator.language.split('-')[0].toUpperCase())
  }
}
</script>
