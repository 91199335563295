export const YOURCX_CONFIG = {
  product: {
    insert: {
      active: true,
      surveyId: {
        PL: 'f15eda31a2da646eea513b0f81a5414d'
      }
    },
    audit: {
      active: true,
      surveyId: 'ccbd8ca962b80445df1f7f38c57759f0'
    },
    target: {
      active: true,
      surveyId: 'f15eda31a2da646eea513b0f81a5414d'
    }
  }
}
