<template>
  <div class="outdated-paid-not-refunded">
    <h2 class="normal" v-html="$t('confirmation.outdatedPaidNotRefunded.header')"></h2>
    <p v-html="$t('confirmation.outdatedPaidNotRefunded.description')"></p><p v-html="$t('confirmation.outdatedPaidNotRefunded.description2')"></p>
  </div>
</template>
<script>
export default {
  name: 'ConfirmationOutdatedPaidNotRefunded'
}
</script>
