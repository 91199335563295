import { ToolsClass } from '@/base/class/tools.class'

export default function (phone) {
  if (!phone) {
    return ''
  }
  let phoneNumber = ToolsClass.cleanPhoneNumber(String(phone))
  if (phoneNumber.length < 9 || phoneNumber.length > 11) {
    return phone
  }

  let phoneNumberArray = []
  if (phoneNumber.length === 9) {
    phoneNumberArray.push(phoneNumber.slice(0, 3))
    phoneNumberArray.push(phoneNumber.slice(3, 6))
    phoneNumberArray.push(phoneNumber.slice(6, 9))
    return phoneNumberArray.join(' ')
  }

  phoneNumberArray.push(phoneNumber.slice(2, 5))
  phoneNumberArray.push(phoneNumber.slice(5, 8))
  phoneNumberArray.push(phoneNumber.slice(8, 11))
  return `${phoneNumberArray.join(' ')}`
}
