import Vue from 'vue'
import DefaultLoader from '@/base/components/Loaders/DefaultLoader'
import ProcessingLoader from '@/base/components/Payment/Processing/Loader'

export function getInitialLoader () {
  return import('@/context/' + Vue.prototype.$VUE_CONTEXT_NAME + '/Loader')
    .then(res => {
      return res.default
    }, () => {
      return DefaultLoader
    })
}

export function getProcessingLoader () {
  return import('@/context/' + Vue.prototype.$VUE_CONTEXT_NAME + '/Processing')
    .then(res => {
      return res.default
    }, () => {
      return ProcessingLoader
    })
}
