import StartComponent from '@/base/views/Start.vue'
import ContinueComponent from '@/base/views/Continue.vue'
import ConfirmationComponent from '@/base/views/Confirmation.vue'
import VisadataComponent from '@/base/views/Visadata.vue'
import SecureBackComponent from '@/base/views/SecureBack.vue'
import i18n from '@/base/i18n/index'

export const ROUTING = {
  start: '/start',
  continue: '/continue',
  wait: '/wait',
  confirmation: '/confirmation',
  thankYou: '/thank-you',
  error: '/error',
  visadata: '/visadata',
  errorNotFound: '/error/404',
  secureBack: '/secure-back',
  maintenance: '/maintenance',
  regulationsApproval: '/regulations-approval',
  sessionTimeout: '/session-timeout',
  paywayError: '/payway-error'
}

export const routes = [
  {
    path: ROUTING.maintenance,
    name: 'Maintenance',
    component: () => import(/* webpackChunkName: "maintenance" */ '@/base/views/Maintenance.vue'),
    meta: {
      title: 'maintenance'
    }
  },
  {
    path: ROUTING.start + '/:apiToken',
    name: 'Start',
    component: StartComponent,
    props: true,
    meta: {
      title: 'start'
    }
  },
  {
    path: ROUTING.visadata + '/:apiToken/:idTransRemote',
    name: 'Visadata',
    component: VisadataComponent,
    props: true,
    meta: {
      title: 'visadata'
    }
  },
  {
    path: ROUTING.thankYou + '/:apiToken',
    name: 'ThankYou',
    component: () => import(/* webpackChunkName: "thankYou" */ '@/base/views/ThankYou.vue'),
    props: true,
    meta: {
      title: 'thankYou'
    }
  },
  {
    path: ROUTING.continue + '/:idTransRemote/:continueHash',
    name: 'Continue',
    props: true,
    component: ContinueComponent,
    meta: {
      title: 'continue'
    }
  },
  {
    path: ROUTING.wait + '/:apiToken/:idTransRemote',
    name: 'Wait',
    props: true,
    component: () => import(/* webpackChunkName: "wait" */ '@/base/views/Wait.vue'),
    meta: {
      title: 'wait'
    }
  },
  {
    path: ROUTING.errorNotFound,
    name: 'ErrorNotFound',
    props: true,
    component: () => import(/* webpackChunkName: "404" */ '@/base/views/404.vue'),
    meta: {
      title: 'errorNotFound'
    }
  },
  {
    path: ROUTING.error + '/:errorHash?',
    name: 'Error',
    props: true,
    component: () => import(/* webpackChunkName: "error" */ '@/base/views/Error.vue'),
    meta: {
      title: 'error'
    }
  },
  {
    path: ROUTING.confirmation,
    name: 'Confirmation',
    component: ConfirmationComponent,
    meta: {
      title: 'confirmation'
    }
  },
  {
    path: ROUTING.secureBack + '/:apiToken',
    name: 'SecureBack',
    props: true,
    component: SecureBackComponent,
    meta: {
      title: 'secureBack'
    }
  },
  {
    path: ROUTING.regulationsApproval + '/:idTransRemote/:continueHash',
    name: 'RegulationsApprovalContinue',
    props: true,
    component: () => import(/* webpackChunkName: "regulationsApproval" */ '@/base/views/RegulationsApproval.vue'),
    meta: {
      title: 'regulationsApproval'
    }
  },
  {
    path: ROUTING.regulationsApproval + '/:apiToken',
    name: 'RegulationsApprovalStart',
    props: true,
    component: () => import(/* webpackChunkName: "regulationsApproval" */ '@/base/views/RegulationsApproval.vue'),
    meta: {
      title: 'regulationsApproval'
    }
  },
  {
    path: ROUTING.sessionTimeout,
    name: 'SessionTimeout',
    component: () => import(/* webpackChunkName: "SessionTimeout" */ '@/base/views/SessionTimeout.vue'),
    meta: {
      title: 'sessionTimeout'
    }
  },
  {
    path: ROUTING.paywayError,
    name: 'paywayError',
    component: () => import(/* webpackChunkName: "paywayError" */ '@/base/views/PaywayError.vue'),
    meta: {
      title: 'paywayError'
    }
  },
  {
    path: '*',
    redirect: ROUTING.errorNotFound
  }
]

export const pageTitle = () => {
  return {
    base: i18n.t('page.title.base'),
    start: i18n.t('page.title.start'),
    visadata: i18n.t('page.title.visadata'),
    continue: i18n.t('page.title.continue'),
    wait: i18n.t('page.title.wait'),
    confirmation: i18n.t('page.title.confirmation'),
    thankYou: i18n.t('page.title.thankYou'),
    error: i18n.t('page.title.error'),
    errorNotFound: i18n.t('page.title.errorNotFound'),
    secureBack: i18n.t('page.title.secureBack'),
    maintenance: i18n.t('page.title.maintenance'),
    regulationsApproval: i18n.t('page.title.regulationsApproval'),
    frData: i18n.t('page.title.toTransferData'),
    sessionTimeout: i18n.t('page.title.sessionTimeout'),
    paywayError: i18n.t('page.title.paywayError')
  }
}
