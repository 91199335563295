<template>
  <div class="positive" :class="{'visa': isVisaTransaction}">
    <div class="visa-video" :aria-label="$t('confirmation.positive.visaVideo')" v-if="isVisaTransaction">
      <video ref="visaVideo" playsinline>
        <source src="/web/video/visa_small.mp4" type="video/mp4" />
      </video>
    </div>
    <h2 class="normal" v-html="$t('confirmation.positive.header')"></h2>
    <p :class="{'visa-text': isVisaTransaction}" v-html="$t('confirmation.positive.description')"></p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { CARD_ISSUERS } from '@/base/const/cards.const'

export default {
  name: 'ConfirmationPositive',
  computed: {
    ...mapState({
      isVisaTransaction: state => state.confirmation.data?.transaction.cardInformation?.issuer === CARD_ISSUERS.visa
    })
  },
  mounted () {
    if (this.isVisaTransaction) {
      const video = this.$refs.visaVideo
      video.play()
        .catch(() => {
          video.muted = true
          video.play()
        })
    }
  }
}
</script>
