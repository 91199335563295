<template>
  <div class="error">
    <img src="/web/img/visa-mobile/trx-error.svg" alt="Błąd" />
  </div>
</template>

<script>
export default {
  name: 'VisaMobileStatusError'
}
</script>

<style lang="scss" scoped>
@import "./src/base/assets/scss/components/_visamobile-loader.scss";
</style>
