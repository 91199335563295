import Vue from 'vue'
import VueRaven from 'vue-raven'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import VueSafeHtml from '@bm/safe-html'
import store from '@/base/store'
import { routes } from '@/base/const/routing.const'
import { filterPhone, filterAmount, filterDate, filterTruncate } from '@/base/filters'
import { ToolsClass } from '@/base/class/tools.class'
import '@/base/polyfills/paymentrequest.js'
import { YOURCX_CONFIG } from '@/base/const/yourcx.const'
import { LOADER_CONFIG } from '@/base/const/loader.const'
import { IS_MAINTENANCE_GLOBAL } from '@/base/const/maintenance.const'
import i18n, { loadLanguage } from '@/base/i18n/index'
import { ENV } from '@/base/const/env.const'
import FormDirective from '@/base/directives/form.directive'
import { CONFIRMATION_REDIRECT_TIME_LONG } from '@/base/const/confirmation.const'

export const initContext = async (contextName, contextApp) => {
  const APP = contextApp
  const CONTEXT = contextName || 'payment'

  Vue.directive('form', FormDirective)
  Vue.filter('date', filterDate)
  Vue.filter('amount', filterAmount)
  Vue.filter('truncate', filterTruncate)
  Vue.filter('phone', filterPhone)
  Vue.config.productionTip = false

  Vue.use(VueRouter)
  Vue.use(VueResource)
  Vue.use(VueMeta)
  Vue.use(VueSafeHtml)

  if (ToolsClass.isProduction()) {
    Vue.use(VueRaven, {
      dsn: process.env.VUE_APP_SENTRY,
      environment: process.env.NODE_ENV
    })
  }

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL + CONTEXT,
    routes
  })

  router.beforeEach((to, from, next) => {
    loadLanguage(i18n.locale)
      .then(() => {
        if (IS_MAINTENANCE_GLOBAL && to.name !== 'Maintenance') {
          next({ name: 'Maintenance' })
        } else {
          next()
        }
      })
      .catch(error => {
        console.error('Failed to load language context-init router beforeEach:', error)
        next()
      })
  })

  const isMockEnabled = () => {
    return process.env.VUE_APP_USE_API_MOCK === 'true'
  }

  const isDevelopmentMode = () => {
    return process.env.NODE_ENV === ENV.development
  }

  Vue.prototype.$VUE_CONTEXT_NAME = CONTEXT
  Vue.prototype.$VUE_YOURCX_CONFIG = YOURCX_CONFIG
  Vue.prototype.$VUE_LOADER_CONFIG = LOADER_CONFIG

  if (isMockEnabled() && isDevelopmentMode()) {
    Vue.prototype.$VUE_CONFIRMATION_TIMER = CONFIRMATION_REDIRECT_TIME_LONG
    await import('../mock/initMsw')
      .then(mswModule => {
        mswModule.startWorker()
      })
  }

  new Vue({
    router,
    store,
    i18n,
    render: h => h(APP)
  }).$mount('#app')
}
