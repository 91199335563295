import { apiClass } from '@/base/class/api.class'
import { ENDPOINTS } from '@/base/const/endpoints.const'
import { Raven } from 'vue-raven'
import { ToolsClass } from '@/base/class/tools.class'

const state = {
  loading: true,
  params: {
    merchantID: null,
    orderID: null,
    amount: null,
    currency: null,
    status: null,
    hash: null,
    apiToken: null
  }
}
const mutations = {
  SET_BACK_LOADING (state, value) {
    state.loading = value
  },
  SET_BACK_PARAMS (state, data) {
    state.params = data
  }
}
const actions = {
  getBackTrx ({ commit, state }) {
    commit('SET_BACK_LOADING', true)
    return apiClass.post(ENDPOINTS.secureBack, state.params)
      .then(response => {
        const data = response.body.data
        if (data.versionSDK) {
          ToolsClass.setVersionSDK(data.versionSDK)
        }
        return Promise.resolve(data.returnUrl)
      })
      .catch(error => {
        commit('SET_BACK_LOADING', false)
        Raven.captureMessage('back.js getBackTrx Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  }
}
const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
