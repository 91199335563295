<template>
  <div class="container">
    <div class="paywall">
      <h1 class="page-title" id="page-title-for-sr">{{ $t('paywall.header') }}</h1>
      <div class="columns" :class="{confirmation: showPaywayNotAvailableInfo}">
        <section class="sidebar-column" v-if="canShowDesktopOrderDetails">
          <order-details-side :transaction="transaction"
                              :visibility="visibilityOptions"
                              :show-button="true"/>
        </section>
        <section class="main-column">
          <div class="content confirmation-wrapper" v-if="showPaywayNotAvailableInfo">
            <info-payway-not-available/>
          </div>
          <div class="content" v-if="!showPaywayNotAvailableInfo">
            <div v-if="paywayGroups">
              <div class="payway-method-switch" v-if="canShowPaywayMethodSwitch()">
                <div role="radio" tabindex="0" name="payway-method" class="payway-method payway-single"
                     :class="{'active': isPaywayMethodSingle()}"
                     :aria-checked="isPaywayMethodSingle()"
                     @click="setPaywayMethodSingle()"
                     @keydown.13.stop="setPaywayMethodSingle()"
                     @keydown.32.prevent
                     @keyup.32="setPaywayMethodSingle()">
                  <span class="method-short">{{ $t('paywall.paywaySwitch.methodShortSingle') }}</span>
                  <span class="method-long">{{ $t('paywall.paywaySwitch.methodLongSingle') }}</span>
                </div>
                <div role="radio" tabindex="0" name="payway-method" class="payway-method payway-auto"
                     :class="{'active': isPaywayMethodAuto()}"
                     :aria-checked="isPaywayMethodAuto()"
                     @click="setPaywayMethodAuto()"
                     @keydown.13.stop="setPaywayMethodAuto()"
                     @keydown.32.prevent
                     @keyup.32="setPaywayMethodAuto()">
                  <span class="method-short">{{ $t('paywall.paywaySwitch.methodShortAuto') }}</span>
                  <span class="method-long">{{ $t('paywall.paywaySwitch.methodLongAuto') }}</span>
                </div>
              </div>
              <ul id="payway-radio-group">
                <template v-for="(paywayGroup, index) in paywayGroups">
                  <li v-if="canShowPaymentGroup(paywayGroup.type)" :key="index">
                    <payment-payway-group :group-id="'payway-radio-' + paywayGroup.type" :group="paywayGroup"/>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="back-link" v-if="visibilityOptions && visibilityOptions.showReturnToShopButton">
            <button type="button"
                    class="btn btn-plain link-back-to-shop"
                    :title="$t('orderDetails.linkBackTitle')"
                    v-if="isPaywallView"
                    @click="sendPaymentReject">
              {{ $t('orderDetails.linkBack') }}
            </button>
            <button type="button"
                    class="btn btn-plain link-back-to-shop"
                    :title="$t('orderDetails.linkBack')"
                    v-if="!isPaywallView"
                    @click="goBackToShop(returnUrl)">
              {{ $t('orderDetails.linkBack') }}
            </button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentPaywayGroup from '@/base/components/Payment/Paywall/PaywayGroup'
import OrderDetailsSide from '@/base/components/OrderDetails/Side'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { MatomoClass } from '@/base/class/matomo.class'
import { MATOMO_EVENT_TYPE, MATOMO_PRODUCT_SKU } from '@/base/const/matomo.const'
import { DateClass } from '@/base/class/date.class'
import { MobileAppClass } from '@/base/class/mobileApp.class'
import { MOBILE_STATUS } from '@/base/const/mobileEvents.const'
import { PAYWALL_MODE, PAYWALL_PAYWAY_METHOD } from '@/base/const/paywall.const'
import { ToolsClass } from '@/base/class/tools.class'
import InfoPaywayNotAvailable from '@/base/components/Payment/Info/PaywayNotAvailable'
import { AUTO_ID_LIST, PAYWAY_GROUPS_TYPES } from '@/base/const/payway.const'
import { Raven } from 'vue-raven'
import Vue from 'vue'
import { ROUTING } from '@/base/const/routing.const'
import { LANGUAGES_CODE_LIST } from '@/base/const/lang.const'

export default {
  name: 'PaymentPaywall',
  components: {
    InfoPaywayNotAvailable,
    PaymentPaywayGroup,
    OrderDetailsSide
  },
  data () {
    return {
      showPaywayNotAvailableInfo: false
    }
  },
  computed: {
    ...mapState({
      transaction: state => state.payment.paymentData.transaction,
      visibilityOptions: state => state.payment.paymentData.visibilityOptions,
      click2PayData: state => state.payment.paymentData.click2PayData,
      serviceType: state => state.payment.paymentData.serviceType,
      isPaywallModeChosenPayway: state => state.payment.paywallMode === PAYWALL_MODE.chosenPayway,
      isPaywallView: state => state.payment.paywallView,
      chosenPaywayId: state => state.payment.paymentData.chosenPaywayId,
      paywayMethod: state => state.payment.paywayMethod,
      returnUrl: state => state.payment.paymentData.returnUrl,
      customerEmail: state => state.payment.form.customerEmail,
      language: state => state.lang.current
    }),
    ...mapGetters({
      paywayGroups: 'getPaymentGroupList',
      isDefaultLangActive: 'isDefaultLangActive',
      isPaymentCardAvailable: 'isPaymentCardAvailable'
    }),
    isPaymentMethodAutoAvailable: function () {
      return !!this.paywayGroups.find(group => group.type === PAYWAY_GROUPS_TYPES.auto)
    },
    isPaymentMethodSingleAvailable: function () {
      return !!this.paywayGroups.find(group => group.type !== PAYWAY_GROUPS_TYPES.auto)
    },
    canShowDesktopOrderDetails: function () {
      return !this.$VUE_HIDE_TRANSACTION_INFO
    }
  },
  methods: {
    ...mapMutations([
      'SET_TIMER_PAYWALL_VIEW',
      'SET_ERROR_DATA',
      'SET_PAYMENT_PAYWALL_VIEW',
      'SET_PAYWAY_METHOD',
      'SET_PAYMENT_FORM_PAYWAY_ID',
      'SET_PAYMENT_FORM_REGULATION_ID',
      'SET_PAYMENT_FORM_REGULATION_APPROVED',
      'SET_CLICK2PAY_EMAIL',
      'SET_CLICK2PAY_TRANSACTION_AMOUNT',
      'SET_CLICK2PAY_TRANSACTION_CURRENCY',
      'SET_CLICK2PAY_IS_PROCESS_AVAILABLE',
      'SET_CLICK2PAY_LOADING'
    ]),
    ...mapActions([
      'setPaymentGroupSelected',
      'setPaymentActivePayway',
      'sendPaymentRejectTrx',
      'getClick2PayScript',
      'getClick2PayInit'
    ]),
    goBackToShop (returnUrl) {
      if (ToolsClass.isMobileAppConnection()) {
        MobileAppClass.callMobileTransactionFinish()
        return
      }
      ToolsClass.goToExternalUrl(returnUrl)
    },
    sendPaymentReject () {
      this.sendPaymentRejectTrx()
        .then(returnUrl => {
          ToolsClass.goToExternalUrl(returnUrl)
        })
        .catch(error => {
          MobileAppClass.callMobileTransactionFinish()
          Raven.captureMessage('sendPaymentReject Method response error', {
            extra: error
          })
        })
    },
    canShowPaywayMethodSwitch () {
      return !this.isPaywallModeChosenPayway && this.isPaymentMethodAutoAvailable && this.isPaymentMethodSingleAvailable
    },
    canShowPaymentGroup (paymentGroupType) {
      if (this.isPaywayMethodSingle()) {
        return paymentGroupType !== PAYWAY_GROUPS_TYPES.auto
      } else {
        return paymentGroupType === PAYWAY_GROUPS_TYPES.auto
      }
    },
    isPaywayMethodSingle () {
      return this.paywayMethod === PAYWALL_PAYWAY_METHOD.single
    },
    setPaywayMethodSingle () {
      this.SET_PAYWAY_METHOD(PAYWALL_PAYWAY_METHOD.single)
      this.SET_PAYMENT_FORM_PAYWAY_ID(null)
      this.SET_PAYMENT_FORM_REGULATION_ID(null)
      this.SET_PAYMENT_FORM_REGULATION_APPROVED(false)
    },
    isPaywayMethodAuto () {
      return this.paywayMethod === PAYWALL_PAYWAY_METHOD.auto
    },
    goToPaywayError () {
      this.SET_PAYMENT_PAYWALL_VIEW(false)
      this.$router.push(ROUTING.paywayError)
    },
    setPaywayMethodAuto () {
      this.SET_PAYWAY_METHOD(PAYWALL_PAYWAY_METHOD.auto)
      this.activateAutoGroup()
      if (!this.paywayGroups || !this.paywayGroups.length) {
        this.goToPaywayError()
      }
    },
    setPaywayGroup (group) {
      this.setPaymentGroupSelected(group)
    },
    sendEventEcommerceView () {
      if (!this.transaction || !MatomoClass.isMatomoLoaded()) {
        setTimeout(this.sendEventEcommerceView, 100)
        return
      }
      const event = [
        MATOMO_EVENT_TYPE.setEcommerceView,
        MATOMO_PRODUCT_SKU[this.serviceType],
        this.serviceType,
        '',
        this.transaction.payAmount
      ]
      MatomoClass.sendEvent(event)
      MatomoClass.sendTrackPageView()
      setTimeout(this.sendEventEcommerceAddItem, 2000)
    },
    sendEventEcommerceAddItem () {
      const event = [
        MATOMO_EVENT_TYPE.addEcommerceItem,
        MATOMO_PRODUCT_SKU[this.serviceType],
        this.serviceType,
        'Płatność',
        this.transaction.payAmount,
        1
      ]
      MatomoClass.sendEvent(event)
    },
    activateAutoGroup () {
      const autoGroup = this.paywayGroups.find(group => group?.type === PAYWAY_GROUPS_TYPES.auto)
      if (!autoGroup) {
        this.goToPaywayError()
        return
      }
      this.setPaywayGroup(autoGroup)
      if (autoGroup.payways.length === 1) {
        this.setPaymentActivePayway(autoGroup.payways[0])
      } else {
        this.setPaymentActivePayway(null)
      }
    }
  },
  mounted () {
    if (window.yourcx && this.$VUE_YOURCX_CONFIG.product.audit.active && this.isDefaultLangActive) {
      window.yourcx('survey', this.$VUE_YOURCX_CONFIG.product.audit.surveyId, { product: 'audit' })
    }

    this.sendEventEcommerceView()
    this.SET_TIMER_PAYWALL_VIEW(DateClass.getCurrentTimestamp())
    MobileAppClass.postMessageSuccess(MOBILE_STATUS().paywaylistLoaded)

    // inicjalizacja MasterCard Click2Pay
    if (this.isPaymentCardAvailable && this.click2PayData) {
      this.getClick2PayScript()
        .then(() => {
          const data = {
            initiatorId: this.click2PayData.srcInitiatorId,
            dpaId: this.click2PayData.srciDpaId,
            transactionId: this.click2PayData.srciTransactionId,
            transactionAmount: this.transaction.payAmount,
            transactionCurrencyCode: this.transaction.payAmountCurrency,
            locale: LANGUAGES_CODE_LIST[this.language],
            dpaPresentationName: this.transaction.receiverNameForFront,
            dpaName: this.transaction.receiverNameForFront
          }
          this.SET_CLICK2PAY_IS_PROCESS_AVAILABLE(true)
          this.SET_CLICK2PAY_EMAIL(this.customerEmail)
          this.SET_CLICK2PAY_TRANSACTION_AMOUNT(this.transaction.payAmount)
          this.SET_CLICK2PAY_TRANSACTION_CURRENCY(this.transaction.payAmountCurrency)
          this.getClick2PayInit(data).catch(() => {})
        })
        .catch(() => {
          this.SET_CLICK2PAY_LOADING(false)
          this.SET_CLICK2PAY_IS_PROCESS_AVAILABLE(false)
        })
    }

    // start z wybranym kanałem walletowym, który jest niedostępny
    if (this.isPaywallModeChosenPayway && !ToolsClass.isWalletPaywayAvailable(this.chosenPaywayId)) {
      this.SET_PAYMENT_PAYWALL_VIEW(false)
      this.showPaywayNotAvailableInfo = true
      return
    }

    // start z wybranym kanałem płatności automatycznej
    if (this.isPaywallModeChosenPayway && AUTO_ID_LIST.includes(this.chosenPaywayId)) {
      this.setPaywayMethodAuto()
      return
    }

    // normalny start, ale mamy dostępną płatność automatyczną
    if (this.isPaymentMethodAutoAvailable && !Vue.prototype.$VUE_PAYWALL_FORCE_SINGLE_PAYMENT) {
      this.setPaywayMethodAuto()
      return
    }

    // normalny start, ale dostępna tylko 1 grupa płatności (start z wybranym kanałem płatności)
    if (this.paywayGroups.length === 1 && this.paywayGroups[0].active) {
      this.setPaywayGroup(this.paywayGroups[0])
    }

    // brak grup płatności
    if (this.paywayGroups.length === 0) {
      this.goToPaywayError()
    }
  },
  updated () {
    if (this.isPaywayMethodAuto()) {
      this.activateAutoGroup()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/base/assets/scss/components/Paywall.scss";
</style>
