<template>
  <google-pay-button
    v-if="showButton"
    ref="gp"
    :environment="payEnvironment"
    :button-type="buttonType"
    :button-color="buttonColor"
    :button-locale="language"
    :existing-payment-method-required="existingPaymentMethodRequired"
    :paymentRequest.prop="paymentRequest"
    @loadpaymentdata="onLoadPaymentData"
    @error="onError"
    @cancel="onCancel"
    :onPaymentAuthorized.prop="onPaymentDataAuthorized"
    :onReadyToPayChange.prop="onReadyToPayChange"
  ></google-pay-button>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import '@google-pay/button-element'
import base64 from 'base-64'
import { Raven } from 'vue-raven'

export default {
  name: 'GooglePayPayway',
  data () {
    return {
      showButton: false,
      formValid: false,
      payEnvironment: process.env.VUE_APP_GP_ENV,
      existingPaymentMethodRequired: false,
      buttonColor: 'default',
      buttonType: 'buy',
      paymentRequest: null
    }
  },
  props: {
    marketing: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      paymentData: state => state.payment.paymentData,
      language: state => state.lang.current.toLowerCase()
    })
  },
  methods: {
    ...mapActions([
      'sendPaymentUpdateTrx',
      'getWalletData',
      'disableSessionTimer'
    ]),
    onLoadPaymentData () {},
    onError (event) {
      Raven.captureMessage('GooglePay error', {
        extra: event
      })
    },
    onCancel () {
      this.formValid = false
    },
    onPaymentDataAuthorized (paymentData) {
      return new Promise((resolve, reject) => {
        this.disableSessionTimer()
        const token = base64.encode(JSON.stringify(paymentData.paymentMethodData.tokenizationData.token))
        const data = {
          paymentToken: token,
          marketing: this.marketing
        }
        this.sendPaymentUpdateTrx(data)
          .then(() => {
            resolve({ transactionState: 'SUCCESS' })
          })
          .catch(() => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ transactionState: 'ERROR' })
          })
      })
    },
    onReadyToPayChange () {
      this.$refs.gp.querySelector('button').addEventListener('click', e => {
        const form = this.$parent.form
        if (!form) {
          return
        }
        form.setAsSubmitted()
        if (!this.formValid) {
          e.stopImmediatePropagation()
          if (form.valid) {
            this.formValid = true
            this.$refs.gp.querySelector('button').dispatchEvent(new Event('click'))
          }
        }
      }, true)
    }
  },
  created () {
    this.getWalletData()
      .then(response => {
        const merchantData = response.googlePay.merchant
        this.paymentRequest = {
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: 'CARD',
              parameters: {
                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                allowedCardNetworks: ['MASTERCARD', 'VISA']
              },
              tokenizationSpecification: {
                type: 'PAYMENT_GATEWAY',
                parameters: {
                  gateway: process.env.VUE_APP_GP_GATEWAY,
                  gatewayMerchantId: String(this.paymentData.acceptorID)
                }
              }
            }
          ],
          merchantInfo: {
            merchantId: String(merchantData.id),
            merchantOrigin: merchantData.domain,
            merchantName: merchantData.name
          },
          transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPriceLabel: this.paymentData.transaction.orderId,
            totalPrice: this.paymentData.transaction.payAmount.toFixed(2),
            currencyCode: this.paymentData.transaction.payAmountCurrency,
            countryCode: 'PL'
          },
          callbackIntents: ['PAYMENT_AUTHORIZATION']
        }
        this.showButton = true
      })
      .catch(error => {
        Raven.captureMessage('GooglePay init error', {
          extra: error
        })
      })
  }
}
</script>
