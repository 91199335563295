export class DateClass {
  /**
   * @param {Date} date
   * @param {string} format
   * @returns {string}
   */
  static getFormattedDate (date, format = 'Y-m-d H:i:s') {
    if (!date || !(date instanceof Date)) {
      return
    }

    const Y = date.getFullYear().toString()
    const m = ('0' + (date.getMonth() + 1)).slice(-2)
    const d = ('0' + date.getDate()).slice(-2)
    const H = ('0' + date.getHours()).slice(-2)
    const i = ('0' + date.getMinutes()).slice(-2)
    const s = ('0' + date.getSeconds()).slice(-2)

    switch (format) {
      case 'Y-m-d H:i:s':
        return [Y, '-', m, '-', d, ' ', H, ':', i, ':', s].join('')

      case 'YmdHis':
      default:
        return [Y, m, d, H, i, s].join('')
    }
  }

  /**
   * @returns {number}
   */
  static getCurrentYear () {
    return new Date().getFullYear()
  }

  /**
   * @returns {number}
   */
  static getCurrentTimestamp () {
    return new Date().getTime()
  }

  /**
   * @param {string|null} dateStart
   * @param {string|null} dateEnd
   * @returns {boolean}
   */
  static isDateActive (dateStart, dateEnd) {
    const now = DateClass.getCurrentTimestamp()
    const start = dateStart ? Date.parse(dateStart) : now
    const end = dateEnd ? Date.parse(dateEnd) : now
    return now >= start && now <= end
  }
}
