import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import { ToolsClass } from '@/base/class/tools.class'

Vue.use(Vuex)

export default new Vuex.Store({
  modules,
  strict: !ToolsClass.isProduction()
})
