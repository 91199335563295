import { ToolsClass } from '@/base/class/tools.class'
import { MOBILE_EVENT_NAMES, MOBILE_STATUS } from '@/base/const/mobileEvents.const'

const SDK_3_1 = '3_1'

export class MobileAppClass {
  static emitMobileAppEvents (eventName, eventData) {
    if (!ToolsClass.isMobileAppConnection()) {
      return
    }
    if (ToolsClass.isIOS(eventName)) {
      window.webkit.messageHandlers[eventName].postMessage(eventData)
    }
    if (ToolsClass.isAndroid()) {
      window.PAYBM_MOBILE[eventName](eventData)
    }
    if (ToolsClass.isDebugEnabled()) {
      console.log('emitMobileAppEvents', eventName, eventData)
    }
  }

  static callMobileTransactionFinish () {
    if (!ToolsClass.isMobileAppConnection()) {
      return
    }

    if (ToolsClass.isAndroid()) {
      window.PAYBM_MOBILE.transactionFinishCallback()
    }

    if (ToolsClass.isIOS('transactionFinishCallback')) {
      window.webkit.messageHandlers.transactionFinishCallback.postMessage({})
    }

    if (ToolsClass.isDebugEnabled()) {
      console.log('callMobileTransactionFinish')
    }
  }

  /**
   * @param {string} version1
   * @param {string} version2
   * @param {string} separator
   * @returns {(boolean|number)} false, jeżeli version1 lub version2 nie są typu string; -1 jeżeli version1 < version2; 0 jeżeli version1 === version2; 1 jeżeli version1 > version2.
   */
  static compareVersion (version1, version2, separator = '_') {
    const ver1 = String(version1).split(separator)
    const ver2 = String(version2).split(separator)
    const length = Math.min(version1.length, version2.length)

    for (let i = 0; i < length; ++i) {
      ver1[i] = parseInt(ver1[i], 10)
      ver2[i] = parseInt(ver2[i], 10)

      if (ver1[i] > ver2[i]) {
        return 1
      }

      if (ver1[i] < ver2[i]) {
        return -1
      }
    }
    if (ver1.length === ver2.length) {
      return 0
    }
    return ver1.length < ver2.length ? -1 : 1
  }

  /**
   * @param {string} version
   * @param {string} minVersion
   * @returns {boolean}
   */
  static isNewerSDK (version, minVersion) {
    const result = MobileAppClass.compareVersion(version, minVersion)
    return result === 1 || result === 0
  }

  /**
   * @param {Object} status
   */
  static postMessageSuccess (status) {
    const statusName = MobileAppClass.getStatusNameBySDKVersion(status)
    const statusData = JSON.stringify({ status: statusName, message: status.description })
    MobileAppClass.emitMobileAppEvents(MOBILE_EVENT_NAMES.transactionStartCallback, statusData)
  }

  /**
   * @param {Object} status
   */
  static postMessageError (status) {
    const statusName = MobileAppClass.getStatusNameBySDKVersion(status)
    const statusData = JSON.stringify({ status: statusName, message: status.description })
    MobileAppClass.emitMobileAppEvents(MOBILE_EVENT_NAMES.transactionFinishCallback, statusData)
  }

  /**
   * @param {Object} status
   * @param {string} url
   */
  static transactionDynamicLinks (status, url) {
    const statusName = MobileAppClass.getStatusNameBySDKVersion(status)
    const statusData = JSON.stringify({ status: statusName, message: url })
    MobileAppClass.emitMobileAppEvents(MOBILE_EVENT_NAMES.linkClickCallback, statusData)
  }

  /**
   * @param {Object} status
   * @returns {*}
   */
  static getStatusNameBySDKVersion (status) {
    return (ToolsClass.isVersionSDKSet() && MobileAppClass.isNewerSDK(ToolsClass.getVersionSDK(), SDK_3_1))
      ? status.newName
      : status.name
  }

  /**
   * @param {string} version
   */
  static setVersionSDKParam (version) {
    ToolsClass.setVersionSDK(version)
    if (ToolsClass.isDebugEnabled()) {
      console.log('setVersionSDK', version)
    }
  }

  static initMobileSDKMock () {
    window.PAYBM_MOBILE = {
      transactionStartCallback: () => {},
      transactionFinishCallback: () => {},
      linkClickCallback: () => {}
    }
  }

  static supportExternalLinks () {
    if (!ToolsClass.isMobileAppConnection()) {
      return
    }
    setTimeout(() => {
      const externalLinks = document.querySelectorAll('a[target="_blank"]')
      externalLinks.forEach(element => {
        element.addEventListener('click', event => {
          event.preventDefault()
          event.stopImmediatePropagation()
          MobileAppClass.transactionDynamicLinks(MOBILE_STATUS().webPageClick, element.href)
        })
      })
    }, 1000)
  }
}
