import { Raven } from 'vue-raven'

const state = {
  appStart: null,
  trxGetStart: null,
  trxStartLoaded: null,
  paywallView: null,
  formSubmited: null,
  trxUpdateStart: null,
  trxUpdateLoaded: null
}
const mutations = {
  SET_TIMER_APP_START (state, value) {
    state.appStart = value
  },
  SET_TIMER_TRX_GET_START (state, value) {
    state.trxGetStart = value
  },
  SET_TIMER_TRX_START_LOADED (state, value) {
    state.trxStartLoaded = value
  },
  SET_TIMER_PAYWALL_VIEW (state, value) {
    state.paywallView = value
  },
  SET_TIMER_FORM_SUBMITED (state, value) {
    state.formSubmited = value
  },
  SET_TIMER_TRX_UPDATE_START (state, value) {
    state.trxUpdateStart = value
  },
  SET_TIMER_TRX_UPDATE_LOADED (state, value) {
    state.trxUpdateLoaded = value
  }
}
const actions = {
  logTimerStats ({ state }) {
    const timeData = {
      timeToTrxGetStart: (state.trxGetStart - state.appStart) / 1000,
      timeToTrxGetLoaded: (state.trxStartLoaded - state.appStart) / 1000,
      timeToPaywallView: (state.paywallView - state.appStart) / 1000,
      timeToFormSubmited: (state.formSubmited - state.appStart) / 1000,
      timeToTrxUpdateStart: (state.trxUpdateStart - state.appStart) / 1000,
      timeToTrxUpdateLoaded: (state.trxUpdateLoaded - state.appStart) / 1000
    }
    Raven.captureMessage('TIMER STATS', {
      extra: timeData
    })
  }
}
const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
