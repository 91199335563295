<template>
  <div class="container">
    <h1 class="page-title_hidden" id="page-title-for-sr">{{ $t('paywall.processing.pageTitle') }}</h1>
    <div class="columns loader"><span class="sr-only">{{ $t('paywall.processing.loaderAnimation') }}</span>
      <section class="sidebar-column">
        <order-details-side :transaction="transaction"
                            :visibility="visibilityOptions"
                            :show-button="false" />
      </section>
      <section class="main-column">
        <div class="content">
          <div class="payment-processing-loader-wrapper">
            <payment-processing-loader />
            <div class="loader-text" v-if="!isPaymentDataSet">
              <h2>{{ $t('paywall.processing.header') }}</h2>
              <p>{{ $t('paywall.processing.description') }}</p>

              <div class="info">
                <h3>{{ $t('paywall.processing.info.header') }}</h3>
                <p>{{ $t('paywall.processing.info.description') }}</p>
              </div>
            </div>
            <div class="loader-text" v-if="isPaymentDataSet && isPaymentPsd2DataSet">
              <h2 role="alert" v-html="$t('paywall.processing.loaderText')"></h2>
            </div>
          </div>
          <div v-if="isPaymentDataSet">
            <payment-psd2-data v-if="isPaymentPsd2DataSet" :payment-data="paymentData" />
            <payment-data v-else :payment-data="paymentData"/>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PaymentProcessingLoader from './ProcessingLoader'
import OrderDetailsSide from '@/base/components/OrderDetails/Side'
import PaymentData from '@/base/components/Payment/Data/PaymentData'
import loadingMixin from '@/base/mixins/loading.mixin'
import { ToolsClass } from '@/base/class/tools.class'
import PaymentPsd2Data from '@/base/components/Payment/Data/PaymentPsd2Data'

export default {
  name: 'PaymentProcessing',
  mixins: [loadingMixin],
  components: {
    PaymentPsd2Data,
    OrderDetailsSide,
    PaymentProcessingLoader,
    PaymentData
  },
  computed: {
    ...mapState({
      transaction: state => state.payment.paymentData.transaction,
      visibilityOptions: state => state.payment.paymentData.visibilityOptions
    })
  },
  created () {
    ToolsClass.scrollToElementTop(document.querySelector('header'))
  }
}
</script>

<style lang="scss" scoped>
@import "./src/base/assets/scss/components/_processing-loader.scss";
</style>
