<template>
  <div class="cart-container">
    <ol class="cart">
      <li v-for="(product, index) in getFirstPartOfCartProducts()" :key="index" class="cart-item">
          <div class="cart-item-title">{{ getParamWithProductName(product.productBasketItemParams).value }}</div>
          <div class="cart-item-amount">{{ product.amount | amount }} {{ product.amountCurrency }}</div>
      </li>
    </ol>
    <ol class="cart" :start="secondListStartCounter" v-if="isSecondListNeeded && showSecondList">
      <li v-for="(product, index) in getRestOfCartProducts()" :key="index" class="cart-item">
          <div class="cart-item-title">{{ getParamWithProductName(product.productBasketItemParams).value }}</div>
          <div class="cart-item-amount">{{ product.amount | amount }} {{ product.amountCurrency }}</div>
      </li>
    </ol>
    <a class="show-more" href="" @click.prevent="showSecondList = true" v-if="isSecondListNeeded && !showSecondList">{{ $t('orderDetails.linkMore') }}</a>
    <a class="show-less" href="" @click.prevent="showSecondList = false" v-if="isSecondListNeeded && showSecondList">{{ $t('orderDetails.linkLess') }}</a>
  </div>
</template>

<script>
import { mapState } from 'vuex'
const PRODUCTS_COUNTER = 2
export default {
  name: 'OrderDetailsCart',
  data () {
    return {
      showSecondList: false
    }
  },
  computed: {
    ...mapState({
      cart: state => state.payment.paymentData.productBasket
    }),
    secondListStartCounter: () => PRODUCTS_COUNTER + 1,
    isSecondListNeeded: function () {
      return this.cart.productBasketItems.length > PRODUCTS_COUNTER
    }
  },
  methods: {
    getFirstPartOfCartProducts () {
      return this.cart.productBasketItems.slice(0, PRODUCTS_COUNTER)
    },
    getRestOfCartProducts () {
      return this.cart.productBasketItems.slice(PRODUCTS_COUNTER)
    },
    getParamWithProductName (params) {
      if (params.length === 1) {
        return params[0]
      }
      return params.find(param => param.title === 'Nazwa produktu')
    }
  }
}
</script>
