import { EVENT_CODES } from '@/base/const/eventCodes.const'

class EventsClass {
  constructor () {
    this.body = document.body
    this.mouseHandler = () => {
      this.body.classList.remove('using-keyboard')
      this.body.classList.add('using-mouse')
    }
    this.keyboardHandler = event => {
      if (event.code === EVENT_CODES.space && (event.target.getAttribute('role') && event.target.getAttribute('role') === 'radio')) {
        event.preventDefault()
      }
      if (event.code === EVENT_CODES.tab) {
        this.body.classList.remove('using-mouse')
        this.body.classList.add('using-keyboard')
      }
    }
  }

  addEventsHandlers () {
    this.body.addEventListener('mousedown', this.mouseHandler)
    this.body.addEventListener('keydown', this.keyboardHandler)
  }

  removeEventsHandlers () {
    this.body.removeEventListener('mousedown', this.mouseHandler)
    this.body.removeEventListener('keydown', this.keyboardHandler)
  }
}

export const eventsClass = new EventsClass()
