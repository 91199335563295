export const MESSAGE_TYPE = {
  error: 'ERROR',
  success: 'SUCCESS',
  warning: 'WARNING',
  info: 'INFO'
}

export const MESSAGE_DISPLAY = {
  notice: 'NOTICE',
  modal: 'MODAL'
}

export const MESSAGE_MODEL = {
  id: null,
  type: null,
  display: null,
  text: null,
  component: null,
  options: {
    title: null,
    time: 5000,
    autoHide: true,
    hideButton: false,
    showClose: false,
    displayed: false,
    size: null
  }
}
