<template>
  <component v-bind:is="loaderComponent" />
</template>

<script>

import { getProcessingLoader } from '@/base/utils/load'

export default {
  name: 'PaymentProcessingLoader',
  computed: {
    loaderComponent: function () {
      return this.load
    }
  },
  methods: {
    load () {
      return getProcessingLoader()
    }
  }
}
</script>
