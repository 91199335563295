import { PAYMENT_SERVICE_TYPE } from '@/base/const/payment.const'
import { PAYWAY_GROUPS_TYPES } from '@/base/const/payway.const'

export const MATOMO_EVENT_TYPE = {
  trackEvent: 'trackEvent',
  trackPageView: 'trackPageView',
  setEcommerceView: 'setEcommerceView',
  addEcommerceItem: 'addEcommerceItem',
  trackEcommerceOrder: 'trackEcommerceOrder',
  setCustomDimension: 'setCustomDimension'
}

export const MATOMO_EVENT_CATEGORY = {
  validationError: 'blad_walidacji',
  experiments: 'eksperymenty'
}

export const MATOMO_VALIDATION_ERROR_NAME = {
  paywayID: 'bank',
  customerEmail: 'email',
  regulationAcceptanceId: 'regulamin',
  cardData: 'dane_karty_platniczej',
  visaMobile: 'dane_visa_mobile'
}

export const MATOMO_PRODUCT_SKU = {
  [PAYMENT_SERVICE_TYPE.mwf]: 1,
  [PAYMENT_SERVICE_TYPE.ecommerce]: 2,
  [PAYMENT_SERVICE_TYPE.verificationBank]: 3,
  [PAYMENT_SERVICE_TYPE.verificationNotBank]: 4,
  [PAYMENT_SERVICE_TYPE.shoper]: 5,
  [PAYMENT_SERVICE_TYPE.expressTransfers]: 6,
  [PAYMENT_SERVICE_TYPE.public]: 7
}

export const MATOMO_CUSTOM_DIMENSION = {
  sid: 1,
  paymentTypeGroup: 2,
  paymentTypeChannel: 4,
  userType: 3
}

export const MATOMO_PAYMENT_TYPE_GROUP = {
  [PAYWAY_GROUPS_TYPES.auto]: 'auto',
  [PAYWAY_GROUPS_TYPES.blik]: 'blik',
  [PAYWAY_GROUPS_TYPES.card]: 'karta',
  [PAYWAY_GROUPS_TYPES.pbl]: 'przelew',
  [PAYWAY_GROUPS_TYPES.wallet]: 'portfel',
  [PAYWAY_GROUPS_TYPES.installments]: 'raty',
  [PAYWAY_GROUPS_TYPES.otp]: 'otp',
  [PAYWAY_GROUPS_TYPES.transfer]: 'dane_do_przelewu',
  [PAYWAY_GROUPS_TYPES.other]: 'inny'
}

export const MATOMO_USER_TYPE = {
  direct: 'nowy',
  returning: 'powracający'
}
