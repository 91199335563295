import { ToolsClass } from '@/base/class/tools.class'
import {
  MATOMO_EVENT_CATEGORY,
  MATOMO_EVENT_TYPE,
  MATOMO_PAYMENT_TYPE_GROUP,
  MATOMO_VALIDATION_ERROR_NAME
} from '@/base/const/matomo.const'
import { MatomoClass } from '@/base/class/matomo.class'
import { mapMutations, mapActions } from 'vuex'
import { WidgetConnection, widgetEvents } from '@bm/widget-connection'

export default {
  beforeDestroy () {
    if (this.visaWidget) {
      this.visaWidget.stopConnection()
    }
  },
  methods: {
    ...mapMutations([
      'SET_PAYMENT_FORM_VISAMOBILE'
    ]),
    ...mapActions([
      'addModalInfo'
    ]),
    getVisaMobileIframeSrc () {
      return process.env.VUE_APP_WIDGET_NEW_VISA_MOBILE_URL
    },
    setVisaMobileIframeLoaded () {
      const widgetConfig = {
        merchantName: this.merchantShortName,
        amount: this.amount,
        currency: this.currency,
        idTransRemote: this.idTransRemote,
        paywallStartTime: this.paywallStartTime
      }
      this.visaWidget = new WidgetConnection(widgetConfig, ToolsClass.isDebugEnabled())
      this.visaWidget.startConnection(this.$refs.iframe)
        .then(() => {
          this.iframeLoaded = true
          this.visaWidget.on(widgetEvents.validityStatus, (message, data) => {
            const result = message ? Object.keys(message).every(key => message[key].valid) : data.valid
            this.setVisaMobileError(result)
          })
          this.visaWidget.on(widgetEvents.validationResult, (message, data) => {
            const result = message ? message.valid : data.valid
            this.setVisaMobileError(result, true)
          })
          this.visaWidget.on(widgetEvents.formSuccess, (message, data) => {
            if (data.prefix && data.phoneNumber) {
              this.SET_PAYMENT_FORM_VISAMOBILE({
                prefix: data.prefix,
                phoneNumber: data.phoneNumber
              })
            }
            this.sendToken(message)
          })
          this.visaWidget.on(widgetEvents.enterKey, () => {
            this.submit()
          })
          this.visaWidget.on(widgetEvents.showModal, () => {
            const message = {
              focusReturnElementId: 'iframeVisaMobile',
              component: 'VisaHelpModal1',
              options: {
                size: 'medium'
              }
            }
            this.addModalInfo(message)
          })
        })
      this.visaWidget.changeLanguage(this.lang)
    },
    setVisaMobileError (valid, sendEvent) {
      if (!valid) {
        if (this.visaMobile) {
          this.visaMobile = false
          if (this.form.invalid) {
            this.scrollToError()
          }
        }
        if (sendEvent) {
          const event = [
            MATOMO_EVENT_TYPE.trackEvent,
            MATOMO_EVENT_CATEGORY.validationError,
            MATOMO_PAYMENT_TYPE_GROUP[this.selectedPaywayGroupType],
            MATOMO_VALIDATION_ERROR_NAME.visaMobile
          ]
          MatomoClass.sendEvent(event)
        }
      } else {
        if (!this.visaMobile) {
          this.visaMobile = true
        }
      }
    }
  }
}
