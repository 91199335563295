<template>
  <div v-if="canMakePayments" :class="buttonClasses" @click="onClick"></div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import base64 from 'base-64'
import { Raven } from 'vue-raven'
import { ToolsClass } from '@/base/class/tools.class'

export default {
  name: 'ApplePayButton',
  props: {
    color: {
      type: String,
      required: false,
      default: 'black',
      validator: function (value) {
        return ['black', 'white'].includes(value)
      }
    },
    withLine: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    marketing: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    canMakePayments: false,
    session: null
  }),
  mounted () {
    if (window.ApplePaySession) {
      try {
        this.canMakePayments = window.ApplePaySession.canMakePayments()
      } catch (e) {}
    }
  },
  computed: {
    ...mapState({
      paymentData: state => state.payment.paymentData
    }),
    buttonClasses () {
      return `apple-pay-button-with-text apple-pay-button-${this.color}${this.withLine ? '-with-line' : ''}-with-text`
    }
  },
  methods: {
    ...mapActions([
      'getApplePaySession',
      'sendPaymentUpdateTrx',
      'disableSessionTimer'
    ]),
    onClick () {
      const form = this.$parent.form
      if (!form) {
        return
      }
      form.setAsSubmitted()
      if (form.valid) {
        this.startSession()
      }
    },
    startSession () {
      const centValue = ToolsClass.getAmountAsCurrencyInteger(this.paymentData.transaction.payAmount)
      const stringToHash = centValue + '|' + this.paymentData.transaction.payAmountCurrency
      const request = {
        countryCode: 'PL',
        currencyCode: this.paymentData.transaction.payAmountCurrency,
        total: {
          label: this.paymentData.transaction.orderId,
          amount: this.paymentData.transaction.payAmount.toFixed(2)
        },
        supportedNetworks: ['masterCard', 'visa'],
        merchantCapabilities: ['supports3DS'],
        applicationData: btoa(stringToHash)
      }
      this.session = new window.ApplePaySession(1, request)
      this.session.onvalidatemerchant = this.onValidateMerchant
      this.session.onpaymentauthorized = this.onPaymentAuthorized
      this.session.oncancel = this.onCancel
      this.session.begin()
    },
    onValidateMerchant (event) {
      this.getApplePaySession(event.validationURL)
        .then(response => {
          this.session.completeMerchantValidation(response.body)
        })
        .catch(error => {
          Raven.captureMessage('ApplePay onValidateMerchant error', {
            extra: error
          })
        })
    },
    onPaymentAuthorized (event) {
      if (typeof event.payment === 'undefined') {
        this.session.completePayment({
          status: window.ApplePaySession.STATUS_FAILURE,
          errors: [
            new window.ApplePayError('unknown')
          ]
        })
        return
      }
      this.disableSessionTimer()
      const token = base64.encode(JSON.stringify(event.payment.token.paymentData))
      const data = {
        paymentToken: token,
        marketing: this.marketing
      }
      this.sendPaymentUpdateTrx(data)
        .then(() => {
          this.session.completePayment({ status: window.ApplePaySession.STATUS_SUCCESS })
        })
        .catch(error => {
          this.session.completePayment({
            status: window.ApplePaySession.STATUS_FAILURE,
            errors: [
              new window.ApplePayError('unknown')
            ]
          })
          Raven.captureMessage('ApplePay sendPaymentUpdateTrx error', {
            extra: error
          })
        })
    },
    onCancel (event) {
      this.session.abort()
    }
  }
}
</script>

<style scoped>
@supports (-webkit-appearance: -apple-pay-button) {
  /* Template for logo only button (height independent). */
  .apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
  }
  .apple-pay-button-black {
    -apple-pay-button-style: black;
  }
  .apple-pay-button-black-with-line {
    -apple-pay-button-style: black-outline;
  }
  .apple-pay-button-white {
    -apple-pay-button-style: white;
  }
  .apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
  }
  /* Template for "Buy with" button with height: 32 */
  .apple-pay-button-with-text {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: buy;
  }
  .apple-pay-button-with-text > * {
    display: none;
  }
  .apple-pay-button-black-with-text {
    -apple-pay-button-style: black;
  }
  .apple-pay-button-black-with-line-with-text {
    -apple-pay-button-style: black-outline;
  }
  .apple-pay-button-white-with-text {
    -apple-pay-button-style: white;
  }
  .apple-pay-button-white-with-line-with-text {
    -apple-pay-button-style: white-outline;
  }
  /* Template for additional Apple Pay button types */
  .apple-pay-set-up-button {
    -apple-pay-button-type: set-up;
  }
  .apple-pay-donate-button {
    -apple-pay-button-type: donate;
  }
  .apple-pay-check-out-button {
    -apple-pay-button-type: check-out;
  }
  .apple-pay-book-button {
    -apple-pay-button-type: book;
  }
  .apple-pay-subscribe-button {
    -apple-pay-button-type: subscribe;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  /* Template for logo only button (height independent). */
  .apple-pay-button {
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
  }
  .apple-pay-button-black {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }
  .apple-pay-button-white {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .apple-pay-button-white-with-line {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
    border: .5px solid black;
  }
  /* Template for "Buy with" button with height: 32 */
  .apple-pay-button-with-text {
    --apple-pay-scale: 1; /* (height / 32) */
    display: inline-flex;
    justify-content: center;
    font-size: 1.2rem;
    border-radius: 5px;
    padding: 0;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
  }
  .apple-pay-button-black-with-text {
    background-color: black;
    color: white;
  }
  .apple-pay-button-white-with-text {
    background-color: white;
    color: black;
  }
  .apple-pay-button-white-with-line-with-text {
    background-color: white;
    color: black;
    border: .5px solid black;
  }
  .apple-pay-button-with-text.apple-pay-button-black-with-text > .logo {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }
  .apple-pay-button-with-text.apple-pay-button-white-with-text > .logo {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .apple-pay-button-with-text.apple-pay-button-white-with-line-with-text > .logo {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .apple-pay-button-with-text > .text {
    font-family: -apple-system;
    font-size: calc(1em * var(--apple-pay-scale));
    font-weight: 300;
    align-self: center;
    margin-right: calc(2px * var(--apple-pay-scale));
  }
  .apple-pay-button-with-text > .logo {
    width: calc(35px * var(--scale));
    height: 100%;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 0 50%;
    margin-left: calc(2px * var(--apple-pay-scale));
    border: none;
  }
}
</style>
