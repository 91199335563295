<template>
  <div :ref="formRef" v-if="isFormDataSet" v-html="formData"></div>
</template>

<script>
import { PisTransactionStatusClass } from '@/base/class/pisTransactionStatus.class'
import { Raven } from 'vue-raven'
import { MobileAppClass } from '@/base/class/mobileApp.class'
import { MOBILE_STATUS } from '@/base/const/mobileEvents.const'
import { ToolsClass } from '@/base/class/tools.class'
import { ERROR_CODES_NAMES } from '@/base/const/errorCodes.const'
import { mapMutations } from 'vuex'

const FORM_REF = 'formPisContainer'

export default {
  name: 'PaymentPsd2Data',
  data () {
    return {
      isFormDataSet: false,
      formData: null,
      formRef: FORM_REF
    }
  },
  props: {
    paymentData: {
      type: Object
    }
  },
  watch: {
    isFormDataSet: function (val) {
      if (val) {
        setTimeout(this.submitForm, 1000)
      }
    }
  },
  methods: {
    ...mapMutations([
      'SET_PAYMENT_PAYWALL_VIEW',
      'SET_ERROR_DATA'
    ]),
    submitForm () {
      try {
        const form = this.$refs[FORM_REF].getElementsByTagName('form')
        if (!form.length) {
          throw new Error('no form!')
        }
        form[0].submit()
        MobileAppClass.postMessageSuccess(MOBILE_STATUS().paywayLoaded)
      } catch (error) {
        if (ToolsClass.isDebugEnabled()) {
          console.log('submitForm error', error)
        }
        Raven.captureMessage('PaymentPsd2Data form error', {
          extra: error
        })
        this.goToErrorPage()
      }
    },
    redirectUser (data) {
      this.formData = data
      this.isFormDataSet = true
    },
    processError (error) {
      if (ToolsClass.isDebugEnabled()) {
        console.log('processError', error)
      }
      Raven.captureMessage('PaymentPsd2Data process error', {
        extra: error
      })
      this.goToErrorPage()
    },
    processException (exception) {
      if (ToolsClass.isDebugEnabled()) {
        console.log('processException', exception)
      }
      Raven.captureMessage('PaymentPsd2Data process exception', {
        extra: exception
      })
      this.goToErrorPage()
    },
    goToErrorPage () {
      this.SET_PAYMENT_PAYWALL_VIEW(false)
      this.SET_ERROR_DATA({
        errorCode: ERROR_CODES_NAMES.GENERAL_ERROR,
        errorUrl: null
      })
    }
  },
  mounted () {
    const data = {
      webSocketServerUrl: this.paymentData.wtnSocketUrl,
      checkUrl: this.paymentData.wtnRestUrl,
      statusMaxWaitingTime: this.paymentData.timeout,
      onRedirectUserCallback: this.redirectUser,
      onErrorCallback: this.processError,
      onExceptionCallback: this.processException
    }
    const handler = new PisTransactionStatusClass(data)
    handler.init()
  }
}
</script>
