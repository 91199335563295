<template>
  <div class="not-found">
    <h2 class="normal" v-html="$t('confirmation.notFound.header')"></h2>
    <p v-html="$t('confirmation.notFound.description')"></p><p v-html="$t('confirmation.notFound.description2')"></p>
  </div>
</template>
<script>
export default {
  name: 'ConfirmationNotFound'
}
</script>
