<template>
  <div id="lottie"></div>
</template>

<script>
const animationData = {
  'v': '5.7.4',
  'fr': 24,
  'ip': 0,
  'op': 24,
  'w': 256,
  'h': 256,
  'nm': 'Visa_Mobile_Mark_loading_blue',
  'ddd': 0,
  'assets': [],
  'layers': [{
    'ddd': 0,
    'ind': 1,
    'ty': 4,
    'nm': 'Shape Layer 2',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [128.272, 103.482, 0], 'ix': 2, 'l': 2 },
      'a': { 'a': 0, 'k': [0.092, 7.847, 0], 'ix': 1, 'l': 2 },
      's': { 'a': 0, 'k': [150.278, 150.278, 100], 'ix': 6, 'l': 2 }
    },
    'ao': 0,
    'shapes': [{
      'ty': 'gr',
      'it': [{
        'ind': 0,
        'ty': 'sh',
        'ix': 1,
        'ks': {
          'a': 0,
          'k': {
            'i': [[-6.531, 0], [0, -6.531], [6.531, 0], [0, 6.531]],
            'o': [[6.531, 0], [0, 6.531], [-6.531, 0], [0, -6.531]],
            'v': [[0, -11.825], [11.825, 0], [0, 11.825], [-11.825, 0]],
            'c': true
          },
          'ix': 2
        },
        'nm': 'Path 1',
        'mn': 'ADBE Vector Shape - Group',
        'hd': false
      }, {
        'ty': 'st',
        'c': { 'a': 0, 'k': [0.078431375325, 0.203921571374, 0.796078443527, 1], 'ix': 3 },
        'o': { 'a': 0, 'k': 100, 'ix': 4 },
        'w': { 'a': 0, 'k': 4, 'ix': 5 },
        'lc': 1,
        'lj': 1,
        'ml': 4,
        'bm': 0,
        'nm': 'Stroke 1',
        'mn': 'ADBE Vector Graphic - Stroke',
        'hd': false
      }, {
        'ty': 'tr',
        'p': { 'a': 0, 'k': [0.092, 7.847], 'ix': 2 },
        'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
        'r': { 'a': 0, 'k': 0, 'ix': 6 },
        'o': { 'a': 0, 'k': 100, 'ix': 7 },
        'sk': { 'a': 0, 'k': 0, 'ix': 4 },
        'sa': { 'a': 0, 'k': 0, 'ix': 5 },
        'nm': 'Transform'
      }],
      'nm': 'Ellipse 1',
      'np': 3,
      'cix': 2,
      'bm': 0,
      'ix': 1,
      'mn': 'ADBE Vector Group',
      'hd': false
    }, {
      'ty': 'tm',
      's': {
        'a': 1,
        'k': [{ 'i': { 'x': [0.833], 'y': [0.833] }, 'o': { 'x': [0.167], 'y': [0.167] }, 't': 6, 's': [34] }, {
          't': 19,
          's': [34]
        }],
        'ix': 1
      },
      'e': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 6,
          's': [66]
        }, { 'i': { 'x': [0.833], 'y': [0.833] }, 'o': { 'x': [0.167], 'y': [0.167] }, 't': 13, 's': [92] }, {
          't': 19,
          's': [66]
        }],
        'ix': 2
      },
      'o': {
        'a': 1,
        'k': [{ 'i': { 'x': [0.134], 'y': [1] }, 'o': { 'x': [0.333], 'y': [0] }, 't': 0, 's': [0] }, {
          'i': {
            'x': [0.667],
            'y': [1]
          },
          'o': { 'x': [0.834], 'y': [0] },
          't': 5,
          's': [26]
        }, { 'i': { 'x': [0.833], 'y': [1] }, 'o': { 'x': [0.167], 'y': [0] }, 't': 13, 's': [-297] }, {
          'i': {
            'x': [0.833],
            'y': [1]
          },
          'o': { 'x': [0.167], 'y': [0] },
          't': 15,
          's': [-339]
        }, { 't': 19, 's': [-360] }],
        'ix': 3
      },
      'm': 1,
      'ix': 2,
      'nm': 'Trim Paths 1',
      'mn': 'ADBE Vector Filter - Trim',
      'hd': false
    }],
    'ip': 0,
    'op': 25,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 2,
    'ty': 4,
    'nm': 'Layer 3 Outlines 2',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [128.161, 61.111, 0], 'ix': 2, 'l': 2 },
      'a': { 'a': 0, 'k': [3.25, 3.25, 0], 'ix': 1, 'l': 2 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [1, 1, 1] },
          'o': { 'x': [0.333, 0.333, 0.333], 'y': [0, 0, 0] },
          't': 1,
          's': [150.278, 150.278, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [1, 1, 1] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0, 0, 0] },
          't': 3,
          's': [150.278, 0, 100]
        }, { 't': 7, 's': [150.278, 156.289, 100] }],
        'ix': 6,
        'l': 2
      }
    },
    'ao': 0,
    'shapes': [{
      'ty': 'gr',
      'it': [{
        'ind': 0,
        'ty': 'sh',
        'ix': 1,
        'ks': {
          'a': 0,
          'k': {
            'i': [[-1.657, 0], [0, 1.657], [1.657, 0], [0, -1.657]],
            'o': [[1.657, 0], [0, -1.657], [-1.657, 0], [0, 1.657]],
            'v': [[0, 3], [3, 0], [0, -3], [-3, 0]],
            'c': true
          },
          'ix': 2
        },
        'nm': 'Path 1',
        'mn': 'ADBE Vector Shape - Group',
        'hd': false
      }, {
        'ty': 'fl',
        'c': { 'a': 0, 'k': [0.078431375325, 0.203921571374, 0.796078443527, 1], 'ix': 4 },
        'o': { 'a': 0, 'k': 100, 'ix': 5 },
        'r': 1,
        'bm': 0,
        'nm': 'Fill 1',
        'mn': 'ADBE Vector Graphic - Fill',
        'hd': false
      }, {
        'ty': 'tr',
        'p': { 'a': 0, 'k': [3.25, 3.25], 'ix': 2 },
        'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
        'r': { 'a': 0, 'k': 0, 'ix': 6 },
        'o': { 'a': 0, 'k': 100, 'ix': 7 },
        'sk': { 'a': 0, 'k': 0, 'ix': 4 },
        'sa': { 'a': 0, 'k': 0, 'ix': 5 },
        'nm': 'Transform'
      }],
      'nm': 'Group 1',
      'np': 2,
      'cix': 2,
      'bm': 0,
      'ix': 1,
      'mn': 'ADBE Vector Group',
      'hd': false
    }],
    'ip': 0,
    'op': 25,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 3,
    'ty': 4,
    'nm': 'Layer 2 Outlines',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [127.774, 91.078, 0], 'ix': 2, 'l': 2 },
      'a': { 'a': 0, 'k': [28, 40, 0], 'ix': 1, 'l': 2 },
      's': { 'a': 0, 'k': [150.491, 150.491, 100], 'ix': 6, 'l': 2 }
    },
    'ao': 0,
    'shapes': [{
      'ty': 'gr',
      'it': [{
        'ind': 0,
        'ty': 'sh',
        'ix': 1,
        'ks': {
          'a': 0,
          'k': {
            'i': [[3, 0], [0, 0], [0, 3], [0, 0], [-3.2, 0], [0, 0], [0, -3.2], [0, 0]],
            'o': [[0, 0], [-3.2, 0], [0, 0], [-0.2, -3.2], [0, 0], [3, 0], [0, 0], [0, 3]],
            'v': [[12.4, 30], [-12.2, 30], [-17.8, 24.4], [-17.8, -24.2], [-12.2, -30], [12.4, -30], [18, -24.2], [18, 24.4]],
            'c': true
          },
          'ix': 2
        },
        'nm': 'Path 1',
        'mn': 'ADBE Vector Shape - Group',
        'hd': false
      }, {
        'ty': 'st',
        'c': { 'a': 0, 'k': [0.078431375325, 0.203921571374, 0.796078443527, 1], 'ix': 3 },
        'o': { 'a': 0, 'k': 100, 'ix': 4 },
        'w': { 'a': 0, 'k': 4, 'ix': 5 },
        'lc': 1,
        'lj': 1,
        'ml': 10,
        'bm': 0,
        'nm': 'Stroke 1',
        'mn': 'ADBE Vector Graphic - Stroke',
        'hd': false
      }, {
        'ty': 'tr',
        'p': { 'a': 0, 'k': [28, 40], 'ix': 2 },
        'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
        'r': { 'a': 0, 'k': 0, 'ix': 6 },
        'o': { 'a': 0, 'k': 100, 'ix': 7 },
        'sk': { 'a': 0, 'k': 0, 'ix': 4 },
        'sa': { 'a': 0, 'k': 0, 'ix': 5 },
        'nm': 'Transform'
      }],
      'nm': 'Group 1',
      'np': 2,
      'cix': 2,
      'bm': 0,
      'ix': 1,
      'mn': 'ADBE Vector Group',
      'hd': false
    }],
    'ip': 0,
    'op': 25,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 4,
    'ty': 4,
    'nm': 'Layer 1/Visa_Brandmark_Blue_RGB Outlines',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [122.5, 187.75, 0], 'ix': 2, 'l': 2 },
      'a': { 'a': 0, 'k': [78.5, 25.5, 0], 'ix': 1, 'l': 2 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2 }
    },
    'ao': 0,
    'shapes': [{
      'ty': 'gr',
      'it': [{
        'ind': 0,
        'ty': 'sh',
        'ix': 1,
        'ks': {
          'a': 0,
          'k': {
            'i': [[0, 0], [0, 0], [0, 0], [0, 0]],
            'o': [[0, 0], [0, 0], [0, 0], [0, 0]],
            'v': [[-0.758, -24.248], [-11.159, 24.376], [-23.736, 24.376], [-13.332, -24.248]],
            'c': true
          },
          'ix': 2
        },
        'nm': 'Path 1',
        'mn': 'ADBE Vector Shape - Group',
        'hd': false
      }, {
        'ind': 1,
        'ty': 'sh',
        'ix': 2,
        'ks': {
          'a': 0,
          'k': {
            'i': [[0, 0], [0, 0], [0, 0]],
            'o': [[0, 0], [0, 0], [0, 0]],
            'v': [[52.163, 7.147], [58.785, -11.109], [62.594, 7.147]],
            'c': true
          },
          'ix': 2
        },
        'nm': 'Path 2',
        'mn': 'ADBE Vector Shape - Group',
        'hd': false
      }, {
        'ind': 2,
        'ty': 'sh',
        'ix': 3,
        'ks': {
          'a': 0,
          'k': {
            'i': [[0, 0], [0, 0], [0, 0], [0, 0], [0.903, -2.162], [0, 0], [0, 0], [0, 0], [0, 0]],
            'o': [[0, 0], [0, 0], [0, 0], [-2.415, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            'v': [[66.199, 24.376], [77.833, 24.376], [67.678, -24.248], [56.939, -24.248], [51.587, -20.681], [32.712, 24.376], [45.919, 24.376], [48.542, 17.113], [64.68, 17.113]],
            'c': true
          },
          'ix': 2
        },
        'nm': 'Path 3',
        'mn': 'ADBE Vector Shape - Group',
        'hd': false
      }, {
        'ind': 3,
        'ty': 'sh',
        'ix': 4,
        'ks': {
          'a': 0,
          'k': {
            'i': [[-0.041, 10.108], [-0.122, 5.732], [-3.636, 0.474], [-5.632, -2.592], [0, 0], [4.846, 0], [0.074, -9.464], [-4.765, -2.326], [0.018, -2.131], [3.622, -0.056], [2.925, 1.359], [0, 0], [-5.625, -0.059]],
            'o': [[0.053, -12.833], [0.038, -1.745], [1.802, -0.236], [0, 0], [-3.026, -1.099], [-12.432, 0], [-0.08, 7], [4.902, 2.383], [-0.035, 3.262], [-6.322, 0.097], [0, 0], [2.939, 1.348], [13.215, 0]],
            'v': [[33.368, 8.501], [15.745, -10.772], [21.081, -14.846], [33.48, -12.67], [35.689, -22.98], [23.923, -25.136], [2.667, -9.063], [13.678, 4.169], [20.208, 10.213], [12.676, 14.971], [-0.241, 11.901], [-2.52, 22.553], [11.469, 25.137]],
            'c': true
          },
          'ix': 2
        },
        'nm': 'Path 4',
        'mn': 'ADBE Vector Shape - Group',
        'hd': false
      }, {
        'ind': 4,
        'ty': 'sh',
        'ix': 5,
        'ks': {
          'a': 0,
          'k': {
            'i': [[0, 0], [0, 0], [0, 0], [0, 0], [1.852, 1.006], [4.392, 0.956], [0, 0], [0, 0], [-0.62, -3.142], [0, 0], [0, 0]],
            'o': [[0, 0], [0, 0], [0, 0], [-0.609, -2.391], [-3.023, -1.641], [0, 0], [0, 0], [2.728, 0], [0, 0], [0, 0], [0, 0]],
            'v': [[-18.73, -24.248], [-39.109, 24.376], [-52.406, 24.376], [-62.434, -14.428], [-65.424, -18.7], [-77.833, -22.835], [-77.535, -24.248], [-56.131, -24.248], [-50.331, -19.29], [-45.035, 8.847], [-31.943, -24.248]],
            'c': true
          },
          'ix': 2
        },
        'nm': 'Path 5',
        'mn': 'ADBE Vector Shape - Group',
        'hd': false
      }, { 'ty': 'mm', 'mm': 1, 'nm': 'Merge Paths 1', 'mn': 'ADBE Vector Filter - Merge', 'hd': false }, {
        'ty': 'fl',
        'c': { 'a': 0, 'k': [0.078431372549, 0.203921583587, 0.796078491211, 1], 'ix': 4 },
        'o': { 'a': 0, 'k': 100, 'ix': 5 },
        'r': 1,
        'bm': 0,
        'nm': 'Fill 1',
        'mn': 'ADBE Vector Graphic - Fill',
        'hd': false
      }, {
        'ty': 'tr',
        'p': { 'a': 0, 'k': [78.082, 25.386], 'ix': 2 },
        'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
        'r': { 'a': 0, 'k': 0, 'ix': 6 },
        'o': { 'a': 0, 'k': 100, 'ix': 7 },
        'sk': { 'a': 0, 'k': 0, 'ix': 4 },
        'sa': { 'a': 0, 'k': 0, 'ix': 5 },
        'nm': 'Transform'
      }],
      'nm': 'Group 1',
      'np': 9,
      'cix': 2,
      'bm': 0,
      'ix': 1,
      'mn': 'ADBE Vector Group',
      'hd': false
    }],
    'ip': 0,
    'op': 25,
    'st': 0,
    'bm': 0
  }],
  'markers': []
}
export default {
  name: 'VisaMobileStatusPending',
  mounted () {
    const params = {
      container: document.getElementById('lottie'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData
    }
    // eslint-disable-next-line no-undef
    lottie.loadAnimation(params)
  }
}
</script>

<style lang="scss" scoped>
  #lottie {
  background-color: #ffffff;
  width: 150px;
  height: 150px;
  display: block;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  text-align: center;
  opacity: 1;
}
</style>
