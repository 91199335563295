<template>
  <div :ref="formRef" v-html="paymentData"></div>
</template>

<script>
import { Raven } from 'vue-raven'
import { MobileAppClass } from '@/base/class/mobileApp.class'
import { MOBILE_STATUS } from '@/base/const/mobileEvents.const'

const FORM_REF = 'formContainer'

export default {
  name: 'PaymentData',
  data () {
    return {
      formRef: FORM_REF
    }
  },
  props: {
    paymentData: {
      type: String
    }
  },
  mounted: function () {
    try {
      const form = this.$refs[FORM_REF].getElementsByTagName('form')
      if (!form.length) {
        throw new Error('no form!')
      }
      form[0].submit()
      MobileAppClass.postMessageSuccess(MOBILE_STATUS().paywayLoaded)
    } catch (error) {
      Raven.captureMessage('PaymentData form error', {
        extra: error
      })
    }
  }
}
</script>
