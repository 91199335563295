<template>
  <div aria-haspopup="dialog"
       aria-modal="true"
       aria-live="assertive"
       class="modal-container"
       :class="modal.options.size && 'size-' + modal.options.size" v-if="showModal">
    <div class="modal-content">
      <div class="modal-close" v-if="modal.options.showClose">
        <button ref="closeButton"
                type="button"
                :aria-label="$t('modal.closeButton')"
                @click="hideModal"
                @keydown.esc.prevent="hideModal"
                @keydown.tab.prevent="handleTab">
        </button>
      </div>
      <div class="modal-text">
        <component v-if="modalName" v-bind:is="modalName" :aria-labelledby="modalName"/>
        <div v-else :aria-describedby="modal.text">
          {{ modal.text }}
        </div>
      </div>
      <div class="modal-button" v-if="!modal.options.hideButton">
        <button ref="modalButton"
                class="btn"
                @click="hideModal"
                @keydown.esc.prevent="hideModal"
                @keydown.tab.prevent="handleTab">
          {{ $t('modal.confirmButton') }}
        </button>
      </div>
    </div>
    <div id="overlayAll" v-if="showModal"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'Modals',
  components: {
    'VisaHelpModal1': () => import('@/base/components/Payment/VisaMobileProcess/Modals/VisaHelpModal1'),
    'VisaHelpModal2': () => import('@/base/components/Payment/VisaMobileProcess/Modals/VisaHelpModal2'),
    'AliorModal': () => import('@/base/components/Payment/Paywall/Modals/AliorModal'),
    'PaykaModal': () => import('@/base/components/Payment/Paywall/Modals/PaykaModal'),
    'Click2PayModal': () => import('@/base/components/Payment/Paywall/Modals/Click2PayModal'),
    'Click2PayPrcessErrorModal': () => import('@/base/components/Payment/Paywall/Modals/Click2PayPrcessErrorModal'),
    'BlikPayLaterModal': () => import('@/base/components/Payment/Paywall/Modals/BlikPayLaterModal')
  },
  data () {
    return {
      showModal: false
    }
  },
  watch: {
    modal: function (val) {
      this.showModal = typeof val === 'object' && val !== null
    }
  },
  computed: {
    ...mapGetters({
      modal: 'getModal'
    }),
    ...mapState({
      returnFocusRefId: state => state.messages.focusReturnElementId
    }),
    modalName () {
      return this.modal.component
    }
  },
  methods: {
    ...mapActions([
      'removeMessage'
    ]),
    hideModal () {
      this.removeMessage(this.modal.id)
      if (document.getElementById(this.returnFocusRefId)) {
        document.getElementById(this.returnFocusRefId).focus()
      }
      this.setInert(false)
    },
    setInert (hideAll) {
      const element = document.querySelector('main')
      if (hideAll) {
        element.setAttribute('aria-disabled', hideAll)
      } else {
        element.removeAttribute('aria-disabled')
      }
    },
    handleTab () {
      if (!this.modal.options.hideButton) {
        this.$refs.modalButton.focus()
        return
      }
      if (this.modal.options.showClose) {
        this.$refs.closeButton.focus()
      }
    }
  },
  updated () {
    if (this.showModal) {
      if (!this.modal.options.hideButton) {
        this.$refs.modalButton.focus()
      } else {
        if (this.modal.options.showClose) {
          this.$refs.closeButton.focus()
        }
      }
      this.setInert(true)
    }
  }
}
</script>
