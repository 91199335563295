import { MATOMO_EVENT_TYPE } from '@/base/const/matomo.const'
import { ToolsClass } from '@/base/class/tools.class'

export class MatomoClass {
  /**
   * Wysłanie eventu do Matomo
   * @param {array} data
   */
  static sendEvent (data) {
    if (!window._paq) {
      window._paq = []
    }
    if (ToolsClass.isDebugEnabled()) {
      console.log('MatomoClass.sendEvent', JSON.stringify(data))
    }
    window._paq.push(data)
  }

  /**
   * Wysłanie eventu typu customDimension
   * @param {number} index
   * @param {any} value
   */
  static sendCustomDimension (index, value) {
    if (!window._paq) {
      window._paq = []
    }
    const data = [MATOMO_EVENT_TYPE.setCustomDimension, index, value]
    if (ToolsClass.isDebugEnabled()) {
      console.log('MatomoClass.sendCustomDimension', JSON.stringify(data))
    }
    window._paq.push(data)
  }

  /**
   * Wysłanie eventu trackPageView
   */
  static sendTrackPageView () {
    if (!window._paq) {
      window._paq = []
    }
    const data = [MATOMO_EVENT_TYPE.trackPageView]
    if (ToolsClass.isDebugEnabled()) {
      console.log('MatomoClass.sendCustomDimension', JSON.stringify(data))
    }
    window._paq.push(data)
  }

  /**
   * Funckja sprawdzająca załadowanie skryptu Matomo
   * @returns {boolean}
   */
  static isMatomoLoaded () {
    return window.Matomo && window.Matomo.initialized === true
  }
}
