import { render, staticRenderFns } from "./PaywayNotAvailable.vue?vue&type=template&id=6eb7b130&scoped=true"
import script from "./PaywayNotAvailable.vue?vue&type=script&lang=js"
export * from "./PaywayNotAvailable.vue?vue&type=script&lang=js"
import style0 from "./PaywayNotAvailable.vue?vue&type=style&index=0&id=6eb7b130&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eb7b130",
  null
  
)

export default component.exports