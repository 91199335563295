<template>
  <div class="container">
    <div class="paywall">
      <div class="columns">
        <section class="sidebar-column">
          <order-details-side :transaction="transaction"
                              :visibility="visibilityOptions"
                              :show-button="true" />
        </section>
        <section class="main-column">
          <div class="content">
            <div class="visamobile-wrapper">
              <div class="visamobile-processing-loader-wrapper">
                <visa-mobile-status-pending v-if="isTransactionStatusPending" />
                <visa-mobile-status-success v-if="isTransactionStatusSuccess" />
                <visa-mobile-status-error v-if="isTransactionStatusError" />
              </div>
              <div class="visamobile-content">
                <div v-if="isTransactionStatusPending">
                  <p class="vm-status" v-html="$t('paywall.form.visaMobile.status.pending.statusText')"></p>
                  <p class="vm-header" v-html="$t('paywall.form.visaMobile.status.pending.header')"></p>
                  <p class="vm-description" v-if="!canceled && !showSecondDescription" v-html="$t('paywall.form.visaMobile.status.pending.descriptionText1')"></p>
                  <p class="vm-description" v-if="!canceled && showSecondDescription" v-html="$t('paywall.form.visaMobile.status.pending.descriptionText2')"></p>
                  <p class="vm-description" v-if="canceled" v-html="$t('paywall.form.visaMobile.status.pending.descriptionText3')"></p>

                  <div class="vm-button" v-if="!canceled && paymentData">
                    <button type="button" @click="cancelTransaction" v-text="$t('paywall.form.visaMobile.status.pending.cancelButton')"></button>
                  </div>
                  <div class="vm-help" @click="showModal2" id="help">
                    <p v-html="$t('paywall.form.visaMobile.help')"></p>
                  </div>
                  <div class="vm-apps">
                    <div class="apps-text" v-html="$t('paywall.form.visaMobile.apps')"></div>
                    <div class="vm-help" @click="showModal1">
                      <p v-html="$t('paywall.form.visaMobile.info')"></p>
                    </div>
                    <iframe :src="getAppsLogosIframeSrc()" title="Payment Logos"></iframe>
                  </div>
                </div>
                <div v-if="isTransactionStatusSuccess">
                  <p class="vm-description" v-html="$t('paywall.form.visaMobile.status.success.descriptionText')"></p>
                  <p class="vm-status" v-html="$t('paywall.form.visaMobile.status.success.statusText')"></p>
                </div>
                <div v-if="isTransactionStatusError">
                  <p class="vm-description" v-html="$t('paywall.form.visaMobile.status.error.descriptionText')"></p>
                  <p class="vm-status" v-html="$t('paywall.form.visaMobile.status.error.statusText')"></p>
                </div>
                <div class="vm-secure">
                  <img src="/web/img/visa-mobile/lock.svg" alt="Pomoc" />
                  <p v-html="$t('paywall.form.visaMobile.secure')"></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import VisaMobileStatusPending from '@/base/components/Payment/VisaMobileProcess/Status/Pending'
import VisaMobileStatusSuccess from '@/base/components/Payment/VisaMobileProcess/Status/Success'
import VisaMobileStatusError from '@/base/components/Payment/VisaMobileProcess/Status/Error'
import OrderDetailsSide from '@/base/components/OrderDetails/Side'
import { mapMutations, mapActions, mapState } from 'vuex'
import { CONFIRMATION_STATE } from '@/base/const/confirmation.const'
const transactionStatusTimeoutDelay = 2000
const showSecondDescriptionDelay = 15000

export default {
  name: 'VisaMobileData',
  components: {
    VisaMobileStatusError,
    VisaMobileStatusSuccess,
    VisaMobileStatusPending,
    OrderDetailsSide
  },
  data () {
    return {
      counter: 200,
      showSecondDescription: false,
      canceled: false
    }
  },
  watch: {
    paymentData: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getTransactionStatus()
        setTimeout(() => {
          this.showSecondDescription = true
        }, showSecondDescriptionDelay)
      }
    }
  },
  computed: {
    ...mapState({
      transactionStatus: state => state.confirmation.data ? state.confirmation.data.state : CONFIRMATION_STATE.pending,
      paymentData: state => state.payment.paymentData?.visaMobileData,
      transaction: state => state.payment.paymentData?.transaction?.orderId ? state.payment.paymentData.transaction : state.confirmation.data?.transaction,
      visibilityOptions: state => state.payment.paymentData?.visibilityOptions
    }),
    isTransactionStatusSuccess: function () {
      return this.transactionStatus === CONFIRMATION_STATE.positive
    },
    isTransactionStatusPending: function () {
      return this.transactionStatus === CONFIRMATION_STATE.pending
    },
    isTransactionStatusError: function () {
      const errorStateList = [
        CONFIRMATION_STATE.positive,
        CONFIRMATION_STATE.pending
      ]
      return !errorStateList.includes(this.transactionStatus)
    }
  },
  methods: {
    ...mapMutations([
      'SET_CONFIRMATION_DATA'
    ]),
    ...mapActions([
      'getConfirmationTrxForVisaMobile',
      'cancelVisaMobileTransaction',
      'addModalInfo',
      'disableSessionTimer'
    ]),
    getAppsLogosIframeSrc () {
      return process.env.VUE_APP_WIDGET_NEW_VISA_MOBILE_URL + '/apps-logos'
    },
    showModal1 () {
      const message = {
        focusReturnElementId: 'help',
        component: 'VisaHelpModal1',
        options: {
          size: 'medium'
        }
      }
      this.addModalInfo(message)
    },
    showModal2 () {
      const message = {
        focusReturnElementId: 'help',
        component: 'VisaHelpModal2',
        options: {
          size: 'medium'
        }
      }
      this.addModalInfo(message)
    },
    cancelTransaction () {
      this.canceled = true
      this.cancelVisaMobileTransaction()
        .catch(() => {
          this.canceled = false
        })
    },
    getTransactionStatus () {
      this.counter--
      const params = {
        hash: this.paymentData.hash,
        orderId: this.paymentData.orderId,
        serviceId: this.paymentData.serviceId
      }
      this.getConfirmationTrxForVisaMobile(params)
        .then(response => {
          if (this.isTransactionStatusPending) {
            if (this.counter === 0) {
              this.SET_CONFIRMATION_DATA({
                state: CONFIRMATION_STATE.outdated
              })
              return
            }
            setTimeout(() => {
              this.getTransactionStatus()
            }, transactionStatusTimeoutDelay)
          } else {
            this.disableSessionTimer()
          }
        })
        .catch(() => {
          setTimeout(() => {
            this.getTransactionStatus()
          }, transactionStatusTimeoutDelay)
        })
    }
  },
  mounted () {
    if (this.paymentData?.hash && this.paymentData?.orderId && this.paymentData?.serviceId) {
      this.getTransactionStatus()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/base/assets/scss/components/_visamobile-loader.scss";
</style>
