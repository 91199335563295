<template>
  <div>
    <click-to-pay-info />
    <div class="form-row input-text-field">
      <h3>{{ $t('paywall.form.click2pay.cardSelected.header') }}</h3>
      <div class="c2p-card selected">
        <div>
          <img :src="getCardImg(selectedCard)" height="40" alt="">
          <span>**** {{ getLast4Digits(selectedCard) }}</span>
        </div>
        <span>{{ $t('paywall.form.click2pay.cardSelected.date') }} {{ getExpirationDate(selectedCard) }}</span>
        <button type="button" @click="goToClick2PayCardSelect" class="btn btn-plain">{{ $t('paywall.form.click2pay.cardSelected.buttonChange') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import ClickToPayInfo from '@/base/components/Payment/Paywall/ClickToPay/Info.vue'
import { mapGetters, mapMutations } from 'vuex'
import { CLICK2PAY_VIEW } from '@/base/const/click2pay.const'
import click2payMixin from '@/base/mixins/click2pay.mixin'

export default {
  name: 'ClickToPayCardSelected',
  mixins: [click2payMixin],
  components: { ClickToPayInfo },
  computed: {
    ...mapGetters({
      cards: 'getClick2PayCards'
    })
  },
  methods: {
    ...mapMutations([
      'SET_CLICK2PAY_SELECTED_CARD',
      'SET_CLICK2PAY_VIEW'
    ]),
    goToClick2PayCardSelect () {
      this.SET_CLICK2PAY_VIEW(CLICK2PAY_VIEW.cardSelect)
    }
  },
  mounted () {
    if (this.cards.length && !this.selectedCard) {
      this.SET_CLICK2PAY_SELECTED_CARD(this.cards[0])
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/base/assets/scss/components/PaywallForm.scss";
h3 {
  font-family: "Lato Bold", Helvetica, Arial, sans-serif;
  font-weight: 400 !important;
  padding-bottom: 10px;
}
.c2p-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  font-size: 16px;

  &.selected {
    border: 1px solid var(--color-primary);
  }

  & > div {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 8px;
  }

  button {
    font-size: 12px;
    &.btn.btn-plain {
      text-transform: unset;
    }
  }
}
</style>
