<template>
  <div class="pending">
    <h2 class="normal" v-html="$t('confirmation.pending.header')"></h2>
    <p v-html="$t('confirmation.pending.description')"></p>
  </div>
</template>
<script>
export default {
  name: 'ConfirmationPending'
}
</script>
