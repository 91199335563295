import i18n from '@/base/i18n/index'

export const ERROR_CODES_NAMES = {
  NOT_FOUND_ERROR: 'NOT_FOUND_ERROR',
  GENERAL_ERROR: 'GENERAL_ERROR',
  ERR_FIELD_NOT_FOUND: 'ERR_FIELD_NOT_FOUND',
  ERR_BAD_CLIENT_SOURCE: 'ERR_BAD_CLIENT_SOURCE',
  NR_PARAMETERS_ERROR: 'NR_PARAMETERS_ERROR',
  TRANSACTION_OUTDATED: 'TRANSACTION_OUTDATED',
  LINK_VALIDITY_TIME_OUTDATED: 'LINK_VALIDITY_TIME_OUTDATED',
  TRANSACTION_VALIDITY_TIME_OUTDATED: 'TRANSACTION_VALIDITY_TIME_OUTDATED',
  MULTIPLY_TRANSACTION: 'MULTIPLY_TRANSACTION',
  TRANSACTION_CANCELED: 'TRANSACTION_CANCELED',
  MULTIPLY_PAID_TRANSACTION: 'MULTIPLY_PAID_TRANSACTION',
  TRANSACTION_REJECT: 'TRANSACTION_REJECT',
  BANK_DISABLED: 'BANK_DISABLED',
  BANK_TEMPORARY_MAINTENANCE: 'BANK_TEMPORARY_MAINTENANCE',
  INSUFFICIENT_START_AMOUNT: 'INSUFFICIENT_START_AMOUNT',
  START_AMOUNT_OUT_OF_RANGE: 'START_AMOUNT_OUT_OF_RANGE',
  NON_ACCOUNTED_LIMIT_EXCEEDED: 'NON_ACCOUNTED_LIMIT_EXCEEDED',
  SESSION_TIMEOUT: 'SESSION_TIMEOUT',
  PAYWAY_NOT_AVAILABLE: 'PAYWAY_NOT_AVAILABLE',
  APPLE_PAY_NOT_AVAILABLE: 'APPLE_PAY_NOT_AVAILABLE',
  GOOGLE_PAY_NOT_AVAILABLE: 'GOOGLE_PAY_NOT_AVAILABLE'
}

export const ERROR_TEMPLATES = {
  generalError: 'GENERAL_ERROR',
  paywayNotAvailable: 'PAYWAY_NOT_AVAILABLE'
}

export const ERROR_CODES = () => {
  return {
    [ERROR_CODES_NAMES.SESSION_TIMEOUT]: {
      title: i18n.t('error.sessionTimeout.title'),
      message: [i18n.t('error.sessionTimeout.message')],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.NOT_FOUND_ERROR]: {
      title: i18n.t('error.notFoundError.title'),
      message: [i18n.t('error.notFoundError.message')],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.GENERAL_ERROR]: {
      title: i18n.t('error.generalError.title'),
      message: [i18n.t('error.generalError.message')],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.ERR_FIELD_NOT_FOUND]: {
      title: i18n.t('error.errFieldNotFound.title'),
      message: [],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.ERR_BAD_CLIENT_SOURCE]: {
      title: i18n.t('error.errBadClientSource.title'),
      message: [],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.NR_PARAMETERS_ERROR]: {
      title: i18n.t('error.nrParametersError.title'),
      message: [i18n.t('error.nrParametersError.message')],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.TRANSACTION_OUTDATED]: {
      title: i18n.t('error.transactionOutdated.title'),
      message: [i18n.t('error.transactionOutdated.message')],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.LINK_VALIDITY_TIME_OUTDATED]: {
      title: i18n.t('error.linkValidityTimeOutdated.title'),
      message: [i18n.t('error.linkValidityTimeOutdated.message')],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.TRANSACTION_VALIDITY_TIME_OUTDATED]: {
      title: i18n.t('error.transactionValidityTimeOutdated.title'),
      message: [i18n.t('error.transactionValidityTimeOutdated.message')],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.MULTIPLY_TRANSACTION]: {
      title: i18n.t('error.multiplyTransaction.title'),
      message: [i18n.t('error.multiplyTransaction.message')],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.TRANSACTION_CANCELED]: {
      title: i18n.t('error.transactionCanceled.title'),
      message: [
        i18n.t('error.transactionCanceled.message1'),
        i18n.t('error.transactionCanceled.message2')
      ],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.MULTIPLY_PAID_TRANSACTION]: {
      title: i18n.t('error.multiplyPaidTransaction.title'),
      message: [i18n.t('error.multiplyPaidTransaction.message')],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.TRANSACTION_REJECT]: {
      title: i18n.t('error.transactionReject.title'),
      message: [
        i18n.t('error.transactionReject.message1'),
        i18n.t('error.transactionReject.message2')
      ],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.BANK_DISABLED]: {
      title: i18n.t('error.bankDisabled.title'),
      message: [i18n.t('error.bankDisabled.message')],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.BANK_TEMPORARY_MAINTENANCE]: {
      title: i18n.t('error.bankTemporaryMaintenance.title'),
      message: [i18n.t('error.bankTemporaryMaintenance.message')],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.INSUFFICIENT_START_AMOUNT]: {
      title: i18n.t('error.insufficientStartAmount.title'),
      message: [i18n.t('error.insufficientStartAmount.message')],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.START_AMOUNT_OUT_OF_RANGE]: {
      title: i18n.t('error.startAmountOutOfRange.title'),
      message: [i18n.t('error.startAmountOutOfRange.message')],
      template: ERROR_TEMPLATES.generalError
    },
    [ERROR_CODES_NAMES.NON_ACCOUNTED_LIMIT_EXCEEDED]: {
      title: i18n.t('error.nonAccountedLimitExceeded.title'),
      message: [i18n.t('error.nonAccountedLimitExceeded.message')],
      template: ERROR_TEMPLATES.generalError
    }
  }
}
