import { mapState } from 'vuex'
import { PAYMENT_CLIENT_SOURCE } from '@/base/const/payment.const'
import { getInitialLoader } from '@/base/utils/load'

export default {
  computed: {
    ...mapState({
      paymentPgData: state => state.payment.paymentData.pgData,
      paymentPbcData: state => state.payment.paymentData.pbcData,
      paymentPblData: state => state.payment.paymentData.pblData,
      paymentPbiData: state => state.payment.paymentData.pbiData,
      paymentWalletData: state => state.payment.paymentData.walletData,
      paymentPsd2Data: state => state.payment.paymentData.psd2Data,
      isPaymentPgDataSet: state => state.payment.paymentData && state.payment.paymentData.clientSource === PAYMENT_CLIENT_SOURCE.pgData,
      isPaymentPbcDataSet: state => state.payment.paymentData && state.payment.paymentData.clientSource === PAYMENT_CLIENT_SOURCE.pbcData,
      isPaymentPblDataSet: state => state.payment.paymentData && state.payment.paymentData.clientSource === PAYMENT_CLIENT_SOURCE.pblData,
      isPaymentPbiDataSet: state => state.payment.paymentData && state.payment.paymentData.clientSource === PAYMENT_CLIENT_SOURCE.pbiData,
      isPaymentWalletDataSet: state => state.payment.paymentData && state.payment.paymentData.clientSource === PAYMENT_CLIENT_SOURCE.walletData,
      isPaymentPsd2DataSet: state => state.payment.paymentData && state.payment.paymentData.psd2Data && state.payment.paymentData.clientSource === PAYMENT_CLIENT_SOURCE.psd2Data
    }),
    isPaymentDataSet: function () {
      return this.isPaymentPgDataSet ||
        this.isPaymentPbcDataSet ||
        this.isPaymentPblDataSet ||
        this.isPaymentPbiDataSet ||
        this.isPaymentWalletDataSet ||
        this.isPaymentPsd2DataSet ||
        this.isPaymentVisaMobileDataSet
    },
    paymentData: function () {
      if (this.isPaymentPgDataSet) {
        return this.paymentPgData
      }
      if (this.isPaymentPbcDataSet) {
        return this.paymentPbcData
      }
      if (this.isPaymentPblDataSet) {
        return this.paymentPblData
      }
      if (this.isPaymentPbiDataSet) {
        return this.paymentPbiData
      }
      if (this.isPaymentWalletDataSet) {
        return this.paymentWalletData
      }
      if (this.isPaymentPsd2DataSet) {
        return this.paymentPsd2Data
      }
      if (this.isPaymentVisaMobileDataSet) {
        return this.paymentVisaMobileData
      }
      return ''
    },
    loaderComponent: function () {
      return this.load
    }
  },
  methods: {
    load () {
      return getInitialLoader()
    }
  }
}
