<template>
  <transition-group name="fade" mode="out-in" id="slides" tag="div">
    <div class="slide-image" :style="setBackgroundImage" :key="image.id"></div>
  </transition-group>
</template>

<script>
export default {
  name: 'LogoSlides',
  props: {
    image: {
      type: Object,
      required: true
    }
  },
  computed: {
    setBackgroundImage () {
      return { backgroundImage: 'url(' + this.image.url + ')' }
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-image {
  width: 65px;
  height: 30px;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
}

.fade-move {
  transition: all .5s;
}

.fade-enter-active {
  animation-name: fadeEnter;
  animation-duration: .5s;
  animation-iteration-count: 1;
}

.fade-leave-active {
  animation-name: fadeLeave;
  animation-duration: .5s;
  animation-iteration-count: 1;
  position: absolute;
}

@keyframes fadeEnter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeLeave {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
