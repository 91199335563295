<template>
  <p>
    <button type="button"
            class="btn button-back-to-shop"
            :title="$t('error.linkBackUrlSet')"
            @click="goBackToShop">
      <span v-if="isBackUrlSet">{{ $t('error.linkBackUrlSet') }}</span>
      <span v-if="!isBackUrlSet">{{ $t('error.linkBackUrlNotSet') }}</span>
    </button>
  </p>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsClass } from '@/base/class/tools.class'
import { MobileAppClass } from '@/base/class/mobileApp.class'

const BM_HELP_URL = 'https://bluemedia.pl/paybm_back'

export default {
  name: 'ErrorBackButton',
  computed: {
    ...mapState({
      isErrorDataSet: state => !!state.error.errorData,
      errorData: state => state.error.errorData
    }),
    isBackUrlSet: function () {
      return !!(this.isErrorDataSet && this.errorData.errorUrl)
    }
  },
  methods: {
    goBackToShop () {
      const url = this.isBackUrlSet ? this.errorData.errorUrl : BM_HELP_URL
      if (ToolsClass.isMobileAppConnection()) {
        MobileAppClass.callMobileTransactionFinish()
        return
      }
      ToolsClass.goToExternalUrl(url)
    }
  }
}
</script>
