<template>
  <div class="container">
    <h1 class="page-title_hidden" id="page-title-for-sr">{{ $t('error.pageTitle') }}</h1>
    <div class="columns" v-if="!loading">
      <div class="column-full">
        <div class="content error-wrapper">
          <div class="error-icon-wrapper">
            <general-error-icon />
          </div>
          <error-template />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorTemplate from './Template'
import { mapState } from 'vuex'
import GeneralErrorIcon from '@/base/components/Error/Icons/GeneraIErrorIcon'

export default {
  name: 'ErrorData',
  components: {
    GeneralErrorIcon,
    ErrorTemplate
  },
  computed: {
    ...mapState({
      loading: state => state.error.loading
    })
  }
}
</script>

<style lang="scss">
@import "./src/base/assets/scss/components/Error.scss";
</style>
