export class FormClass {
  static isFormSubmitted (form) {
    if (!form) {
      return false
    }
    return !!form.submitted
  }

  static findFieldControl (fieldName, form) {
    if (!fieldName || !form) {
      return false
    }
    if (!form.controls || !form.controls[fieldName]) {
      return false
    }
    return form.controls[fieldName]
  }

  static isErrorForField (fieldName, form) {
    if (!fieldName || !form) {
      return false
    }
    const fieldControl = FormClass.findFieldControl(fieldName, form)
    return !!fieldControl && fieldControl.invalid
  }

  static showErrorForField (fieldName, form) {
    if (!fieldName || !form) {
      return false
    }
    const fieldControl = FormClass.findFieldControl(fieldName, form)
    return !!fieldControl && !!fieldControl.invalid && FormClass.isFormSubmitted(form)
  }

  static getErrorForField (fieldName, form) {
    if (!fieldName || !form || !FormClass.isErrorForField(fieldName, form)) {
      return ''
    }
    const fieldControl = FormClass.findFieldControl(fieldName, form)
    if (!fieldControl || !fieldControl.invalid) {
      return ''
    }
    return Object.keys(fieldControl.errors)[0]
  }

  static isFieldTouched (fieldName, form) {
    if (!fieldName || !form) {
      return false
    }
    const fieldControl = FormClass.findFieldControl(fieldName, form)
    return !!fieldControl && !!fieldControl.touched
  }

  static isFieldDirty (fieldName, form) {
    if (!fieldName || !form) {
      return false
    }
    const fieldControl = FormClass.findFieldControl(fieldName, form)
    return !!fieldControl && !!fieldControl.dirty
  }

  static addErrorToField (error, validatorName, fieldName, form) {
    if (!error || !validatorName || !fieldName || !form) {
      return
    }
    const fieldControl = FormClass.findFieldControl(fieldName, form)
    if (!fieldControl) {
      return
    }
    const errorObj = {}
    errorObj[validatorName] = error
    fieldControl.setErrors(errorObj)
  }

  static updateFieldValue (fieldName, value, form) {
    if (!fieldName || !form) {
      return
    }
    const fieldControl = FormClass.findFieldControl(fieldName, form)
    if (!fieldControl) {
      return
    }
    fieldControl.setValue(value)
  }
}
