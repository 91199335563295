<template>
  <div>
    <order-details-top :transaction="transaction"
                       :visibility="visibilityOptions"
                       :show-button="true"
                       v-if="canShowMobileOrderDetails"/>
    <main>
      <payment-loading v-if="isLoading"/>
      <div v-else>
        <error-data v-if="isErrorDataSet"/>
        <div v-else>
          <visa-mobile-data />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import PaymentLoading from '@/base/components/Payment/Loader/Loading'
import ErrorData from '@/base/components/Error/Data'
import OrderDetailsTop from '@/base/components/OrderDetails/Top'
import { DateClass } from '@/base/class/date.class'
import viewMixin from '@/base/mixins/view.mixin'
import VisaMobileData from '@/base/components/Payment/VisaMobileProcess/Data'
import { pageTitle } from '@/base/const/routing.const'

export default {
  name: 'Visadata',
  metaInfo () {
    return {
      title: pageTitle().base,
      titleTemplate: '%s | ' + pageTitle().visadata
    }
  },
  mixins: [viewMixin],
  components: {
    VisaMobileData,
    OrderDetailsTop,
    ErrorData,
    PaymentLoading
  },
  props: {
    apiToken: {
      type: String,
      required: true
    },
    idTransRemote: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.payment.loading,
      transaction: state => state.payment.paymentData?.transaction?.orderId ? state.payment.paymentData.transaction : state.confirmation.data?.transaction,
      visibilityOptions: state => state.payment.paymentData ? state.payment.paymentData?.visibilityOptions : null
    }),
    ...mapGetters({
      isErrorDataSet: 'isErrorDataSet'
    }),
    canShowMobileOrderDetails: function () {
      return !this.$VUE_HIDE_TRANSACTION_INFO && !this.isLoading
    }
  },
  methods: {
    ...mapMutations([
      'SET_ERROR_DATA',
      'SET_TIMER_TRX_GET_START',
      'SET_TIMER_TRX_START_LOADED'
    ]),
    ...mapActions([
      'getPaymentVisadataTrx'
    ])
  },
  mounted () {
    const data = {
      apiToken: this.apiToken,
      idTransRemote: this.idTransRemote
    }
    this.SET_TIMER_TRX_GET_START(DateClass.getCurrentTimestamp())
    this.getPaymentVisadataTrx(data)
      .finally(() => {
        this.SET_TIMER_TRX_START_LOADED(DateClass.getCurrentTimestamp())
      })
      .catch(error => {
        this.SET_ERROR_DATA({
          errorCode: error.body.errorCode,
          errorUrl: error.body.errorUrl
        })
      })
  }
}
</script>
