import { render, staticRenderFns } from "./ApplePay.vue?vue&type=template&id=2b734482&scoped=true"
import script from "./ApplePay.vue?vue&type=script&lang=js"
export * from "./ApplePay.vue?vue&type=script&lang=js"
import style0 from "./ApplePay.vue?vue&type=style&index=0&id=2b734482&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b734482",
  null
  
)

export default component.exports