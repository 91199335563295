import { DateClass } from '@/base/class/date.class'

export const MAINTENANCE_GLOBAL = [
  { start: 'Jan 15 2022 22:00:00 GMT+0100', end: 'Jan 16 2022 10:00:00 GMT+0100' }
]

function isMaintenanceGlobal () {
  let result = false
  MAINTENANCE_GLOBAL.forEach(entry => {
    if (DateClass.isDateActive(entry.start, entry.end)) {
      result = true
    }
  })
  return result
}
export const IS_MAINTENANCE_GLOBAL = isMaintenanceGlobal()
