export const ENV = {
  development: 'development',
  production: 'production'
}

export const APP_NAME = {
  development: 'development',
  production: 'production',
  staging: 'staging',
  test: 'test'
}
