const copyCommand = 'copy'

class ClipboardClass {
  constructor () {
    this.copyingAvailable = true
    try {
      document.execCommand(copyCommand)
    } catch (error) {
      this.copyingAvailable = false
    }
  }

  isCopyingAvailable () {
    return this.copyingAvailable
  }

  copyToClipboard (dataToCopy) {
    if (!this.copyingAvailable) {
      return Promise.reject(new Error('Copy not available!'))
    }

    const el = document.createElement('textarea')
    el.value = dataToCopy
    el.style = { position: 'absolute', left: '-9999px' }
    document.body.appendChild(el)
    el.select()

    if (document.execCommand('copy')) {
      document.body.removeChild(el)
      return Promise.resolve()
    }
    document.body.removeChild(el)
    return Promise.reject(new Error('Copy error!'))
  }
}

export const clipboardClass = new ClipboardClass()
