import VALIDATORS from '@/base/validators'
import { ToolsClass } from '@/base/class/tools.class'

const CHECKBOX_INPUT_TYPE = 'checkbox'
const RADIO_INPUT_TYPE = 'radio'
const FORM_STATUS = {
  initiated: 'INITIATED',
  pending: 'PENDING',
  valid: 'VALID',
  invalid: 'INVALID'
}
const FORM_CSS_CLASS = {
  pristine: 'pristine',
  dirty: 'dirty',
  untouched: 'untouched',
  touched: 'touched',
  invalid: 'invalid',
  valid: 'valid',
  submitted: 'submitted',
  empty: 'empty',
  filled: 'filled'
}
const FORMS = []

class FormDirectiveHelpers {
  static addCssClassToNode (node, cssClass) {
    node.classList.add(cssClass)
  }

  static removeCssClassFromNode (node, cssClass) {
    node.classList.remove(cssClass)
  }

  static setNodeValidationCssClass (node, valid) {
    if (valid) {
      FormDirectiveHelpers.removeCssClassFromNode(node, FORM_CSS_CLASS.invalid)
      FormDirectiveHelpers.addCssClassToNode(node, FORM_CSS_CLASS.valid)
    } else {
      FormDirectiveHelpers.removeCssClassFromNode(node, FORM_CSS_CLASS.valid)
      FormDirectiveHelpers.addCssClassToNode(node, FORM_CSS_CLASS.invalid)
    }
  }

  static setNodeContentsCssClass (node, filled) {
    if (filled) {
      FormDirectiveHelpers.removeCssClassFromNode(node, FORM_CSS_CLASS.empty)
      FormDirectiveHelpers.addCssClassToNode(node, FORM_CSS_CLASS.filled)
    } else {
      FormDirectiveHelpers.removeCssClassFromNode(node, FORM_CSS_CLASS.filled)
      FormDirectiveHelpers.addCssClassToNode(node, FORM_CSS_CLASS.empty)
    }
  }

  static setNodeTouchingCssClass (node, touched) {
    if (touched) {
      FormDirectiveHelpers.removeCssClassFromNode(node, FORM_CSS_CLASS.untouched)
      FormDirectiveHelpers.addCssClassToNode(node, FORM_CSS_CLASS.touched)
    } else {
      FormDirectiveHelpers.removeCssClassFromNode(node, FORM_CSS_CLASS.touched)
      FormDirectiveHelpers.addCssClassToNode(node, FORM_CSS_CLASS.untouched)
    }
  }

  static setNodePurityCssClass (node, dirty) {
    if (dirty) {
      FormDirectiveHelpers.removeCssClassFromNode(node, FORM_CSS_CLASS.pristine)
      FormDirectiveHelpers.addCssClassToNode(node, FORM_CSS_CLASS.dirty)
    } else {
      FormDirectiveHelpers.removeCssClassFromNode(node, FORM_CSS_CLASS.dirty)
      FormDirectiveHelpers.addCssClassToNode(node, FORM_CSS_CLASS.pristine)
    }
  }

  static setNodeSubmitCssClass (node, submitted) {
    if (submitted) {
      FormDirectiveHelpers.addCssClassToNode(node, FORM_CSS_CLASS.submitted)
    } else {
      FormDirectiveHelpers.removeCssClassFromNode(node, FORM_CSS_CLASS.submitted)
    }
  }

  static runValidator (validatorName, value, field, form) {
    const result = VALIDATORS[validatorName](value, field, form)
    return {
      validator: validatorName,
      valid: typeof result === 'boolean' ? result : false,
      validatorMessage: typeof result !== 'boolean' ? result : null
    }
  }

  static addFormInstanceToWindow (formInstance) {
    if (!window.formDirectives) {
      window.formDirectives = []
    }
    window.formDirectives.push(formInstance)
  }

  static removeFormInstanceFromWindow (formInstance) {
    if (!window.formDirectives) {
      return
    }
    window.formDirectives = window.formDirectives.filter(form => form !== formInstance)
  }

  static startValueWatcher (control, callback) {
    const property = 'value'
    Object.defineProperty(control, property, new Watcher())

    function Watcher () {
      let _value = control[property]
      return {
        set (value) {
          _value = value
          control.dispatchEvent(new Event('change'))
          // walidacja formularza musi odbyc sie w timeoucie, poniewaz na tym etapie, drzewko vnode jest jeszcze nie zaktualizowane
          setTimeout(() => {
            callback()
          })
        },
        get () {
          return _value
        }
      }
    }
  }
}

class FormDirectiveFormElement {
  formEl = null
  formVNode = null

  controls = {}
  value = {}
  errors = {}

  valid = null
  invalid = null
  pending = false
  pristine = true
  dirty = false
  untouched = true
  touched = false
  status = null
  submitted = false
  debug = false

  constructor (formEl, formBindings, formVNode) {
    this.formEl = formEl
    this.formVNode = formVNode
    this.status = FORM_STATUS.initiated

    if (formBindings.arg) {
      this.formVNode.context[formBindings.arg] = this
    }
    if (formBindings.modifiers.debug) {
      this.debug = true
      FormDirectiveHelpers.addFormInstanceToWindow(this)
      addLog('Form directive initiated')
    }

    this.checkFields(formVNode.children)

    this.formEl.addEventListener('submit', () => { this.setAsSubmitted() })
    this.formEl.addEventListener('change', () => { this.validateForm() })
    if (!formBindings.modifiers.change) {
      this.formEl.addEventListener('input', () => {
        setTimeout(() => {
          this.validateForm()
        })
      })
    }
  }

  updateVNode (formVNode) {
    this.formVNode = formVNode
    if (this.debug) {
      addLog('Form VNode updated')
    }
    this.checkFields(formVNode.children)
  }

  resetFormData () {
    this.value = {}
    this.errors = {}
  }

  resetFields () {
    ToolsClass.each(this.controls, (controlInstance) => {
      controlInstance.resetControl()
    })
  }

  resetFormFlags () {
    this.valid = null
    this.invalid = null
    this.pending = false
    this.pristine = true
    this.dirty = false
    this.untouched = true
    this.touched = false
    this.status = null
    this.submitted = false
    FormDirectiveHelpers.setNodeSubmitCssClass(this.formEl, false)
    if (this.debug) {
      addLog('Form flags cleared')
    }
  }

  resetForm () {
    this.resetFormData()
    this.resetFields()
    this.resetFormFlags()
    this.validateForm()
  }

  setAsSubmitted () {
    this.submitted = true
    FormDirectiveHelpers.setNodeSubmitCssClass(this.formEl, true)
    if (this.debug) {
      addLog('Form submitted')
    }
    this.updateView()
  }

  validateForm () {
    this.pending = true
    this.status = FORM_STATUS.pending
    this.resetFormData()
    const controlsResults = []

    ToolsClass.each(this.controls, (controlInstance, fieldName) => {
      controlInstance.validateControl()

      controlsResults.push({
        valid: controlInstance.valid,
        touched: controlInstance.touched,
        dirty: controlInstance.dirty
      })

      this.value[fieldName] = controlInstance.value
      if (controlInstance.invalid) {
        this.errors[fieldName] = controlInstance.errors
      }
    })

    this.touched = !!controlsResults.find(control => control.touched)
    this.untouched = !this.touched
    this.dirty = !!controlsResults.find(control => control.dirty)
    this.pristine = !this.dirty
    this.invalid = !!controlsResults.find(control => !control.valid)
    this.valid = !this.invalid
    this.status = this.valid ? FORM_STATUS.valid : FORM_STATUS.invalid

    FormDirectiveHelpers.setNodeTouchingCssClass(this.formEl, this.touched)
    FormDirectiveHelpers.setNodePurityCssClass(this.formEl, this.dirty)
    FormDirectiveHelpers.setNodeValidationCssClass(this.formEl, this.valid)
    this.pending = false
    if (this.debug) {
      addLog('Form validated with result ' + this.valid)
    }
    this.updateView()
  }

  checkFields (vNodes) {
    const fieldsRemovedFromControls = this.checkFieldsForExist()
    const fieldsAddedToControls = this.checkVNodeForFields(vNodes)
    const validatorsChanged = this.checkFieldsForValidationChanges()
    if (fieldsRemovedFromControls || fieldsAddedToControls || validatorsChanged) {
      this.validateForm()
    }
  }

  checkFieldsForExist () {
    let wasRemoved = false
    ToolsClass.each(this.controls, (controlInstance, fieldName) => {
      if (!controlInstance.controlEl.form) {
        wasRemoved = true
        delete this.controls[fieldName]
        if (this.debug) {
          addLog('Field ' + fieldName + ' removed from form')
        }
      }
    })
    return wasRemoved
  }

  checkFieldsForValidationChanges () {
    let validationChange = false
    ToolsClass.each(this.controls, (controlInstance) => {
      const oldValidators = JSON.stringify(controlInstance.validators)
      const currentValidators = JSON.stringify(controlInstance.getFieldValidators())
      if (oldValidators !== currentValidators) {
        validationChange = true
      }
    })
    return validationChange
  }

  checkVNodeForFields (vnodes) {
    let wasAdded = false
    ToolsClass.each(vnodes, (vnode) => {
      if (['input', 'select', 'textarea'].includes(vnode.tag)) {
        const field = vnode.elm || {}
        if (vnode.data.attrs['model-standalone'] === true) {
          return
        }
        if (!field.name) {
          return
        }
        if (field.formDirectiveAttached) {
          if (this.controls[field.name]) {
            this.controls[field.name].updateFieldControlVNode(vnode)
          }
          return
        }
        wasAdded = true
        field.formDirectiveAttached = true
        this.controls[field.name] = new FormDirectiveControlElement(vnode, this)
        if (this.debug) {
          addLog('Field ' + field.name + ' added to form')
        }
      } else if (vnode.children && vnode.children.length) {
        wasAdded = this.checkVNodeForFields(vnode.children) || wasAdded
      }
    })
    return wasAdded
  }

  updateView () {
    this.formVNode.context.$forceUpdate()
  }
}

class FormDirectiveControlElement {
  controlEl = null
  controlVNode = null
  form = null

  value = null
  errors = null
  validators = []

  valid = null
  invalid = null
  pending = null
  pristine = true
  dirty = false
  untouched = true
  touched = false
  status = null

  constructor (controlVNode, form) {
    this.controlEl = controlVNode.elm
    this.controlVNode = controlVNode
    this.form = form

    if (this.controlEl.type === 'hidden') {
      const callback = () => {
        if (this.form.debug) {
          addLog('Watcher callback on value change in hidden field ' + this.controlEl.name)
        }
        this.form.validateForm()
      }
      FormDirectiveHelpers.startValueWatcher(this.controlEl, callback)
      if (this.form.debug) {
        addLog('Control ' + this.controlEl.name + ' is hidden, added watcher for value changes')
      }
    }

    this.status = FORM_STATUS.initiated

    this.controlEl.addEventListener('blur', () => {
      this.touched = true
      this.untouched = false
      this.form.updateView()
      if (this.form.debug) {
        addLog('Field ' + this.controlEl.name + ' changed to touched')
      }
    }, { once: true })
  }

  updateFieldControlVNode (controlVNode) {
    this.controlVNode = controlVNode
  }

  resetControlValue () {
    this.setValue(null)
    if (this.form.debug) {
      addLog('Field ' + this.controlEl.name + ' cleared')
    }
  }

  resetControlFlags () {
    this.valid = null
    this.invalid = null
    this.pending = null
    this.pristine = true
    this.dirty = false
    this.untouched = true
    this.touched = false
    this.status = null
    if (this.form.debug) {
      addLog('Field ' + this.controlEl.name + ' flags cleared')
    }
  }

  resetControl () {
    this.resetControlValue()
    this.resetControlFlags()
    this.validateControl()
  }

  getFieldValidators () {
    const validators = []
    const controlData = this.controlVNode.data
    const controlDomAttrs = Object.keys(controlData.attrs).filter(attrName => typeof controlData.attrs[attrName] !== 'boolean' || controlData.attrs[attrName])
    const controlDirectives = controlData.directives ? controlData.directives.map(directive => directive.name) : []
    const controlAttrs = [...controlDomAttrs, ...controlDirectives]
    controlAttrs.forEach(attribute => {
      if (VALIDATORS[attribute]) {
        validators.push(attribute)
      }
    })
    return validators
  }

  validateControl () {
    this.pending = true
    this.errors = null
    this.status = FORM_STATUS.pending
    const validationResults = []
    const controlData = this.controlVNode.data
    const controlModelDirective = controlData.directives ? controlData.directives.find(directive => directive.name === 'model') : null
    const controlValue = controlModelDirective ? controlModelDirective.value : (this.controlEl.type === CHECKBOX_INPUT_TYPE ? this.controlEl.checked : this.controlEl.value)
    this.value = controlValue

    // validating field
    this.validators = this.getFieldValidators()
    this.validators.forEach(validatorName => {
      const result = FormDirectiveHelpers.runValidator(validatorName, controlValue, this.controlEl, this.controlEl.form)
      validationResults.push(result)
      if (this.form.debug) {
        addLog('Validator ' + validatorName + ' returns ' + result.valid + ' with value ' + (typeof controlValue !== 'string' ? JSON.stringify(controlValue) : controlValue) + ' on field ' + this.controlEl.name)
      }
    })

    // setting valid
    const isControlValid = !validationResults.find(result => !result.valid)
    this.valid = isControlValid
    this.invalid = !isControlValid
    if (!isControlValid) {
      const errorObj = {}
      validationResults.filter(result => !result.valid).forEach(result => {
        errorObj[result.validatorMessage || result.validator] = true
      })
      this.setErrors(errorObj)
    }
    this.status = isControlValid ? FORM_STATUS.valid : FORM_STATUS.invalid
    FormDirectiveHelpers.setNodeValidationCssClass(this.controlEl, isControlValid)

    // setting empty
    FormDirectiveHelpers.setNodeContentsCssClass(this.controlEl, !!controlValue)

    // setting touched
    FormDirectiveHelpers.setNodeTouchingCssClass(this.controlEl, this.touched)

    // setting dirty
    if (!this.dirty && controlValue) {
      this.dirty = true
      this.pristine = false
      if (this.form.debug) {
        addLog('Field ' + this.controlEl.name + ' changed to dirty')
      }
    }
    FormDirectiveHelpers.setNodePurityCssClass(this.controlEl, this.dirty)

    this.pending = false
    if (this.form.debug) {
      addLog('Field ' + this.controlEl.name + ' validated with result ' + this.valid)
    }
  }

  setValue (value) {
    if (this.controlEl.type === RADIO_INPUT_TYPE) {
      this.controlEl.checked = this.controlEl.value === value
    } else if (this.controlEl.type === CHECKBOX_INPUT_TYPE) {
      this.controlEl.checked = !!value
    } else {
      this.controlEl.value = value
    }
    this.form.validateForm()
  }

  setErrors (errorObj) {
    if (!errorObj || typeof errorObj !== 'object') {
      return
    }
    this.invalid = true
    this.valid = false
    this.errors = Object.assign({}, errorObj)
    if (!this.pending) {
      this.form.updateView()
    }
    if (this.form.debug) {
      ToolsClass.each(errorObj, (value, key) => {
        addLog('Error ' + key + '  added to field ' + this.controlEl.name)
      })
    }
  }
}

function addLog (log) {
  console.log('%cFormDirective: %c' + log, 'font-weight:bold;', 'font-weight:normal;')
}

function createFormClass (formEl, formBindings, formVNode) {
  FORMS.push(new FormDirectiveFormElement(formEl, formBindings, formVNode))
}

function findFormInstance (el) {
  return FORMS.find(instance => instance.formEl === el)
}

function updateFormFields (el, vnode) {
  const result = findFormInstance(el)
  if (result) {
    result.updateVNode(vnode)
  }
}

function removeFormInstance (el) {
  const result = findFormInstance(el)
  FORMS.splice(FORMS.indexOf(result), 1)
  if (result.debug) {
    FormDirectiveHelpers.removeFormInstanceFromWindow(result)
  }
}

export default {
  bind: function (el, binding, vnode) {
    createFormClass(el, binding, vnode)
  },
  componentUpdated (el, binding, vnode) {
    updateFormFields(el, vnode)
  },
  unbind (el) {
    removeFormInstance(el)
  }
}
