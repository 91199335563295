<template>
  <main>
    <back-loading v-if="isLoading" />
    <div v-else>
      <error-data v-if="isErrorSet"/>
    </div>
  </main>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import BackLoading from '@/base/components/Back/Loading'
import ErrorData from '@/base/components/Error/Data'
import { ToolsClass } from '@/base/class/tools.class'
import { MobileAppClass } from '@/base/class/mobileApp.class'
import { pageTitle } from '@/base/const/routing.const'

export default {
  name: 'SecureBack',
  metaInfo () {
    return {
      title: pageTitle().base,
      titleTemplate: '%s | ' + pageTitle().secureBack
    }
  },
  components: {
    ErrorData,
    BackLoading
  },
  props: {
    apiToken: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.back.loading,
      isErrorSet: state => !!state.error.errorData
    })
  },
  methods: {
    ...mapMutations([
      'SET_BACK_PARAMS',
      'SET_ERROR_DATA'
    ]),
    ...mapActions([
      'getBackTrx'
    ])
  },
  mounted () {
    const params = {
      merchantID: this.$route.query.MerchantID,
      orderID: this.$route.query.OrderID,
      amount: this.$route.query.Amount,
      currency: this.$route.query.Currency,
      status: this.$route.query.Status,
      hash: this.$route.query.Hash,
      apiToken: this.apiToken
    }
    this.SET_BACK_PARAMS(params)
    this.getBackTrx()
      .then(returnUrl => {
        if (ToolsClass.isMobileAppConnection()) {
          MobileAppClass.callMobileTransactionFinish()
          return
        }
        ToolsClass.goToExternalUrl(returnUrl)
      })
      .catch(error => {
        this.SET_ERROR_DATA({
          errorCode: error.body.errorCode,
          errorUrl: error.body.errorUrl
        })
      })
  }
}
</script>
