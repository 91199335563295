<template>
  <div ref="visaButtonContainer">
    <img class="v-button"
         locale="pl_PL"
         role="button"
         :src="buttonSrc"
         alt="Visa Checkout">
  </div>
</template>

<script>
import { visaClass, VisaClass } from '@/base/class/visa.class'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { WALLET_TYPES } from '@/base/const/payway.const'
import base64 from 'base-64'
import { Raven } from 'vue-raven'

export default {
  name: 'VisaCheckoutPay',
  data () {
    return {
      formValid: false
    }
  },
  props: {
    marketing: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      transaction: state => state.payment.paymentData.transaction,
      returnUrl: state => state.payment.paymentData.returnUrl
    }),
    ...mapGetters({
      paywayList: 'getPaywayList'
    }),
    buttonSrc: () => VisaClass.getButtonSrc(),
    visaPayway: function () {
      return this.paywayList.find(payway => payway.id === WALLET_TYPES.visaCheckout.id)
    }
  },
  methods: {
    ...mapMutations([
      'SET_ERROR_DATA'
    ]),
    ...mapActions([
      'sendPaymentUpdateTrx'
    ]),
    visaSuccess (payment) {
      const token = base64.encode(payment.callid)
      const data = {
        paymentToken: token,
        marketing: this.marketing
      }
      this.sendPaymentUpdateTrx(data)
        .catch(error => {
          this.SET_ERROR_DATA({
            errorCode: error.body.errorCode,
            errorUrl: error.body.errorUrl
          })
        })
    },
    visaCancel () {
      this.formValid = false
    },
    visaError (payment, error) {
      this.formValid = false
      Raven.captureMessage('VisaCheckout error', {
        extra: error
      })
    },
    supportSubmit () {
      this.$refs.visaButtonContainer.querySelector('img').addEventListener('click', e => {
        const form = this.$parent.form
        if (!form) {
          return
        }
        form.setAsSubmitted()
        if (!this.formValid) {
          e.stopImmediatePropagation()
          if (form.valid) {
            this.formValid = true
            this.$refs.visaButtonContainer.querySelector('img').dispatchEvent(new Event('click'))
          }
        }
      }, true)
    }
  },
  mounted () {
    visaClass.init(
      this.visaPayway.additionalData.api_key,
      this.visaPayway.additionalData.acceptor_id,
      this.transaction.payAmountCurrency,
      Number(this.transaction.payAmount).toFixed(2),
      this.visaSuccess,
      this.visaCancel,
      this.visaError
    )
    this.supportSubmit()
  }
}
</script>
